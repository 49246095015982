import axios from "axios";

export const getClosestWarehouse = async (facilityID, warehouseIDs) => {
  return axios
    .get("/api/warehouse/closest", {
      params: {
        facilityID,
        warehouseIDs,
      },
    })
    .then((res) => res.data.warehouse);
};

export const addProductInventoryToWarehouse = async ({
  products,
  warehouseID,
}) => {
  return axios.patch("/api/warehouse/add-product-inventory", {
    products,
    warehouseID,
  });
};

export const addSupplierProductDetailToManyWarehouses = async ({
  product,
  supplierCompanyID,
  warehouseIDs,
}) => {
  return axios.patch("/api/warehouse/add-supplier-product-detail-to-many", {
    product,
    supplierCompanyID,
    warehouseIDs,
  });
};

export const removeSupplierProductDetailFromManyWarehouses = async ({
  product,
  supplierCompanyID,
  warehouseIDs,
}) => {
  return axios.patch(
    "/api/warehouse/remove-supplier-product-detail-from-many",
    {
      product,
      supplierCompanyID,
      warehouseIDs,
    }
  );
};

export const removeProductInventoryFromWarehouse = async ({
  productsSelected,
  warehouseID,
}) => {
  return axios.patch("/api/warehouse/remove-product-inventory", {
    productsSelected,
    warehouseID,
  });
};
