import React from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";

const HistoryButton = ({ to, onClick, ...gridProps }) => {
  return (
    <Grid item {...gridProps}>
      {to ? (
        <Link to={to} style={{ textDecoration: "none", fontSize: "1rem" }}>
          <Button variant="contained" fullWidth>
            History
            <HistoryIcon style={{ marginLeft: "2px", fontSize: "20px" }} />
          </Button>
        </Link>
      ) : (
        <Button variant="contained" fullWidth onClick={onClick}>
          History
          <HistoryIcon style={{ marginLeft: "2px", fontSize: "20px" }} />
        </Button>
      )}
    </Grid>
  );
};

export default HistoryButton;
