import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cancelCartSubmission } from "../../api/cartSubmissionAPI";
import { useDispatch } from "react-redux";
import {
  setProductsInCartByWarehouse,
  setOrderFacility,
  setOrderFacilityAddress,
  setCartSubmissions,
  setCartSubmission,
} from "../../redux/reducers/productsSlice";

const useCancelCartSubmission = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelCartSubmission,
    onSuccess: () => {
      dispatch(setOrderFacility(null));
      dispatch(setOrderFacilityAddress(null));
      dispatch(setProductsInCartByWarehouse([]));
      dispatch(setCartSubmissions([]));
      dispatch(setCartSubmission(null));
      queryClient.invalidateQueries({ queryKey: ["cartSubmissions"] });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
  });
};

export default useCancelCartSubmission;
