import axios from "axios";

export const getCartSubmissionByID = async (id) => {
  return axios.get(`/api/cart-submission/${id}`).then((res) => {
    return res.data.cartSubmission;
  });
};

export const getAllCartSubmissionsByRequestedUser = async (id) => {
  return axios
    .get(`/api/cart-submission/${id}/user`)
    .then((res) => res.data.cartSubmissions);
};

export const addCartSubmission = async (cartSubmission) => {
  return axios.post("/api/cart-submission", cartSubmission).then((res) => {
    return res.data.cartSubmission;
  });
};

export const cancelCartSubmission = async (id) => {
  return axios.patch(`/api/cart-submission/${id}/status/cancel`).then((res) => {
    return res.data.cartSubmission;
  });
};
