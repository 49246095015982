import { Grid, Typography } from "@mui/material";
import React from "react";

const AccountingMetrics = () => {
  return (
    <Grid container spacing={2} sx={{ mt: "5px" }}>
      <Grid item xs={12}>
        <Typography variant="h6">Accounting Metrics</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Coming soon...</Typography>
      </Grid>
    </Grid>
  );
};

export default AccountingMetrics;
