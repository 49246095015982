import { Button, Grid } from "@mui/material";
import React from "react";
import useBookUPSShipment from "../../../customHooks/ups/useBookUPSShipment";

const CreateUPSShipment = ({ order }) => {
  const bookShipment = useBookUPSShipment();
  const handleCreateShipment = async () => {
    await bookShipment.mutateAsync(order._id);
  };

  return (
    <Grid item xs={4} md={3} display={"flex"}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateShipment}
      >
        Create UPS Shipment
      </Button>
    </Grid>
  );
};

export default CreateUPSShipment;
