import axios from "axios";

export const getVendorCredits = async () => {
  return axios.get(`/api/vendor-credit/get-all`).then((res) => {
    return res?.data?.vendorCredit;
  });
};

export const getVendorCredit = async (vendorCreditID) => {
  return axios
    .get(`/api/vendor-credit/get-by-id?id=${vendorCreditID}`)
    .then((res) => {
      return res?.data?.vendorCredit;
    });
};

export const getVendorCreditByVendor = async (vendorID, outStanding) => {
  return axios
    .get(
      `/api/vendor-credit/get-by-vendor?vendorID=${vendorID}&outStanding=${outStanding}`
    )
    .then((res) => {
      return res?.data?.vendorCredit;
    });
};

export const vendorCreditApplyCredit = async (body) => {
  return axios.put(`/api/vendor-credit/apply-credit`, body);
};
