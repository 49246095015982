import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateServiceOrderServiceDates } from "../../api/serviceOrderAPI";

const useUpdateServiceOrderServiceDates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateServiceOrderServiceDates,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useUpdateServiceOrderServiceDates;
