import React from "react";
import AccountingBottomNav from "./AccountingBottomNav";
import AccountingMetrics from "./AccountingMetrics";
import AccountingDocuments from "./accountingDocuments/AccountingDocuments";
import { useSearchParams } from "react-router-dom";
import AccountingDocumentTable from "./accountingDocuments/AccountingDocumentTable";

const Accounting = () => {
  let [searchParams] = useSearchParams();
  const tab = searchParams.get("tab") || 0;

  return (
    <>
      {parseInt(tab) === 0 && <AccountingMetrics />}
      {parseInt(tab) === 1 && <AccountingDocuments />}
      {parseInt(tab) === 2 && <AccountingDocumentTable />}
      <AccountingBottomNav />
    </>
  );
};

export default Accounting;
