import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import customDollarRound from "../../../sharedFunctions/purchasingFunctions/customDollarRound";
import DecimalTextField from "../../sharedComponents/TextFields/DecimalTextField";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import FormSwitch from "../../sharedComponents/toggles/FormSwitch";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useProductServiceNames from "../../../customHooks/products/useProductServiceNames";
import useContactCustomerVendorNames from "../../../customHooks/contacts/useContactCustomerVendorNames";
import InfoText from "../../sharedComponents/InfoText";
import { productTypes } from "../../../globalConstants";
import useAccountingAddDocument from "../../../customHooks/accounting/useAccountingAddDocument";
import { useDispatch } from "react-redux";
import { pushNotification } from "../../../redux/reducers/notificationsSlice";
import sanitizeText from "../../../sharedFunctions/sanitizeText";

const AddDocumentForm = () => {
  const dispatch = useDispatch();

  const [documentType, setDocumentType] = useState("");
  const [business, setBusiness] = useState("");
  const [lineItems, setLineItems] = useState([]);
  const [notes, setNotes] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [saving, setSaving] = useState(false);
  const [txnDate, setTxnDate] = useState(moment());
  const [valid, setValid] = useState(true);

  const productServiceTypes = [
    ...productTypes,
    "Service",
    "Subcontracted Service",
    "Shipping",
    "Shipping Brokerage",
  ];

  const { data: businesses, isLoading: isLoadingBusinesses } =
    useContactCustomerVendorNames();
  const { data: productsAndServices, isLoading: isLoadingProductsAndServices } =
    useProductServiceNames();

  const addDocument = useAccountingAddDocument();

  useEffect(() => {
    if (!documentType) {
      return setValid(false);
    }

    if (orderNumber !== "" && orderNumber.length === 7) {
      return setValid(true);
    }

    if (!txnDate || !business || !lineItems.length) {
      return setValid(false);
    }

    for (let item of lineItems) {
      if (!item.product || !item.quantity || !item.rate) {
        return setValid(false);
      }

      if (
        item.product.name === "Custom" &&
        (!item.productType || !item.description)
      ) {
        return setValid(false);
      }
    }

    setValid(true);
  }, [documentType, txnDate, business, lineItems, orderNumber]);

  const createDocument = async () => {
    setSaving(true);
    const body = {
      documentType,
      notes,
      orderNumber,
      txnDate: txnDate.toISOString(),
      business,
      lineItems,
    };

    const response = await addDocument.mutateAsync(body);

    if (response.success) {
      dispatch(
        pushNotification({
          title: "Document",
          message: `Document Created`,
          hasViewButton: false,
          severity: "success",
        })
      );
    }
    setSaving(false);
  };

  const handleRemoveItem = (index) => {
    const newItems = [...lineItems];
    newItems.splice(index, 1);
    setLineItems(newItems);
  };

  const handleChangeItem = (index, field, value) => {
    const newItems = [...lineItems];
    newItems[index][field] = value;
    setLineItems(newItems);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2} xs={12} className="centered-container">
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="document-label" required={true}>
              Document
            </InputLabel>
            <Select
              labelId="document-label"
              id="document-select"
              value={documentType}
              label="Document"
              onChange={(e) => setDocumentType(e.target.value)}
              required={true}
            >
              <MenuItem value={"Bill"}>Bill</MenuItem>
              <MenuItem value={"CreditMemo"}>Credit Memo</MenuItem>
              <MenuItem value={"Invoice"}>Invoice</MenuItem>
              <MenuItem value={"ShipperBill"}>Shipper Bill</MenuItem>
              <MenuItem value={"VendorCredit"}>Vendor Credit</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <DecimalTextField
            editMode={true}
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            label="Order Number"
            allowMinus={false}
            allowDecimal={false}
            required={false}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InfoText text="Enter the order number of the missing document." />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {orderNumber === "" && (
          <>
            <Grid item xs={6} md={4}>
              <Autocomplete
                loading={isLoadingBusinesses}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                options={
                  businesses?.filter((business) => {
                    if (
                      documentType === "Bill" ||
                      documentType === "ShipperBill" ||
                      documentType === "VendorCredit"
                    ) {
                      return business.type === "vendor";
                    } else if (
                      documentType === "Invoice" ||
                      documentType === "CreditMemo"
                    ) {
                      return business.type === "customer";
                    }
                    return true;
                  }) || []
                }
                value={business}
                onChange={(e, value) => setBusiness(value)}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label="Customer/Vendor"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Transaction Date"
                onChange={setTxnDate}
                value={txnDate}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() =>
                  setLineItems([
                    {
                      description: "",
                      hasPST: true,
                      productType: "",
                      product: "",
                      quantity: "",
                      rate: "",
                    },
                    ...lineItems,
                  ])
                }
              >
                Add <ArrowDownwardIcon />
              </Button>
            </Grid>
            {lineItems.map((item, i) => (
              <Grid container spacing={1} item xs={12} key={i}>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    loading={isLoadingProductsAndServices}
                    getOptionLabel={(option) => option?.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    options={[
                      { _id: "Custom", name: "Custom" },
                      { _id: "drOP$", name: "drOP$" },
                      ...(productsAndServices || []),
                    ]}
                    value={item.product}
                    onChange={(e, value) =>
                      handleChangeItem(i, "product", value)
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        label="Product/Service"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <DecimalTextField
                    editMode={true}
                    value={item.quantity}
                    onChange={(e) =>
                      handleChangeItem(i, "quantity", e.target.value)
                    }
                    label="Quantity"
                    required={true}
                    allowMinus={false}
                    allowDecimal={true}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <DecimalTextField
                    editMode={true}
                    value={item.rate}
                    onChange={(e) =>
                      handleChangeItem(
                        i,
                        "rate",
                        customDollarRound(e.target.value)
                      )
                    }
                    label="Rate"
                    required={true}
                    allowMinus={false}
                    allowDecimal={true}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <DecimalTextField
                    editMode={false}
                    value={item.rate * item.quantity}
                    onChange={(e) =>
                      handleChangeItem(
                        i,
                        "amount",
                        customDollarRound(e.target.value)
                      )
                    }
                    label="Amount"
                    required={true}
                    allowMinus={false}
                    allowDecimal={true}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {item?.product?.name === "Custom" && (
                  <>
                    <Grid item xs={10} md={4}>
                      <Autocomplete
                        getOptionLabel={(option) => option || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        options={productServiceTypes}
                        value={item?.productType}
                        onChange={(e, value) =>
                          handleChangeItem(i, "productType", value)
                        }
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={true}
                            label="Product/Service Type"
                          />
                        )}
                      />
                    </Grid>
                    <FormSwitch
                      checked={item.hasPST}
                      onChange={(e) =>
                        handleChangeItem(i, "hasPST", e.target.checked)
                      }
                      label={"PST"}
                      xs={2}
                    />
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        multiline
                        label="Description"
                        value={item.description}
                        required={true}
                        onChange={(e) =>
                          handleChangeItem(i, "description", e.target.value)
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={2}>
                  <Button
                    onClick={() => handleRemoveItem(i)}
                    variant="contained"
                    sx={{ mt: 1 }}
                    fullWidth
                    color="error"
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      const newLineItems = [...lineItems];
                      newLineItems.splice(i + 1, 0, {
                        product: "",
                        quantity: "",
                        rate: "",
                      });
                      setLineItems(newLineItems);
                    }}
                  >
                    Add <ArrowDownwardIcon />
                  </Button>
                </Grid>
              </Grid>
            ))}
          </>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            label="Notes"
            value={notes}
            onChange={(e) => setNotes(sanitizeText(e.target.value))}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!valid || saving}
            onClick={createDocument}
            variant="contained"
            fullWidth={true}
          >
            Create Document
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddDocumentForm;
