import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Grid, Button } from "@mui/material";
import moment from "moment";
import { CustomModal, FormSwitch } from "../sharedComponents";
import OrderDisplay from "./orderDisplay/OrderDisplay";
import { useSearchParams } from "react-router-dom";
import useOrders from "../../customHooks/order/useOrders";
import CustomToolbar from "../sharedComponents/tables/CustomToolbar";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import CartSubmissionDisplay from "./orderDisplay/CartSubmissionDisplay";
import HistoryButton from "../sharedComponents/buttons/HistoryButton";

const OrdersTable = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const orderNumber = searchParams.get("orderNumber");
  const [outstandingOrders, setOutstandingOrders] = useState(true);
  const { orders, isLoading, isRefetching } = useOrders({ outstandingOrders });
  const [cartSubmission, setCartSubmission] = useState(null);
  const [cartSubmissionOpen, setCartSubmissionOpen] = useState(false);
  const appUserLevel = useSelector(selectAppUserLevel);
  const [order, setOrder] = useState(null);
  const [orderOpen, setOrderOpen] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [filters, setFilters] = useState({
    product: true,
    service: true,
    contract: true,
    creditMemo: true,
  });

  useEffect(() => {
    if (!orders?.length || !orderNumber) return;
    const order = orders.find(
      (order) => order.orderNumber.toString() === orderNumber.toString()
    );
    if (!order) return;
    setOrder(order);
    setOrderOpen(true);
  }, [orderNumber, orders]);

  useEffect(() => {
    if (!orders?.length) return;
    let filteredOrders = orders.filter((order) => {
      if (filters.product && order.orderType === "ProductOrder") return true;
      if (filters.service && order.orderType === "ServiceOrder") return true;
      if (filters.contract && order.orderType === "ServiceContractOrder")
        return true;
      if (filters.creditMemo && order.type === "CreditMemo") return true;

      if (
        filters.product &&
        !order.orderType &&
        order.status === "Awaiting Approval"
      )
        return true; // used for cart submissions
      return false;
    });
    setFilteredOrders(filteredOrders);
  }, [filters, orders]);

  const openOrder = (order) => {
    setOrder(order);
    setOrderOpen(true);
  };

  const openCartSubmission = (cs) => {
    setCartSubmission(cs);
    setCartSubmissionOpen(true);
  };

  const checkIfOverDue = (order) => {
    if (order.status === "Completed" || order.status === "Cancelled")
      return false;
    const dayRemaining = moment(order.payDueDate).diff(new Date(), "days");
    return dayRemaining < 0;
  };

  const checkIfReported = (order) => {
    if (order.status === "Reported") return true;
  };

  const checkIfNoShippingCost = (order) => {
    if (
      (appUserLevel.includes("Super User") ||
        appUserLevel.includes("Supplier")) &&
      order.status === "Delivered" &&
      (order?.supplierShippingCost === undefined ||
        order?.supplierShippingCost === null) &&
      (order?.shippingCost === undefined || order?.shippingCost === null) &&
      !order?.shippingIncluded &&
      !order?.pickUp
    )
      return true;
  };

  const closeOrder = (event, reason) => {
    if (reason === "backdropClick") return;
    setSearchParams();
    setOrder();
    setOrderOpen(false);
  };

  const renderButton = (params) => {
    return (
      <Button
        variant="contained"
        onClick={() =>
          params.row.status === "Awaiting Approval" && !params.row.orderType
            ? openCartSubmission(params.row)
            : openOrder(params.row)
        }
        color={params.row.newOrder ? "success" : "primary"}
      >
        {params.value}
      </Button>
    );
  };

  const TableButtons = () => {
    return (
      <Grid item xs={12} container display="flex">
        {appUserLevel.includes("Super User") && (
          <Grid item xs={12} md={5} display="flex" justifyContent="center">
            <FormSwitch
              label="P"
              checked={filters.product}
              onChange={(e) =>
                setFilters({ ...filters, product: e.target.checked })
              }
            />
            <FormSwitch
              label="SR"
              checked={filters.service}
              onChange={(e) =>
                setFilters({ ...filters, service: e.target.checked })
              }
            />
            <FormSwitch
              label="SC"
              checked={filters.contract}
              onChange={(e) =>
                setFilters({ ...filters, contract: e.target.checked })
              }
            />
            <FormSwitch
              label="CM"
              checked={filters.creditMemo}
              onChange={(e) =>
                setFilters({ ...filters, creditMemo: e.target.checked })
              }
            />
          </Grid>
        )}
        {outstandingOrders ? (
          <HistoryButton
            onClick={() => setOutstandingOrders(!outstandingOrders)}
          />
        ) : (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => setOutstandingOrders(!outstandingOrders)}
            >
              Show Outstanding Orders
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  let columns = [
    {
      field: "orderNumber",
      headerName: "Order #",
      renderCell: renderButton,
      width: 100,
      valueGetter: (value, row) => row?.orderNumber ?? row?.docNumber,
    },
    {
      field: "facility",
      headerName: "Facility",
      width: 250,
      valueGetter: (value, row) => value?.name ?? row?.customer?.name ?? "",
    },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 150,
      valueGetter: (value, row) => {
        return row.supplier?.name ?? "";
      },
    },
    {
      field: "shipper",
      headerName: "Shipper",
      width: 150,
      valueGetter: (value, row) => {
        return row.shipper?.name;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 165,
      type: "singleSelect",
      valueOptions: [
        {
          value: "Awaiting Approval",
          label: "Awaiting Approval",
        },
        {
          value: "Awaiting Facility Approval",
          label: "Awaiting Facility Approval",
        },
        { value: "Awaiting Payment", label: "Awaiting Payment" },
        { value: "Adjustment Requested", label: "Adjustment Requested" },
        { value: "Awaiting Stock", label: "Awaiting Stock" },
        { value: "Awaiting Service", label: "Awaiting Service" },
        { value: "Completed", label: "Completed" },
        { value: "Cancelled", label: "Cancelled" },
        { value: "Credits Remaining", label: "Credits Remaining" },
        { value: "Credits Redeemed", label: "Credits Redeemed" },
        { value: "Delivered", label: "Delivered" },
        { value: "Not Issued", label: "Not Issued" },
        { value: "On Route", label: "On Route" },
        { value: "Pending", label: "Pending" },
        { value: "Processing", label: "Processing" },
        { value: "Reported", label: "Reported" },
        { value: "Ready For Pick Up", label: "Ready For Pick Up" },
        { value: "Ready For Shipment", label: "Ready For Shipment" },
        { value: "Ready For Delivery", label: "Ready For Delivery" },
      ],
      getOptionLabel: (option) => option.label,
      valueGetter: (value, row) => {
        if (row.type === "CreditMemo") {
          return row.balance === 0 ? "Credits Redeemed" : "Credits Remaining";
        }
        return row.status;
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
      valueGetter: (value, row) =>
        `$${parseFloat(
          appUserLevel.includes("Supplier")
            ? row.supplierTotal || 0
            : appUserLevel.includes("Shipper")
            ? row.shippingCost || 0
            : value || row.amount || 0
        ).toFixed(2)}`,
    },
  ];

  if (appUserLevel.includes("Super User") || appUserLevel.includes("Regular")) {
    columns.push({
      field: "payDueDate",
      headerName: "Days Till Due",
      width: 100,
      valueGetter: (value, row) =>
        row.status === "Cancelled"
          ? "N/A"
          : row.status === "Completed"
          ? "paid"
          : value
          ? moment(value).diff(new Date(), "days")
          : "",
    });
  }

  columns.push({
    field: "date",
    headerName: "Order Date",
    width: 100,
    valueGetter: (value, row) => moment(value).format("MM/DD/YYYY"),
  });

  columns.push({
    field: "pickUpAvailableDate",
    headerName: "Available Date",
    width: 125,
    valueGetter: (value, row) =>
      value ? moment(new Date(value)).format("MM/DD/YYYY") : null,
  });

  columns.push({
    field: "shipDate",
    headerName: "Ship Date",
    width: 100,
    valueGetter: (value, row) =>
      value ? moment(new Date(value)).format("MM/DD/YYYY") : null,
  });

  columns.push({
    field: "arriveDate",
    headerName: "Est. Arrive Date",
    width: 125,
    valueGetter: (value, row) => {
      return value
        ? moment(new Date(value)).format("MM/DD/YYYY")
        : row.estimatedArriveDate
        ? moment(new Date(row.estimatedArriveDate)).format("MM/DD/YYYY")
        : null;
    },
  });

  const renderTable = () => {
    return (
      <Box
        sx={{
          height: window.innerHeight - 75,
          width: "100%",
          "& .order-overdue-true": {
            background: "rgba(255, 0, 0)",
          },
          "& .order-overdue-true:hover": {
            background: "rgba(255, 0, 0, 0.7) !important",
          },
          "& .order-reported-true": {
            background: "rgba(255, 0, 0)",
          },
          "& .order-reported-true:hover": {
            background: "rgba(255, 0, 0, 0.7) !important",
          },
          "& .order-missing-shipping-cost-true": {
            background: "rgba(255, 125, 0)",
          },
          "& .order-missing-shipping-cost-true:hover": {
            background: "rgba(255, 125, 0, 0.7) !important",
          },
          "& .order-pending-three-days-true": {
            background: "rgba(255, 255, 0, 0.7)",
          },
          "& .order-pending-three-days-true:hover": {
            background: "rgba(255, 255, 0, 0.5) !important",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row.id ?? row._id}
          rows={filteredOrders}
          columns={columns}
          rowsPerPageOptions={[12, 28, 30, 31, 100]}
          disableRowSelectionOnClick
          localeText={{
            noRowsLabel: "You have no orders. Please make your first order!",
          }}
          slots={{
            toolbar: () => CustomToolbar({ Buttons: TableButtons }),
          }}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "orderNumber",
                  sort: "desc",
                },
              ],
            },
          }}
          getRowClassName={(params) => {
            if (
              (appUserLevel.includes("Regular") ||
                appUserLevel.includes("Super User")) &&
              checkIfOverDue(params.row)
            ) {
              return "order-overdue-true";
            } else if (checkIfReported(params.row)) {
              return "order-reported-true";
            } else if (
              appUserLevel.includes("Super User") &&
              checkIfNoShippingCost(params.row)
            ) {
              return "order-missing-shipping-cost-true";
            } else if (
              appUserLevel.includes("Super User") &&
              params.row.status === "Pending" &&
              moment().diff(params.row.date, "days") > 3
            ) {
              return "order-pending-three-days-true";
            }
          }}
          loading={isLoading || isRefetching}
        />
      </Box>
    );
  };

  return (
    <>
      <CustomModal
        open={orderOpen}
        close={closeOrder}
        title={order?.orderNumber ?? order?.docNumber ?? ""}
      >
        <OrderDisplay
          close={closeOrder}
          orderID={order?._id}
          orderNumber={order?.orderNumber}
          orderType={order?.orderType ?? order?.type}
        />
      </CustomModal>

      <CustomModal
        open={cartSubmissionOpen}
        close={() => {
          setCartSubmissionOpen(false);
          setCartSubmission(null);
        }}
        title={cartSubmission?.orderNumber}
      >
        <CartSubmissionDisplay
          cartSubmissionID={cartSubmission?._id}
          close={() => {
            setCartSubmissionOpen(false);
            setCartSubmission(null);
          }}
          facilityID={cartSubmission?.facility?._id}
          facilityHasGST={cartSubmission?.facility?.hasGST}
        />
      </CustomModal>

      <Grid container sx={{ mt: 2 }}>
        {renderTable()}
      </Grid>
    </>
  );
};

export default OrdersTable;
