import { checkIfValidAddress } from "../../sharedFunctions/checkIfValidAddress";
import { useQuery } from "@tanstack/react-query";
import { getClosestWarehouse } from "../../api/warehouseAPI"; // You need to define this API call

const useGetClosestWarehouse = ({
  facilityID,
  warehouses,
  supplierProductDetail,
}) => {
  const validWarehousesIDs = [];

  warehouses?.forEach((warehouse) => {
    if (
      checkIfValidAddress(warehouse?.contact?.address) &&
      (!supplierProductDetail || //if no supplierProductDetail, don't filter
        supplierProductDetail.warehouses.some(
          (spdWarehouse) => spdWarehouse._id === warehouse._id
        ))
    ) {
      validWarehousesIDs.push(warehouse._id);
      return;
    }
  });

  const enabled = !!(facilityID && validWarehousesIDs?.length);

  const {
    data: warehouse,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "closestWarehouse",
      facilityID,
      validWarehousesIDs,
      supplierProductDetail?._id,
    ],
    queryFn: () => getClosestWarehouse(facilityID, validWarehousesIDs),
    enabled,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { warehouse, isLoading, isError };
};

export default useGetClosestWarehouse;
