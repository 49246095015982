import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import formatDate from "../../../sharedFunctions/formatDate";

const OrderDisplayRefunds = ({ refunds, orderView }) => {
  if (!refunds?.length || orderView === "shipper") return;

  const facilityRefunds = () => {
    return refunds.map((refund, index) => {
      const { date, GST, items, PST, total, reason, subtotal } = refund;

      return (
        <Grid container item key={index} xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1">{formatDate(date)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Reason: {reason}</Typography>
          </Grid>
          {items.map((item) => {
            const { name, price, quantity, total } = item;

            return (
              <Grid container item key={item._id} xs={12} mt={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">{name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Qty: {quantity}, Price: ${price.toFixed(2)}, Total: $
                    {total.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">
              Subtotal: ${subtotal.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">PST: ${PST.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">GST: ${GST.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">Total: ${total.toFixed(2)}</Typography>
          </Grid>
        </Grid>
      );
    });
  };

  const supplierRefunds = () => {
    return refunds.map((refund, index) => {
      const {
        date,
        supplierGST,
        items,
        supplierPST,
        supplierTotal,
        reason,
        supplierSubtotal,
      } = refund;

      return (
        <Grid container item key={index} xs={12}>
          <Grid item xs={12}>
            <Typography variant="body1">{formatDate(date)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Reason: {reason}</Typography>
          </Grid>
          {items.map((item) => {
            const { name, cost, quantity, supplierTotal } = item;

            return (
              <Grid container item key={item._id} xs={12} mt={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">{name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Qty: {quantity}, Price: ${cost.toFixed(2)}, Total: $
                    {supplierTotal.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">
              Subtotal: ${supplierSubtotal.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">
              PST: ${supplierPST.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">
              GST: ${supplierGST.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Typography variant="body1">
              Total: ${supplierTotal.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Refunds</Typography>
      </Grid>
      {orderView === "facility" && facilityRefunds()}
      {orderView === "supplier" && supplierRefunds()}
    </Grid>
  );
};

export default OrderDisplayRefunds;
