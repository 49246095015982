import axios from "axios";

export const getBills = async () => {
  return axios.get(`/api/bill/get-all`).then((res) => {
    return res?.data?.bill;
  });
};

export const getBill = async (billID) => {
  return axios.get(`/api/bill/get-by-id?id=${billID}`).then((res) => {
    return res?.data?.bill;
  });
};

export const getBillByVendor = async (vendorID, outStanding) => {
  return axios
    .get(
      `/api/bill/get-by-vendor?vendorID=${vendorID}&outStanding=${outStanding}`
    )
    .then((res) => {
      return res?.data?.bill;
    });
};
