import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../sharedComponents";
import ServiceRequestForm from "../services/ServiceRequestForm";
import useUpdateServiceContract from "../../customHooks/serviceContractOrder/useUpdateServiceContract";
import useServiceOrdersBySupplier from "../../customHooks/serviceOrder/useServiceOrdersBySupplier";

const ServiceContractAddition = ({ editMode, serviceContract }) => {
  const [additionalService, setAdditionalService] = useState(null);
  const [linkExistingService, setLinkExistingService] = useState(null);
  const [linkExistingServiceFormOpen, setLinkExistingServiceFormOpen] =
    useState(false);
  const [newService, setNewService] = useState(true);
  const [serviceRequestFormOpen, setServiceRequestFormOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const { serviceOrders, isLoading: isLoadingServiceOrders } =
    useServiceOrdersBySupplier(serviceContract?.supplier?._id);

  const updateServiceContract = useUpdateServiceContract();

  const onAddExistingService = async () => {
    setSaving(true);

    const body = {
      _id: serviceContract._id,
      additionalServices: serviceContract.additionalServices
        .map((service) => service._id)
        .concat(linkExistingService._id),
    };

    await updateServiceContract.mutateAsync(body);

    setLinkExistingServiceFormOpen(false);
    setLinkExistingService(null);
    setSaving(false);
  };

  const openServiceRequestForm = (service) => {
    if (service) {
      setAdditionalService(service);
      setNewService(false);
    } else {
      setAdditionalService({
        facility: serviceContract.facility,
        supplier: serviceContract.supplier,
      });
      setNewService(true);
    }
    setServiceRequestFormOpen(true);
  };

  const openLinkExistingServiceForm = () => {
    setLinkExistingServiceFormOpen(true);
  };

  const closeServiceRequestForm = () => {
    setServiceRequestFormOpen(false);
  };

  const closeLinkExistingServiceForm = () => {
    setLinkExistingServiceFormOpen(false);
  };

  return (
    <>
      <CustomModal
        open={serviceRequestFormOpen}
        close={closeServiceRequestForm}
        hasEdit={newService ? false : true}
        title={"Additional Service"}
      >
        <ServiceRequestForm
          close={closeServiceRequestForm}
          editMode={newService}
          serviceRequest={additionalService}
          supplierToUse={serviceContract.supplier}
          linkServiceContractID={newService ? serviceContract._id : null}
        />
      </CustomModal>

      <CustomModal
        open={linkExistingServiceFormOpen}
        close={closeLinkExistingServiceForm}
        title={"Additional Service"}
      >
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6">Link Existing Service</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={serviceOrders.filter(
                (order) =>
                  !serviceContract?.additionalServices?.some(
                    (service) => service._id === order._id
                  )
              )}
              loading={isLoadingServiceOrders}
              getOptionLabel={(option) => option?.orderNumber?.toString() || ""}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={linkExistingService}
              onChange={(e, value) => setLinkExistingService(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Service"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={!linkExistingService || saving}
              fullWidth
              onClick={onAddExistingService}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <Grid container item spacing={2} xs={12}>
        {serviceContract?.additionalServices?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">Additional Services</Typography>
          </Grid>
        )}
        {editMode && (
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => openServiceRequestForm(null)}
            >
              Add Service
            </Button>
          </Grid>
        )}
        {editMode && (
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => openLinkExistingServiceForm()}
            >
              Link Existing Service
            </Button>
          </Grid>
        )}
        {serviceContract?.additionalServices?.length > 0 &&
          serviceContract.additionalServices.map((service) => (
            <Grid container item spacing={2} xs={12} key={service._id}>
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => openServiceRequestForm(service)}
                >
                  {service.orderNumber}
                </Button>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{service.serviceRequestDescription}</Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ServiceContractAddition;
