import { GST } from "../../globalConstants";
import calculateProductPrice from "./calculateProductPrice";
import calculateTotalProductAmount from "./calculateTotalProductAmount";
import { calculateTotalProductAmountWithExchange } from "./calculateTotalProductAmountWithExchange";
import customDollarRound from "./customDollarRound";
import { findTierPricingCost } from "./findTierPricingCost";
import getPSTRate from "./getPSTRate";
import getShippingCostByWarehouseInCart from "./getShippingCostByWarehouseInCart";

const calculateOrderTotals = ({
  exchangeRates,
  facilityCredit,
  facilityHasGST,
  facilityHasPST,
  facilityProvince,
  productsInCartByWarehouse,
  supplierPSTExempt,
  warehouseAddress,
}) => {
  const facilityPST = getPSTRate(facilityProvince);
  const supplierPST = getPSTRate(warehouseAddress);

  let productTotal = 0;
  let PSTAmount = 0;
  let GSTAmount = 0;
  let credit = 0;
  let supplierSubtotal = 0;
  let supplierGSTAmount = 0;
  let supplierPSTAmount = 0;
  let shippingCost = 0;
  let deposits = 0;
  let brokerage = 0;

  productsInCartByWarehouse.forEach((productInCartByWarehouse) => {
    const productsInCartWithoutReturns =
      productInCartByWarehouse.products?.filter(
        (productInCart) => !productInCart.isReturn
      );

    // if (
    //   productInCartByWarehouse.closestWarehouse.contact.address.country ===
    //   "United States of America"
    // )
    //   brokerage += 250;

    productsInCartWithoutReturns.forEach((productInCart) => {
      const productCost = findTierPricingCost(productInCart);
      const exchangeRate = exchangeRates.find(
        (exchangeRate) =>
          exchangeRate.currency ===
          productInCart.supplierProductDetail.supplier.currency
      )?.rate;

      if (productInCart.supplierProductDetail.product.type === "Container")
        deposits +=
          calculateProductPrice(productCost, exchangeRate) *
          productInCart.quantity;

      deposits +=
        calculateProductPrice(
          productInCart?.supplierProductDetail?.containerDeposit,
          exchangeRate
        ) * productInCart.quantity;

      if (productInCart.supplierProductDetail.product.type !== "Container")
        productTotal +=
          calculateProductPrice(
            productCost,
            exchangeRate,
            productInCart.supplierProductDetail.supplier._id
          ) * productInCart.quantity;

      supplierSubtotal += calculateTotalProductAmount(
        productInCart?.supplierProductDetail?.containerDeposit,
        productCost,
        productInCart.quantity
      );

      if (productInCart.supplierProductDetail.product.hasPST) {
        if (facilityHasPST) {
          PSTAmount += customDollarRound(
            calculateTotalProductAmountWithExchange(
              productCost,
              productInCart,
              exchangeRate,
              shippingCost
            ) * facilityPST
          );
        }
        if (
          !supplierPSTExempt &&
          productInCart.supplierProductDetail.supplier.currency === "CAD"
        ) {
          supplierPSTAmount += customDollarRound(
            calculateTotalProductAmount(
              productInCart?.supplierProductDetail?.containerDeposit,
              productCost,
              productInCart.quantity
            ) * supplierPST
          );
        }
      }

      if (facilityHasGST) {
        GSTAmount += customDollarRound(
          calculateTotalProductAmountWithExchange(
            productCost,
            productInCart,
            exchangeRate
          ) * GST
        );
      }
      if (productInCart.supplierProductDetail.supplier.currency === "CAD") {
        supplierGSTAmount += customDollarRound(
          calculateTotalProductAmount(
            productInCart?.supplierProductDetail?.containerDeposit,
            productCost,
            productInCart.quantity
          ) * GST
        );
      }
    });

    //get shipping cost for each warehouse
    if (
      !productInCartByWarehouse.pickUp &&
      productInCartByWarehouse.shippingRate &&
      productInCartByWarehouse.freightShipment
    ) {
      shippingCost +=
        getShippingCostByWarehouseInCart(productInCartByWarehouse) ?? 0;
    }
  });

  shippingCost = customDollarRound(shippingCost);
  if (facilityHasGST) GSTAmount += customDollarRound(shippingCost * GST);

  if (
    productTotal + deposits + shippingCost + GSTAmount + PSTAmount <
    facilityCredit
  ) {
    credit = customDollarRound(
      productTotal + deposits + shippingCost + GSTAmount + PSTAmount
    );
  } else if (facilityCredit > 0) {
    credit = facilityCredit;
  }

  const subtotal = customDollarRound(
    productTotal + deposits + shippingCost + brokerage
  );
  const orderTotal = customDollarRound(
    subtotal + GSTAmount + PSTAmount - credit
  );
  const supplierTotal = customDollarRound(
    supplierSubtotal + supplierGSTAmount + supplierPSTAmount
  );
  return {
    brokerage,
    deposits,
    credit,
    GSTAmount,
    PSTAmount,
    productTotal,
    orderTotal,
    supplierSubtotal,
    supplierGSTAmount,
    supplierPSTAmount,
    supplierTotal,
    shippingCost,
    subtotal,
  };
};

export default calculateOrderTotals;
