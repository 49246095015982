import React, { useMemo } from "react";

import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ImagePreview from "./ImagePreview";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import useProductNames from "../../../customHooks/products/useProductNames";
import useFacilityProductNames from "../../../customHooks/facility/useFacilityProductNames";
import { FormSwitch } from "../../sharedComponents";
import useMaintenanceProcedureNamesByFacility from "../../../customHooks/maintenanceProcedure/useMaintenanceProcedureNamesByFacility";
import useProductMaintenanceProcedureNames from "../../../customHooks/maintenanceProcedure/useProductMaintenanceProcedureNames";

const FormItems = ({
  editMode,
  facilityID,
  facilitySystems,
  formItems,
  setFormItems,
  isProduct,
  product,
}) => {
  const { facilityProducts, isLoading: isLoadingFacilityProductNames } =
    useFacilityProductNames(!isProduct && facilityID);
  const { products, isLoading: isLoadingProductNames } = useProductNames({
    enabled: isProduct,
  });
  const {
    data: facilityMaintenanceProcedureNames,
    isLoading: isLoadingFacilityMaintenanceProcedureNames,
  } = useMaintenanceProcedureNamesByFacility(!isProduct && facilityID);
  const {
    data: productMaintenanceProcedureNames,
    isLoading: isLoadingProductMaintenanceProcedureNames,
  } = useProductMaintenanceProcedureNames(isProduct);

  const productNames = useMemo(() => {
    if (facilityID) {
      return facilityProducts;
    } else if (isProduct) {
      return products;
    }

    return [];
  }, [facilityID, facilityProducts, isProduct, products]);

  const maintenanceProcedureNames = useMemo(() => {
    if (facilityID) {
      return facilityMaintenanceProcedureNames;
    } else if (isProduct) {
      return productMaintenanceProcedureNames?.filter(
        (maintenanceProcedure) =>
          maintenanceProcedure.product._id === product._id
      );
    }

    return [];
  }, [
    facilityID,
    isProduct,
    facilityMaintenanceProcedureNames,
    productMaintenanceProcedureNames,
    product?._id,
  ]);

  return (
    <Grid container spacing={2}>
      {formItems?.length > 0 && (
        <Grid item xs={12}>
          <Typography textAlign={"center"}>Form Fields</Typography>
        </Grid>
      )}
      {editMode && (
        <Grid item xs={12} display="flex">
          <Button
            onClick={() =>
              setFormItems([{ inputType: "", label: "" }, ...formItems])
            }
          >
            Add Step <ArrowDownwardIcon />
          </Button>
        </Grid>
      )}

      {formItems.map((item, i) => {
        return (
          <Grid container spacing={1} item xs={12} key={item.inputType + i}>
            <Grid item xs={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="typeTypeLabel">Input Type</InputLabel>
                <Select
                  value={item.inputType}
                  labelId="typeTypeLabel"
                  label="Input Type"
                  onChange={(e) => {
                    formItems[i].inputType = e.target.value;
                    const newFormItems = [...formItems];
                    setFormItems(newFormItems);
                  }}
                  disabled={!editMode}
                >
                  <MenuItem value={"CheckBox"}>CheckBox</MenuItem>
                  <MenuItem value={"Input"}>Input</MenuItem>
                  <MenuItem value={"Image"}>Image</MenuItem>
                  <MenuItem value={"Text"}>Text</MenuItem>
                  <MenuItem value={"Selector"}>Selector</MenuItem>
                  <MenuItem value={"BulletList"}>Bullet List</MenuItem>
                  <MenuItem value={"Product"}>Product</MenuItem>
                  <MenuItem value={"SystemLog"}>System Log</MenuItem>
                  <MenuItem value={"MaintenanceProcedure"}>Procedure</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {(item.inputType === "CheckBox" ||
              item.inputType === "Input" ||
              item.inputType === "Selector" ||
              item.inputType === "SystemLog" ||
              item.inputType === "MaintenanceProcedure") && (
              <FormSwitch
                disabled={!editMode}
                checked={item?.required ?? true}
                onChange={(e) => {
                  const newFormItems = [...formItems];
                  newFormItems[i].required = e.target.checked;
                  setFormItems(newFormItems);
                }}
                label={"Required"}
                xs={6}
              />
            )}

            {item.inputType !== "Product" &&
              item.inputType !== "SystemLog" &&
              item.inputType !== "MaintenanceProcedure" && (
                <Grid item xs={12}>
                  <TextField
                    autoComplete="off"
                    label={
                      item.inputType === "Image"
                        ? "Image Description"
                        : item.inputType === "Text"
                        ? "Text"
                        : "Label"
                    }
                    value={item.label}
                    variant="outlined"
                    multiline
                    onChange={(e) => {
                      formItems[i].label = e.target.value;
                      const newFormItems = [...formItems];
                      setFormItems(newFormItems);
                    }}
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
              )}

            {item.inputType === "Product" && (
              <Grid item xs={6}>
                <Autocomplete
                  id={`product${i}`}
                  isOptionEqualToValue={(option, value) => {
                    if (!value.length) return [];
                    return option._id === value._id;
                  }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  options={productNames}
                  loading={
                    isLoadingFacilityProductNames || isLoadingProductNames
                  }
                  value={item?.product || null}
                  onChange={(e, value) => {
                    formItems[i].product = value ? value : null;
                    const newFormItems = [...formItems];
                    setFormItems(newFormItems);
                  }}
                  disabled={!editMode}
                  renderInput={(params) => (
                    <TextField {...params} label="Product" />
                  )}
                />
              </Grid>
            )}

            {item.inputType === "SystemLog" && (
              <Grid item xs={6}>
                <Autocomplete
                  id={`system${i}`}
                  isOptionEqualToValue={(option, value) => {
                    if (!value.length) return [];
                    return option._id === value._id;
                  }}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  options={facilitySystems ?? []}
                  value={item?.system || null}
                  onChange={(e, value) => {
                    formItems[i].system = value ? value : null;
                    const newFormItems = [...formItems];
                    setFormItems(newFormItems);
                  }}
                  disabled={!editMode}
                  renderInput={(params) => (
                    <TextField {...params} label="System" />
                  )}
                />
              </Grid>
            )}

            {item.inputType === "MaintenanceProcedure" && (
              <Grid item xs={6}>
                <Autocomplete
                  id={`maintenanceProcedure${i}`}
                  isOptionEqualToValue={(option, value) => {
                    if (!value?.length) return [];
                    return option._id === value._id;
                  }}
                  getOptionLabel={(option) => {
                    return `${option.name}, ${
                      option?.equipment?.name ??
                      option?.system?.name ??
                      option?.product?.name ??
                      ""
                    }`;
                  }}
                  options={maintenanceProcedureNames || []}
                  loading={
                    isLoadingFacilityMaintenanceProcedureNames ||
                    isLoadingProductMaintenanceProcedureNames
                  }
                  value={item?.maintenanceProcedure || null}
                  onChange={(e, value) => {
                    formItems[i].maintenanceProcedure = value ? value : null;
                    formItems[i].equipment = value?.equipment || null;
                    const newFormItems = [...formItems];
                    setFormItems(newFormItems);
                  }}
                  disabled={!editMode}
                  renderInput={(params) => (
                    <TextField {...params} label="Procedure" />
                  )}
                />
              </Grid>
            )}

            {editMode && (
              <Grid item xs={12} md={2}>
                <Button
                  onClick={() => {
                    const newFormItems = [...formItems];
                    newFormItems.splice(i, 1);
                    setFormItems(newFormItems);
                  }}
                  variant="contained"
                  sx={{ mt: 1 }}
                  fullWidth
                  color="error"
                >
                  Remove
                </Button>
              </Grid>
            )}

            {item.inputType === "Image" && (
              <>
                {editMode && (
                  <Grid item xs={12} md={4}>
                    <input
                      data-testid="chooseFile"
                      type="file"
                      accept=".jpeg, .png, .jpg, .pdf"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          const newFormItems = [...formItems];
                          newFormItems[i].img = e.target.files[0];

                          setFormItems(newFormItems);
                        }
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  style={{ width: "inherit", paddingRight: "1rem" }}
                >
                  <ImagePreview item={item} />
                </Grid>
              </>
            )}
            {item.inputType === "Selector" && (
              <>
                <FormSwitch
                  label="Multiple Selection"
                  xs={6}
                  md={3}
                  checked={item.multipleSelection}
                  onChange={(e) => {
                    const newFormItems = [...formItems];
                    newFormItems[i].multipleSelection = e.target.checked;
                    setFormItems(newFormItems);
                  }}
                  disabled={!editMode}
                />
                {editMode && (
                  <Grid item xs={12} textAlign={"center"}>
                    <Button
                      onClick={() => {
                        const newFormItems = [...formItems];
                        const options = newFormItems[i].options || [];
                        newFormItems[i].options = ["", ...options];

                        setFormItems(newFormItems);
                      }}
                      sx={{ mt: 1 }}
                    >
                      Add Option <ArrowDownwardIcon />
                    </Button>
                  </Grid>
                )}
                {item?.options?.map((option, j) => {
                  return (
                    <Grid
                      container
                      spacing={2}
                      item
                      xs={12}
                      key={"Option" + i + j}
                    >
                      <Grid item xs={6}>
                        <TextField
                          autoComplete="off"
                          label={"Option"}
                          value={option}
                          variant="outlined"
                          onChange={(e) => {
                            const newFormItems = [...formItems];

                            newFormItems[i].options[j] = e.target.value;

                            setFormItems(newFormItems);
                          }}
                          disabled={!editMode}
                          fullWidth
                        />
                      </Grid>
                      {editMode && (
                        <>
                          <Grid item xs={4} md={2}>
                            <Button
                              onClick={() => {
                                const newFormItems = [...formItems];
                                newFormItems[i].options.splice(j, 1);
                                setFormItems(newFormItems);
                              }}
                              variant="contained"
                              sx={{ mt: 1 }}
                              fullWidth
                              color="error"
                            >
                              Remove
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              onClick={() => {
                                const newFormItems = [...formItems];
                                const tempOptions = [
                                  ...(newFormItems[i].options
                                    ? newFormItems[i].options
                                    : []),
                                ];
                                tempOptions.splice(j + 1, 0, "");
                                newFormItems[i].options = tempOptions;
                                setFormItems(newFormItems);
                              }}
                              sx={{ mt: 1 }}
                            >
                              Add Option <ArrowDownwardIcon />
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              onClick={() => {
                                const newFormItems = [...formItems];
                                const tempOptionText = [
                                  ...(newFormItems[i].optionText?.filter(
                                    (os) => option in os
                                  )
                                    ? newFormItems[i].optionText?.filter(
                                        (os) => option in os
                                      )
                                    : []),
                                ];

                                tempOptionText.unshift({ [option]: "" });

                                newFormItems[i].optionText = tempOptionText;

                                setFormItems(newFormItems);
                              }}
                              sx={{ mt: 1 }}
                            >
                              Add Option Text <ArrowDownwardIcon />
                            </Button>
                          </Grid>
                          {item.optionText
                            ?.filter((os) => option in os)
                            .map((text, k) => {
                              return (
                                <Grid
                                  container
                                  spacing={2}
                                  item
                                  xs={12}
                                  key={"option text" + i + j + k}
                                >
                                  <Grid item xs={6}>
                                    <TextField
                                      autoComplete="off"
                                      label={"Option Text"}
                                      value={text[option]}
                                      variant="outlined"
                                      onChange={(e) => {
                                        const newFormItems = [...formItems];

                                        newFormItems[i].optionText[k][option] =
                                          e.target.value;

                                        setFormItems(newFormItems);
                                      }}
                                      disabled={!editMode}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={4} md={2}>
                                    <Button
                                      onClick={() => {
                                        const newFormItems = [...formItems];
                                        const tempOptionText = [
                                          ...(newFormItems[
                                            i
                                          ].optionText?.filter(
                                            (os) => option in os
                                          )
                                            ? newFormItems[
                                                i
                                              ].optionText?.filter(
                                                (os) => option in os
                                              )
                                            : []),
                                        ];

                                        tempOptionText.splice(k, 1);

                                        const newOptionText = [
                                          ...tempOptionText,
                                          ...newFormItems[i].optionText?.filter(
                                            (os) => !(option in os)
                                          ),
                                        ];

                                        newFormItems[i].optionText =
                                          newOptionText;

                                        setFormItems(newFormItems);
                                      }}
                                      variant="contained"
                                      sx={{ mt: 1 }}
                                      fullWidth
                                      color="error"
                                    >
                                      Remove
                                    </Button>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      onClick={() => {
                                        const newFormItems = [...formItems];
                                        const tempOptionText = [
                                          ...(newFormItems[i].optionText
                                            ? newFormItems[i].optionText
                                            : []),
                                        ];

                                        tempOptionText.splice(k + 1, 0, {
                                          [option]: "",
                                        });

                                        newFormItems[i].optionText =
                                          tempOptionText;

                                        setFormItems(newFormItems);
                                      }}
                                      sx={{ mt: 1 }}
                                    >
                                      Add Option Text <ArrowDownwardIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </>
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
            {item.inputType === "BulletList" && (
              <>
                {editMode && (
                  <Grid item xs={12} textAlign={"center"}>
                    <Button
                      onClick={() => {
                        const newFormItems = [...formItems];
                        const options = newFormItems[i].options || [];
                        newFormItems[i].options = ["", ...options];

                        setFormItems(newFormItems);
                      }}
                      sx={{ mt: 1 }}
                    >
                      Add List Item <ArrowDownwardIcon />
                    </Button>
                  </Grid>
                )}
                {item?.options?.map((option, j) => {
                  return (
                    <>
                      <Grid item xs={6} key={"bullet option" + i + j}>
                        <TextField
                          autoComplete="off"
                          label={"Item"}
                          value={option}
                          variant="outlined"
                          onChange={(e) => {
                            const newFormItems = [...formItems];
                            const tempOptions = [
                              ...(newFormItems[i].options
                                ? newFormItems[i].options
                                : []),
                            ];
                            tempOptions[j] = e.target.value;
                            newFormItems[i].options = tempOptions;
                            setFormItems(newFormItems);
                          }}
                          disabled={!editMode}
                          fullWidth
                        />
                      </Grid>
                      {editMode && (
                        <>
                          <Grid item xs={4} md={2}>
                            <Button
                              onClick={() => {
                                const newFormItems = [...formItems];
                                newFormItems[i].options.splice(j, 1);
                                setFormItems(newFormItems);
                              }}
                              variant="contained"
                              sx={{ mt: 1 }}
                              fullWidth
                              color="error"
                            >
                              Remove
                            </Button>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              onClick={() => {
                                const newFormItems = [...formItems];
                                const tempOptions = [
                                  ...(newFormItems[i].options
                                    ? newFormItems[i].options
                                    : []),
                                ];
                                tempOptions.splice(j + 1, 0, "");
                                newFormItems[i].options = tempOptions;
                                setFormItems(newFormItems);
                              }}
                              sx={{ mt: 1 }}
                            >
                              Add List Item <ArrowDownwardIcon />
                            </Button>
                          </Grid>
                        </>
                      )}
                    </>
                  );
                })}
              </>
            )}
            {editMode && (
              <Grid item xs={12} display="flex">
                <Button
                  onClick={() => {
                    const newFormItems = [...formItems];
                    newFormItems.splice(i + 1, 0, {
                      inputType: "",
                      label: "",
                    });
                    setFormItems(newFormItems);
                  }}
                >
                  Add Item <ArrowDownwardIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FormItems;
