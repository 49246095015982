import axios from "axios";

export const getConversation = async ({ parentType, parentID, supplierID }) => {
  const url = supplierID
    ? `/api/conversation/${parentType}/${parentID}/${supplierID}`
    : `/api/conversation/${parentType}/${parentID}`;

  const { data } = await axios.get(url);
  return data;
};

export const addMessage = async ({ parentType, parentID, formData }) => {
  await axios.post(
    `/api/conversation/${parentType}/${parentID}/messages`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
