import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProduct } from "../../api/productAPI";

const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(["products"]);
      queryClient.invalidateQueries(["productsSupplier"]);
      queryClient.invalidateQueries(["productsManufacturer"]);
    },
  });
};

export default useUpdateProduct;
