import useFacilityRestrictedShippers from "./useFacilityRestrictedShippers";
import useShipperCompanies from "../shipperCompany/useShipperCompanies";
import { CANADA_POST_CONTACT_ID, UPS_CONTACT_ID } from "../../globalConstants";

const checkForShipperRegion = (shipper, address, type) => {
  if (!shipper.shipperRegions[type]?.length) return true;

  const shipperRegion = shipper.shipperRegions[type].find(
    (region) => region.country === address.country
  );

  if (!shipperRegion) return false;

  if (shipperRegion.provinces.length === 0) return true;

  const shipperRegionProvince = shipperRegion.provinces.find(
    (province) => province.province === address.province
  );

  if (!shipperRegionProvince) return false;

  if (shipperRegionProvince.cities.length === 0) return true;

  return shipperRegionProvince.cities.includes(address.city);
};

export const getAvailableShippersForFacility = (
  facilityAddress,
  shipperCompanies,
  restrictedShippers,
  warehouseAddress,
  freightShipment
) => {
  if (freightShipment === false) {
    return shipperCompanies.filter(
      (shipperCompany) =>
        shipperCompany.contact._id === UPS_CONTACT_ID ||
        shipperCompany.contact._id === CANADA_POST_CONTACT_ID
    );
  }

  const availableShippers = [];
  if (!facilityAddress || !warehouseAddress) return shipperCompanies;

  if (restrictedShippers?.length) {
    const filteredShippers = restrictedShippers.filter(
      (shipper) =>
        shipper.contact.ratesEmail &&
        checkForShipperRegion(shipper, warehouseAddress, "origins") &&
        checkForShipperRegion(shipper, facilityAddress, "destinations")
    );
    if (filteredShippers.length) availableShippers.push(...filteredShippers);
  } else if (shipperCompanies?.length) {
    const filteredShippers = shipperCompanies.filter(
      (shipper) =>
        shipper.contact.ratesEmail &&
        checkForShipperRegion(shipper, warehouseAddress, "origins") &&
        checkForShipperRegion(shipper, facilityAddress, "destinations")
    );
    if (filteredShippers.length) availableShippers.push(...filteredShippers);
  }

  return availableShippers;
};

const useAvailableShippersForFacility = (
  facility,
  warehouseAddress,
  freightShipment
) => {
  const { restrictedShippers } = useFacilityRestrictedShippers(facility._id);
  const { shipperCompanies } = useShipperCompanies();

  return getAvailableShippersForFacility(
    facility.addressObject,
    shipperCompanies,
    restrictedShippers,
    warehouseAddress,
    freightShipment
  );
};

export default useAvailableShippersForFacility;
