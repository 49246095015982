import React from "react";
import { Button, Grid } from "@mui/material";
import useProductOrderShippingLabel from "../../../customHooks/productOrder/useProductOrderShippingLabel";

const DownloadShippingLabels = ({ order }) => {
  const { shippingLabels } = useProductOrderShippingLabel(order._id);

  const handleDownload = async () => {
    shippingLabels.forEach((labelBase64, index) => {
      const a = document.createElement("a");
      a.href = labelBase64;
      a.download = `shipping-label-${index + 1}.gif`; // Adjust extension based on the file type
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  if (!shippingLabels?.length) return null;

  return (
    <Grid item xs={4} md={3} display={"flex"}>
      <Button variant="contained" color="primary" onClick={handleDownload}>
        Download Shipping Labels
      </Button>
    </Grid>
  );
};

export default DownloadShippingLabels;
