export const GST_SalesTaxCode = {
  value: process.env.NODE_ENV === "production" ? "4" : "22",
  name: "GST",
};
export const PST_SK_SalesTaxCode = {
  value: process.env.NODE_ENV === "production" ? "9" : "27",
  name: "PST SK",
};
export const GST_PST_SK_SalesTaxCode = {
  value: process.env.NODE_ENV === "production" ? "8" : "26",
  name: "GST/PST SK",
};
export const exemptSalesTaxCode = {
  value: process.env.NODE_ENV === "production" ? "5" : "23",
  name: "Exempt",
};
export const outOfScopeSalesTaxCode = {
  value: process.env.NODE_ENV === "production" ? "6" : "24",
  name: "Out of Scope",
};
export const creditCardAccountRef = {
  name: "Credit Card (5010)",
  value: process.env.NODE_ENV === "production" ? "48" : "220",
};
export const bankAccountRef = {
  name: "Chequing (0610)",
  value: process.env.NODE_ENV === "production" ? "7" : "221",
};

export const shippingItemRef = {
  name: "Shipping & Delivery",
  value: process.env.NODE_ENV === "production" ? "7" : "38",
};
export const chemicalsOrFiltersItemRef = {
  name: "WT Chemical and/or Filters",
  value: process.env.NODE_ENV === "production" ? "5" : "39",
};
export const drop$ItemRef = {
  name: "drOP$",
  value: process.env.NODE_ENV === "production" ? "9" : "41",
};
export const equipmentOrSuppliesItemRef = {
  name: "Other Equipment and/or Supplies (SK)",
  value: process.env.NODE_ENV === "production" ? "6" : "40",
};
export const subcontractedServiceItemRef = {
  name: "Subcontracted Service",
  value: process.env.NODE_ENV === "production" ? "4" : "36",
};
export const serviceItemRef = {
  name: "Service",
  value: process.env.NODE_ENV === "production" ? "8" : "41",
};

export const prePaySalesTermRef = {
  name: "Pre pay",
  value: process.env.NODE_ENV === "production" ? "7" : "17",
};

export const dueOnReceiptSalesTermRef = {
  name: "Due on receipt",
  value: process.env.NODE_ENV === "production" ? "2" : "11",
};

export const net15SalesTermRef = {
  name: "Net 15",
  value: process.env.NODE_ENV === "production" ? "3" : "12",
};

export const net30SalesTermRef = {
  name: "Net 30",
  value: process.env.NODE_ENV === "production" ? "4" : "13",
};

export const net60SalesTermRef = {
  name: "Net 60",
  value: process.env.NODE_ENV === "production" ? "5" : "14",
};

// Currency Ref
export const CADCurrencyRef = {
  name: "Canadian Dollar",
  value: "CAD",
};

export const USDCurrencyRef = {
  name: "United States Dollar",
  value: "USD",
};

// Tax Rate Ref
export const GST_HST_ZR_TaxRateRef = {
  name: "GST/HST ZR",
  value: process.env.NODE_ENV === "production" ? "3" : "47",
};

export const GST_TaxRateRef = {
  name: "GST",
  value: process.env.NODE_ENV === "production" ? "5" : "49",
};

export const GST_ES_TaxRateRef = {
  name: "GST ES",
  value: process.env.NODE_ENV === "production" ? "7" : "51",
};

export const PST_SK_on_sales_TaxRateRef = {
  name: "PST (SK) on sales",
  value: process.env.NODE_ENV === "production" ? "15" : "59",
};

export const NOTAXS_TaxRateRef = {
  name: "NOTAXS",
  value: process.env.NODE_ENV === "production" ? "22" : "66",
};
