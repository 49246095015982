import { COMMISSION_SHIPPER } from "../../globalConstants";
import getShippingFlatRate from "./getShippingFlatRate";

const getShippingCostByWarehouseInCart = (productInCartByWarehouse) => {
  const totalWeight = productInCartByWarehouse.products.reduce(
    (acc, product) => {
      const weight =
        product.supplierProductDetail.manufacturerProductDetail?.weight;
      if (!weight) return acc;
      if (
        product.supplierProductDetail.manufacturerProductDetail?.weightUnit ===
        "kg"
      ) {
        return acc + weight * 2.20462 * product.quantity;
      }
      return acc + weight * product.quantity;
    },
    0
  );
  const { shippingRate } = productInCartByWarehouse;

  const applicableRange = shippingRate.weightRanges.find((range, i) => {
    const maxWeight = range.maxWeight || Infinity;

    if (totalWeight > range.minWeight && totalWeight <= maxWeight) return true;
    if (
      totalWeight > range.minWeight &&
      (!shippingRate.weightRanges[i + 1] ||
        shippingRate.weightRanges[i + 1].minWeight > totalWeight)
    )
      return true;
    return false;
  });

  if (!applicableRange) return;
  const { weightCoefficient } = applicableRange;
  const predictedBaseCost = weightCoefficient * totalWeight;

  const shippingFlatRate = getShippingFlatRate({
    shippingRate,
    hazardous: productInCartByWarehouse.products.some(
      (product) => product.supplierProductDetail.product.hazardous
    ),
    applicableRange,
  });

  const finalShippingCost = predictedBaseCost + shippingFlatRate;
  const shippingCostWithCommission =
    finalShippingCost / (1 - COMMISSION_SHIPPER);

  return shippingCostWithCommission;
};

export default getShippingCostByWarehouseInCart;
