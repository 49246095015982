import axios from "axios";

export const getPushNotificationGetSettings = async () => {
  return axios.get("/api/push-notification/get-settings").then((res) => {
    return res.data.pushNotificationSettings ?? {};
  });
};

export const pushNotificationSetFirebaseToken = async ({ token }) => {
  return axios.patch("/api/push-notification/set-firebase-token", {
    token,
  });
};

export const pushNotificationEnable = async () => {
  return axios.patch("/api/push-notification/enable");
};

export const pushNotificationDisable = async () => {
  return axios.patch("/api/push-notification/disable");
};

export const pushNotificationSubscribeToTopic = async ({ topic }) => {
  return axios.patch("/api/push-notification/subscribe-to-topic", {
    topic,
  });
};

export const pushNotificationUnsubscribeFromTopic = async ({ topic }) => {
  return axios.patch("/api/push-notification/unsubscribe-from-topic", {
    topic,
  });
};

export const pushNotificationSendToContact = async ({
  body,
  contactID,
  title,
}) => {
  return axios.post("/api/push-notification/send-to-contact", {
    body,
    contactID,
    title,
  });
};
