import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { CustomModal, FormSwitch } from "../sharedComponents";
import {
  getCompanyOptionLabel,
  getContactOptionLabel,
} from "../../sharedFunctions/labels";
import useAgendas from "../../customHooks/useAgendas";
import useCompleteNotificationByItemID from "../../customHooks/notifications/useCompleteNotificationByItemID";
import useFacilityRestrictedShippers from "../../customHooks/facility/useFacilityRestrictedShippers";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import useShipperCompanies from "../../customHooks/shipperCompany/useShipperCompanies";
import InfoText from "../sharedComponents/InfoText";
import useConfirmShipping from "../../customHooks/productOrder/useConfirmShipping";
import useUpdateProductOrder from "../../customHooks/productOrder/useUpdateProductOrder";
import CustomDatePicker from "../sharedComponents/datePicker/CustomDatePicker";
import useShipperCompanyThirdPartyShippers from "../../customHooks/shipperCompany/useShipperCompanyThirdPartyShippers";

const ShippingConfirmation = ({
  close,
  order,
  orders,
  supplierConfirmingShipping,
  shipmentID,
}) => {
  const agendas = useAgendas();
  const completeNotificationByItemID = useCompleteNotificationByItemID();
  const confirmShipping = useConfirmShipping();

  const [arriveDate, setArriveDate] = useState(null);
  const [backOrdered, setBackOrdered] = useState(false);
  const [brokerageFeeCost, setBrokerageFeeCost] = useState(
    order?.brokerageFeeCost ?? ""
  );
  const [brokerageFeeGST, setBrokerageFeeGST] = useState(
    order?.brokerageFeeGST ?? ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [facilityFreeShipping, setFacilityFreeShipping] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [productAvailable, setProductAvailable] = useState(null);
  const [saving, setSaving] = useState(false);
  const [shipDate, setShipDate] = useState(null);
  const [shipper, setShipper] = useState(order?.shipper ?? "");
  const [shippingCost, setShippingCost] = useState(order.shippingCost ?? "");
  const [thirdPartyShipper, setThirdPartyShipper] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [useThirdPartyShipper, setUseThirdPartyShipper] = useState(
    !!order?.shipper?.isContractingShipper
  );
  const [valid, setValid] = useState(false);
  const { restrictedShippers } = useFacilityRestrictedShippers(
    order?.facility?._id ?? orders[0]?.facility?._id
  );
  const updateOrder = useUpdateProductOrder();
  const { shipperCompanies } = useShipperCompanies();
  const { thirdPartyShippers } = useShipperCompanyThirdPartyShippers(
    shipper?._id
  );

  useEffect(() => {
    if (!order) return;
    if (backOrdered) {
      if (!productAvailable) return setValid(false);
      return setValid(true);
    }
    if (
      !shipper &&
      restrictedShippers?.length === 1 &&
      order.useDrOPsShipping &&
      order.pallets?.length
    ) {
      setShipper(restrictedShippers[0].contact);
    }
  }, [shipper, restrictedShippers, order, backOrdered, productAvailable]);

  useEffect(() => {
    if (shipDate && arriveDate && shipDate > arriveDate) {
      setErrorMessage("Est. Delivery date cannot be before pick up date");
      return setValid(false);
    } else {
      setErrorMessage("");
    }

    if (!arriveDate || !shipDate || !shipper) return setValid(false);

    if (arriveDate && new Date(arriveDate) < new Date() && !shippingCost) {
      setErrorMessage(
        "A shipping cost must be entered if the delivery date is in the past"
      );
      return setValid(false);
    } else {
      setErrorMessage("");
    }

    if (useThirdPartyShipper && !thirdPartyShipper) {
      setErrorMessage("A Assigned shipper must be selected");
      return setValid(false);
    } else {
      setErrorMessage("");
    }

    setValid(true);
  }, [
    arriveDate,
    shipDate,
    shippingCost,
    shipper,
    thirdPartyShipper,
    useThirdPartyShipper,
    order?.pickUpAvailableDate,
    order,
  ]);

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
  };

  const submitOrder = async (order, shippingCostFraction) => {
    if (backOrdered) {
      const body = {
        _id: order._id,
        estimatedArriveDate: moment(productAvailable)
          .add(
            order.warehouse?.contact.address.country === "Canada" ? 7 : 14,
            "days"
          )
          .toDate(),
        pickUpAvailableDate: productAvailable,
        status: "Awaiting Stock",
      };

      await updateOrder.mutateAsync({ order: body });
      agendas.agendaConfirmShippingDetailsMutation.mutate({
        productAvailable,
        orderID: order._id,
      });

      return;
    }

    let body = {
      arriveDate,
      brokerageFeeCost,
      brokerageFeeGST,
      orderID: order._id,
      shipDate,
      shipper: shipper._id,
      supplierShippingCost: shippingCost
        ? shippingCost * shippingCostFraction
        : null,
      trackingNumber,
      facilityFreeShipping,
    };

    if (useThirdPartyShipper) body.thirdPartyShipper = thirdPartyShipper._id;

    body.status = shipDate > new Date() ? "Ready For Delivery" : "On Route";
    await confirmShipping.mutateAsync({ body });
  };

  const completeShipmentNotifications = async () => {
    await completeNotificationByItemID.mutateAsync({ itemID: shipmentID });
  };

  const onSubmit = async () => {
    try {
      setSaving(true);

      if (order) {
        await submitOrder(order, 1);
        close();
      } else if (orders.length) {
        //is a shipment
        let ordersTotal = 0;
        orders.forEach((order) => {
          ordersTotal += order.subtotal;
        });
        orders.forEach(async (order) => {
          await submitOrder(order, order.subtotal / ordersTotal);
        });
        await completeShipmentNotifications();
        close(null, null, true);
      }

      setSaving(false);
      setModalOpen(false);
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  const renderBackOrdered = () => {
    return (
      <>
        <Grid item xs={12} md={4}>
          <CustomDatePicker
            label={"Est. Product Availability"}
            onChange={(value) => {
              setProductAvailable(value);
            }}
            value={productAvailable}
          />
        </Grid>
      </>
    );
  };

  const renderShippingDetails = () => {
    return (
      <>
        <Grid item xs={12} md={4}>
          <Autocomplete
            options={
              restrictedShippers?.length &&
              order?.useDrOPsShipping &&
              order?.pallets?.length
                ? restrictedShippers
                : shipperCompanies
            }
            getOptionLabel={getCompanyOptionLabel}
            value={shipper}
            isOptionEqualToValue={(option, value) => {
              if (!value?.length) return [];
              return option?.contact?._id === value?._id;
            }}
            onChange={(e, value) => {
              if (!value) return setShipper("");
              setShipper(value?.contact);
            }}
            disabled={!!order?.shipper}
            renderInput={(params) => (
              <TextField
                required
                autoComplete="off"
                {...params}
                label="Shipper"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomDatePicker
            label={"Pick Up Date"}
            onChange={(value) => {
              setShipDate(value);
            }}
            value={shipDate}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomDatePicker
            label={"Est. Delivery Date"}
            onChange={(value) => {
              setArriveDate(value);
            }}
            value={arriveDate}
          />
        </Grid>
        {!order.shippingIncluded && (
          <>
            <Grid item xs={12} md={4}>
              <DecimalTextField
                label={"Final Shipping Cost (before tax)"}
                value={shippingCost}
                editMode={true}
                onChange={(e) => {
                  setShippingCost(e.target.value);
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {order.useDrOPsShipping
                        ? order?.shipper?.currency ?? shipper?.currency
                        : order.supplier.currency}
                      <InfoText text="If shipping cost is not entered here you will be able to enter the shipping cost when the order is delivered." />
                    </InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DecimalTextField
                label={"Brokerage Fee"}
                value={brokerageFeeCost}
                editMode={true}
                onChange={(e) => {
                  setBrokerageFeeCost(e.target.value);
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">CAD</InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DecimalTextField
                label={"Brokerage Fee GST"}
                value={brokerageFeeGST}
                editMode={true}
                onChange={(e) => {
                  setBrokerageFeeGST(e.target.value);
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">CAD</InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={false}
              />
            </Grid>
          </>
        )}
        {
          <FormSwitch
            label="Third Party Shipper"
            checked={useThirdPartyShipper}
            onChange={(e) => {
              setUseThirdPartyShipper(e.target.checked);
            }}
            xs={12}
            md={4}
          />
        }
        {useThirdPartyShipper && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={
                thirdPartyShippers?.length
                  ? thirdPartyShippers
                  : shipperCompanies.map((company) => company.contact)
              }
              getOptionLabel={getContactOptionLabel}
              value={thirdPartyShipper}
              isOptionEqualToValue={(option, value) => {
                if (!value?.length) return [];
                return option?._id === value?._id;
              }}
              onChange={(e, value) => {
                setThirdPartyShipper(value);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  autoComplete="off"
                  {...params}
                  label="Third Party Shipper"
                />
              )}
            />
          </Grid>
        )}
        {shippingCost !== "" && (
          <FormSwitch
            label="Facility Free Shipping"
            checked={facilityFreeShipping}
            onChange={(e) => {
              setFacilityFreeShipping(e.target.checked);
            }}
            xs={12}
            md={4}
          />
        )}

        <Grid item xs={12} md={12}>
          <TextField
            autoComplete="off"
            fullWidth
            variant="outlined"
            label="Tracking Number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
          />
        </Grid>
      </>
    );
  };

  const renderShippingConfirmation = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        {shipmentID && (
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="p">
                Please confirm all of these orders are added to your booked
                shipment
              </Typography>
            </Grid>
            {orders.map((order) => (
              <Grid item xs={12} key={order._id}>
                <Typography variant="p">{order.orderNumber}</Typography>
              </Grid>
            ))}
          </Grid>
        )}
        <FormSwitch
          checked={backOrdered}
          onChange={(e) => setBackOrdered(e.target.checked)}
          label={"Back Ordered"}
          xs={6}
          md={4}
        />

        {backOrdered && renderBackOrdered()}
        {!backOrdered && renderShippingDetails()}

        <Grid item xs={12}>
          {errorMessage && (
            <Typography color={"error"} textAlign="center">
              {errorMessage}
            </Typography>
          )}
          <Button
            variant="contained"
            fullWidth
            onClick={onSubmit}
            disabled={!valid || saving}
          >
            {saving ? "Confirming Shipping" : "Confirm Shipping"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  if (supplierConfirmingShipping) return renderShippingConfirmation();

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setModalOpen(true)}
          >
            Shipping Confirmation
          </Button>
        </Grid>
      </Grid>
      <CustomModal
        open={modalOpen}
        close={closeModal}
        title={"Confirm Shipping"}
      >
        {renderShippingConfirmation()}
      </CustomModal>
    </>
  );
};

export default ShippingConfirmation;
