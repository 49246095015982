import React, { useState } from "react";

import { TextField, Button, Grid, Typography, Link } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import useEmailSendReportProblem from "../../customHooks/email/useEmailSendReportProblem";
import { selectUser } from "../../redux/reducers/userSlice";
import { useSelector } from "react-redux";

const ReportProblemForm = ({ close, type }) => {
  const user = useSelector(selectUser);
  const [searchParams] = useSearchParams();
  const facilityID = searchParams.get("id");
  const emailSendReportProblem = useEmailSendReportProblem();
  const [date, setDate] = useState(moment(new Date()));
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);

  const sendReportEmail = async (e) => {
    try {
      setSending(true);

      const formData = new FormData();

      if (files.length)
        for (let i = 0; i < files.length; i++)
          formData.append("files", files[i]);
      formData.append("facilityID", facilityID);
      formData.append("date", date);
      formData.append("notes", notes);
      formData.append("type", type);
      formData.append("userEmail", user.email);

      const res = await emailSendReportProblem.mutateAsync(formData);

      if (res?.data?.success) {
        setSuccess(true);
        await new Promise((r) => setTimeout(r, 5000));
        close(null, null, { date, notes, user });
        setSending(false);
      } else {
        setError(true);
        setSending(false);
      }
    } catch (error) {
      setError(true);
      setSending(false);
      throw error;
    }
  };

  const renderContent = () => {
    if (success)
      return (
        <Typography variant="h6">
          Thank You! The problem has been submitted to our team and will be
          reviewed in the next couple hours.
        </Typography>
      );
    if (error)
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              Sorry, something went wrong when sending the report. Please
              refresh the page and try again.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex" }}>
            <Typography variant="h6">If the problem persists</Typography>
            <Link to="mailto:support@dropsolutions.ca?subject=drOPs Support">
              <Button
                fullWidth
                onClick={() =>
                  (window.location.href =
                    "mailto:support@dropsolutions.ca?subject=drOPs Support")
                }
              >
                <EmailIcon /> Email us
              </Button>
            </Link>
          </Grid>
        </Grid>
      );
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            id="notes"
            name="notes"
            label="Description"
            variant="outlined"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            id="date"
            name="date"
            label="Date of Problem"
            onChange={(value) => {
              setDate(value);
            }}
            value={date}
          />
        </Grid>
        <Grid item xs={12}>
          <input
            onChange={(e) => {
              setFiles(e.target.files);
            }}
            type="file"
            accept=".jpeg, .png, .jpg, .gif, .svg, .pdf"
            multiple
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            onClick={sendReportEmail}
            disabled={sending || !notes || !date}
          >
            {sending ? "Sending" : " Send"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return renderContent();
};

export default ReportProblemForm;
