export const getChemicalOptionLabel = (
  option,
  containerSizeOption,
  removeNSF
) => {
  if (!option) return "";
  let { name, concentration, NSFType, NSF61Approval, containerType } = option;

  let NSF61 = "";
  if (NSF61Approval && !removeNSF) NSF61 = `NSF${NSFType ? NSFType : ""}`;

  if (concentration)
    concentration = concentration === 1 ? null : concentration * 100 + "%";

  let label = [name];

  if (concentration) {
    label.push(" ", concentration);
  }

  if (containerSizeOption) {
    label.push(" ", containerSizeOption);
  }

  if (containerType) {
    label.push(" ", containerType);
  }

  if (NSF61) {
    label.push(" ", NSF61);
  }

  label = label.join("");

  if (label.trim().length === 0) return "";
  return label || "";
};

export const getMembraneOptionLabel = (option) => {
  if (!option) return "";
  let { diameter, name, membraneType } = option;
  if (diameter) diameter += '"';

  const label = [name, " ", diameter, " ", membraneType].join("");

  if (label.trim().length === 0) return "";
  return label || "";
};

export const getFilterOptionLabel = (option, noFilterType) => {
  if (!option) return "";

  let {
    type,
    filterType,
    filterMaterial,
    filterEnds,
    diameter,
    length,
    filterRating,
  } = option;

  if (diameter) diameter += '" x';
  if (length) length += '"';

  let label = [];
  if (!noFilterType) label.push(type, " ", filterType, " ");

  label.push(
    filterMaterial,
    " ",
    filterEnds,
    " ",
    diameter,
    " ",
    length,
    " ",
    filterRating
  );

  label = label.join("");

  if (label.trim().length === 0 || label.trim() === "0") return "";
  return label || "";
};

export const getAddressLabel = (address) => {
  if (!address) return "";

  let formattedAddress = address.streetAddress;
  if (address.secondaryAddress)
    formattedAddress += `, ${address.secondaryAddress}`;

  if (address.city) formattedAddress += `, ${address.city}`;
  if (address.province) formattedAddress += `, ${address.province}`;
  if (address.postalCode) formattedAddress += ` ${address.postalCode}`;
  if (address.country) formattedAddress += `, ${address.country}`;

  return formattedAddress;
};

export const getContactOptionLabel = (option) => {
  if (!option) return "";
  let label = option.name || option.firstName || option.email;
  const companyName = option?.parentCompanies?.[0]?.name;

  if (companyName) label += " (" + companyName + ")";
  return label || "";
};

export const getWarehouseOptionLabel = (option) => {
  if (!option || !option.contact) return "";
  const contact = option.contact;
  let label = contact.name || contact.firstName || contact.email;
  const companyName = contact?.parentCompanies?.[0]?.name;

  if (companyName) label += " (" + companyName + ")";
  return label || "";
};

export const getCompanyOptionLabel = (option) => {
  if (!option) return "";

  const contact = option.contact;
  let label;
  let companyName;
  if (contact) {
    label = contact.name || contact.firstName || contact.email;
    companyName = contact?.parentCompanies?.[0]?.name;
  } else {
    label = option.name || option.firstName || option.email;
    companyName = option?.parentCompanies?.[0]?.name;
  }

  if (companyName) label += " (" + companyName + ")";
  return label || "";
};

export const getPartOptionLabel = (option) => {
  if (!option) return "";
  let label = option.name + " ";
  return label || "";
};

export const getTestingMaterialsOptionLabel = (option) => {
  if (!option) return "";
  let label = "";
  label += option.name;
  if (option.containerSize) label += ` ${option.containerSize}`;
  if (option.containerSizeUnit) label += ` ${option.containerSizeUnit}`;

  return label || "";
};

export const getProductOptionLabel = (product, containerSize) => {
  if (!product) return "";
  if (product?.type === "Chemical")
    return getChemicalOptionLabel(product, containerSize);
  if (product?.type === "Part" || product?.type === "Equipment")
    return getPartOptionLabel(product);
  if (
    product?.type === "Membrane" ||
    product?.type === "Hollow Fiber Membrane" ||
    product?.type === "Spiral Wound Membrane"
  )
    return getMembraneOptionLabel(product);
  if (product?.type === "Filter") return getFilterOptionLabel(product);
  if (product.type === "Container") return product.containerType;
  if (product?.type === "Testing and Calibration Materials")
    return getTestingMaterialsOptionLabel(product);
  if (product?.type === "Other") return product.name;
  if (product?.type === "Janitorial Supplies") return product.name;
};

export const getProductOptionLabelWithManufacturerAndPartNumber = (
  product,
  manufacturerProductDetail
) => {
  let containerSize = manufacturerProductDetail?.containerSize ?? "";
  if (containerSize) {
    containerSize =
      containerSize + (manufacturerProductDetail?.containerSizeUnit ?? "");
  }

  return (
    (manufacturerProductDetail?.manufacturer?.name
      ? `${manufacturerProductDetail.manufacturer.name} `
      : "") +
    getProductOptionLabel(product, containerSize) +
    (manufacturerProductDetail?.partNumber
      ? ` ${manufacturerProductDetail.partNumber}`
      : "")
  );
};

export const getProductOptionLabelWithManufacturer = (
  product,
  manufacturerProductDetail
) => {
  return (
    (manufacturerProductDetail?.manufacturer?.name
      ? `${manufacturerProductDetail.manufacturer.name} `
      : "") + getProductOptionLabel(product)
  );
};

export const getServiceOptionLabel = (
  option
  // supplierID,
  // exchangeRate
) => {
  if (!option) return "";
  let tempOption = "";
  const name = option.name;
  if (name) tempOption += name;

  // const price = calculateServicePrice(
  //   option.supplierServiceDetails?.find(
  //     (serviceDetail) =>
  //       (serviceDetail.supplier?._id ??
  //         serviceDetail.supplier) === supplierID
  //   )?.cost,
  //   exchangeRate?.rate,
  //   supplierID
  // );
  // if (price) tempOption += ` $${price}/${option.billingRate}`;

  return tempOption;
};

export const getServiceDescriptionLabel = (service) => {
  if (!service) return "";

  let label = "";
  if (service.service?.name) {
    label += service.service.name;
    if (service.serviceDescription) {
      label += ":  " + service.serviceDescription;
    } else if (service.service?.serviceDescription) {
      label += ":  " + service.service.serviceDescription;
    }
  } else if (service.serviceDescription) {
    label = service.serviceDescription;
  } else if (service.service?.serviceDescription) {
    label = service.service.serviceDescription;
  }

  return label;
};
