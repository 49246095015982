import calculateProductPrice from "./calculateProductPrice";
import getShippingFlatRate from "./getShippingFlatRate";

export const getProductShippingPrice = ({
  amount,
  exchangeRate,
  shippingRate,
  supplierProductDetail,
}) => {
  if (!amount || !shippingRate) return;

  let weight = supplierProductDetail.manufacturerProductDetail?.weight;
  if (!weight) return;

  //convert kg to lbs
  if (supplierProductDetail.manufacturerProductDetail?.weightUnit === "kg") {
    weight = weight * 2.20462;
  }
  const totalWeight = amount * weight;

  const applicableRange = shippingRate.weightRanges.find((range) => {
    const maxWeight = range.maxWeight || Infinity;
    if (totalWeight < range.minWeight) return true;
    return totalWeight > range.minWeight && totalWeight <= maxWeight;
  });

  if (!applicableRange) return;

  const { weightCoefficient } = applicableRange;
  const predictedBaseCost = weightCoefficient * totalWeight;

  let finalShippingCost = calculateProductPrice(
    predictedBaseCost,
    exchangeRate,
    supplierProductDetail.supplier._id
  );

  finalShippingCost += getShippingFlatRate({
    shippingRate,
    hazardous: supplierProductDetail.product.hazardous,
    applicableRange,
  });

  if (isNaN(finalShippingCost)) return;

  return finalShippingCost;
};
