import { useQuery } from "@tanstack/react-query";
import { getCartSubmissionByID } from "../../api/cartSubmissionAPI";

const useCartSubmissionByID = (id) => {
  const { data: cartSubmission, isLoading } = useQuery({
    queryKey: ["cartSubmissions", id],
    queryFn: () => getCartSubmissionByID(id),
    enabled: !!id,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { cartSubmission, isLoading };
};

export default useCartSubmissionByID;
