import { useMutation, useQueryClient } from "@tanstack/react-query";
import { serviceContractRefund } from "../../api/serviceContractOrderAPI";

const useServiceContractRefund = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: serviceContractRefund,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
    },
  });
};

export default useServiceContractRefund;
