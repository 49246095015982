import axios from "axios";

export const getShippingRate = async ({
  facilityID,
  shipperID,
  warehouseID,
}) => {
  return axios
    .get("/api/shipping-rate/get-shipping-rate", {
      params: {
        facilityID,
        shipperID,
        warehouseID,
      },
    })
    .then((res) => res.data.shippingRate);
};

export const getShipperShippingRates = async ({ shipperID }) => {
  return axios
    .get("/api/shipping-rate/get-shipper-shipping-rates", {
      params: {
        shipperID,
      },
    })
    .then((res) => res.data.shippingRates);
};

export const updateOrCreateShippingRate = async ({
  facilityID,
  shipperID,
  warehouseID,
}) => {
  return axios.post("/api/shipping-rate/update-or-create-shipping-rate", {
    facilityID,
    shipperID,
    warehouseID,
  });
};

export const updateShipperShippingRates = async ({
  shipperID,
  shippingRates,
}) => {
  return axios.patch("/api/shipping-rate/update-shipper-shipping-rates", {
    shipperID,
    shippingRates,
  });
};
