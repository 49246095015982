import { useQuery } from "@tanstack/react-query";
import { getAllServices } from "../../api/serviceAPI";

const useServices = (supplierID) => {
  const {
    data: services = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["services", supplierID],
    queryFn: () => getAllServices(supplierID),
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    services,
    error,
    isError,
    isLoading,
  };
};

export default useServices;
