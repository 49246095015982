import { useMutation } from "@tanstack/react-query";

import { accountingAddDocument } from "../../api/accountingAPI";

const useAccountingAddDocument = () => {
  return useMutation({
    mutationFn: accountingAddDocument,
  });
};

export default useAccountingAddDocument;
