import { useQuery } from "@tanstack/react-query";
import { getProductOrderShippingLabel } from "../../api/productOrderAPI";

const useProductOrderShippingLabel = (orderID) => {
  const { data: shippingLabels } = useQuery({
    queryKey: ["order", orderID],
    queryFn: () => getProductOrderShippingLabel(orderID),
    enabled: !!orderID,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  return { shippingLabels };
};

export default useProductOrderShippingLabel;
