import AddBoxIcon from "@mui/icons-material/AddBox";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import DescriptionIcon from "@mui/icons-material/Description";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const AccountingBottomNav = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [navPage, setNavPage] = useState(tab ? parseInt(tab) : 0);

  useEffect(() => {
    if (parseInt(tab) !== navPage) {
      setNavPage(parseInt(tab));
    }
  }, [tab, navPage]);

  return (
    <Paper className="bottom-navigation-paper" elevation={3}>
      <BottomNavigation
        showLabels
        value={navPage}
        onChange={(event, newValue) => {
          const modifiedSearchParams = new URLSearchParams(searchParams);
          modifiedSearchParams.set("tab", newValue);
          setNavPage(newValue);
          setSearchParams(modifiedSearchParams);
        }}
        sx={{
          ".MuiBottomNavigationAction-label": { fontSize: "0.75rem" },
          ".MuiBottomNavigationAction-root": {
            xs: {
              minWidth: "0",
              margin: "0 2px",
            },
          },
        }}
      >
        <BottomNavigationAction
          icon={
            <AnalyticsIcon
              className="navigation-icon"
              color={navPage === 0 ? "primary" : "default"}
            />
          }
        />
        <BottomNavigationAction
          icon={
            <AddBoxIcon
              className="navigation-icon"
              color={navPage === 1 ? "primary" : "default"}
            />
          }
        />
        <BottomNavigationAction
          icon={
            <DescriptionIcon
              className="navigation-icon"
              color={navPage === 2 ? "primary" : "default"}
            />
          }
        />
      </BottomNavigation>
    </Paper>
  );
};

export default AccountingBottomNav;
