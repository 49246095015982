import { useQuery } from "@tanstack/react-query";
import { getPayments } from "../../api/paymentAPI";

const usePayments = (enabled) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["payments"],
    queryFn: () => getPayments(),
    enabled: enabled,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, error };
};

export default usePayments;
