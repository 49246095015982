import { useQuery } from "@tanstack/react-query";
import { getAllProductServiceNames } from "../../api/productAPI";

const useProductServiceNames = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["productServiceNames"],
    queryFn: getAllProductServiceNames,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  return { data, isLoading };
};

export default useProductServiceNames;
