import { useQuery } from "@tanstack/react-query";
import { getShippingRate } from "../../api/shippingRateAPI";

const useShippingRate = ({ facilityID, shipperID, warehouseID }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["shippingRate", { facilityID, shipperID, warehouseID }],
    queryFn: () => getShippingRate({ facilityID, shipperID, warehouseID }),
    staleTime: 1000 * 60 * 10,
    enabled: !!facilityID && !!warehouseID,
  });

  return { data, isLoading, error };
};

export default useShippingRate;
