import axios from "axios";

export const getPayments = async () => {
  return axios.get(`/api/payment/get-all`).then((res) => {
    return res?.data?.payment;
  });
};

export const getPayment = async (paymentID) => {
  return axios.get(`/api/payment/get-by-id?id=${paymentID}`).then((res) => {
    return res?.data?.payment;
  });
};
