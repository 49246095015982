import { useQuery } from "@tanstack/react-query";
import { getServiceQuotes } from "../../api/serviceOrderAPI";
import useContact from "../contacts/useContact";

const useServiceOrderQuotes = (serviceOrderID) => {
  const { contact } = useContact();

  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["serviceQuotes", serviceOrderID],
    queryFn: () =>
      getServiceQuotes({ serviceOrderID, contactID: contact?._id }),
    enabled: !!serviceOrderID,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useServiceOrderQuotes;
