import { useQuery } from "@tanstack/react-query";
import { getAllCartSubmissionsByRequestedUser } from "../../api/cartSubmissionAPI";
import { useDispatch } from "react-redux";
import { setCartSubmissions } from "../../redux/reducers/productsSlice";
import { useEffect } from "react";

const useCartSubmissionsByRequestedUser = (id) => {
  const dispatch = useDispatch();

  const { data: cartSubmissions = [] } = useQuery({
    queryKey: ["cartSubmissions"],
    queryFn: () => getAllCartSubmissionsByRequestedUser(id),
    enabled: !!id,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!cartSubmissions?.length) return;
    dispatch(setCartSubmissions(cartSubmissions));
  }, [cartSubmissions, dispatch]);
};

export default useCartSubmissionsByRequestedUser;
