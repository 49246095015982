import axios from "axios";

export const getInvoices = async () => {
  return axios.get(`/api/invoice/get-all`).then((res) => {
    return res?.data?.invoice;
  });
};

export const getInvoice = async (invoiceID) => {
  return axios.get(`/api/invoice/get-by-id?id=${invoiceID}`).then((res) => {
    return res?.data?.invoice;
  });
};

export const getInvoiceByCustomer = async (customerID, outStanding) => {
  return axios
    .get(
      `/api/invoice/get-by-customer?customerID=${customerID}&outStanding=${outStanding}`
    )
    .then((res) => {
      return res?.data?.invoice;
    });
};
