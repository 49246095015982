import { useMutation, useQueryClient } from "@tanstack/react-query";

import { creditMemoApplyCredit } from "../../api/creditMemoAPI";

const useCreditMemoApplyCredit = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: creditMemoApplyCredit,
    onSuccess: () => {
      queryClient.invalidateQueries("creditMemos");
    },
  });
};

export default useCreditMemoApplyCredit;
