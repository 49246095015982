import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import formatDate from "../../../sharedFunctions/formatDate";

const OrderDisplayAppliedCredits = ({ order, orderView }) => {
  const appliedCreditMemos = () => {
    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Applied Credit</Typography>
        </Grid>
        {order?.appliedCreditMemos.map((creditMemo) => {
          return (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography color="limegreen">
                  {formatDate(creditMemo.date)} - Credit Memo{" "}
                  {creditMemo.docNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="limegreen">
                  Amount: ${creditMemo.amount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  };

  const appliedVendorCredits = () => {
    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Applied Credit</Typography>
        </Grid>
        {order?.appliedVendorCredits.map((vendorCredit) => {
          return (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography color="limegreen">
                  {formatDate(vendorCredit.date)} - Vendor Credit{" "}
                  {vendorCredit.docNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="limegreen">
                  Amount: ${vendorCredit.amount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Grid container item spacing={2} xs={12}>
      {orderView === "facility" &&
        order?.appliedCreditMemos?.length > 0 &&
        appliedCreditMemos()}
      {orderView === "supplier" &&
        order?.appliedVendorCredits?.length > 0 &&
        appliedVendorCredits()}
    </Grid>
  );
};

export default OrderDisplayAppliedCredits;
