import axios from "axios";

export const getServiceContractsByFacilityID = async ({
  facilityID,
  toggleCompleted,
}) => {
  if (!facilityID) return;
  return axios
    .get(
      `/api/service-contract-order/get-all-by-facility?facilityID=${facilityID}&completed=${toggleCompleted}`
    )
    .then((res) => res?.data?.serviceContracts ?? []);
};

export const getServiceContractsBySupplierID = async ({
  supplierID,
  toggleCompleted,
}) => {
  if (!supplierID) return;
  return axios

    .get(
      `/api/service-contract-order/get-all-by-supplier?supplierID=${supplierID}&completed=${toggleCompleted}`
    )
    .then((res) => res?.data?.serviceContracts ?? []);
};

export const getServiceContractsByFacilityAndSupplier = async ({
  facilityID,
  supplierIDs,
  toggleCompleted,
}) => {
  if (!facilityID || !supplierIDs?.length) return;
  return axios
    .get(
      `/api/service-contract-order/get-all-by-facility-and-service-provider?facilityID=${facilityID}&supplierIDs=${supplierIDs.join(
        ","
      )}&completed=${toggleCompleted}`
    )
    .then((res) => res?.data?.serviceContracts ?? []);
};

export const getServiceContractByID = async ({ serviceContractID }) => {
  if (!serviceContractID) return {};
  return axios
    .get(`/api/service-contract-order/get-by-id?id=${serviceContractID}`)
    .then((res) => res?.data?.serviceContract);
};

/*
@params
formDta: {
  facilityID
  file
  name
  serviceCompletion
  supplierID
  serviceContractToRemove
}
*/
export const addServiceContract = async (formData) => {
  return axios.post("/api/service-contract-order/add", formData).then((res) => {
    return res?.data?.serviceContract;
  });
};

/*
@params
formDta: {
  _id
  facilityID
  file
  name
  serviceCompletion
  supplierID
  serviceContractToRemove
}
*/
export const updateServiceContract = async (formData) => {
  return axios.put("/api/service-contract-order/update", formData);
};

export const createServiceContractRequest = async (body) => {
  return axios.post("/api/service-contract-order/create-request", body);
};

export const approveServiceContractRequest = async (body) => {
  return axios.post("/api/service-contract-order/approve-request", body);
};

export const adjustServiceContractRequest = async (body) => {
  return axios.post("/api/service-contract-order/adjust-request", body);
};

export const updateServiceContractRequest = async (body) => {
  return axios.put("/api/service-contract-order/update-request", body);
};

export const denyServiceContractRequest = async (body) => {
  return axios.post("/api/service-contract-order/deny-request", body);
};

export const serviceContractUpdateServiceDates = async (body) => {
  return axios.patch("/api/service-contract-order/update-service-dates", body);
};

export const serviceContractConfirmServiceDate = async (body) => {
  return axios.patch("/api/service-contract-order/confirm-service-date", body);
};

export const requestServiceContractServiceDateChange = async (body) => {
  return axios.patch(
    "/api/service-contract-order/request-service-date-change",
    body
  );
};

export const cancelServiceContract = async (body) => {
  return axios.patch("/api/service-contract-order/cancel", body);
};

export const serviceContractCloseout = async (body) => {
  return axios.patch("/api/service-contract-order/contract-closeout", body);
};

export const renewServiceContract = async (body) => {
  return axios.post("/api/service-contract-order/renew", body);
};

export const updateServiceContractStatus = async (body) => {
  return axios.patch("/api/service-contract-order/update-status", body);
};

export const serviceContractRefund = async (body) => {
  return axios.post("/api/service-contract-order/refund", body);
};
