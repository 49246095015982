import axios from "axios";
import { superUserEmails } from "../globalConstants";

export const getSupplierCompaniesByContact = async ({ contact }) => {
  return axios
    .get(
      superUserEmails.includes(contact.email)
        ? "/api/supplier-company/get-all"
        : `/api/supplier-company/get-all-by-contact?contactID=${contact._id}`
    )
    .then((res) => res.data.supplierCompanies);
};

export const getSupplierCompanies = async () => {
  return axios
    .get("/api/supplier-company/get-all")
    .then((res) => res.data.supplierCompanies);
};

export const getSupplierCompaniesWithFacilities = async () => {
  return axios
    .get("/api/supplier-company/get-all-suppliers-with-facilities")
    .then((res) => res.data.supplierCompanies);
};

export const getSupplierReps = async ({ facilityID }) => {
  return axios
    .get(`/api/supplier-company/get-supplier-reps?facilityID=${facilityID}`)
    .then((res) => res.data.supplierReps);
};

export const getSupplierCompanyByID = async (id) => {
  return axios
    .get(`/api/supplier-company/get-by-id?id=${id}`)
    .then((res) => res.data.supplierCompany);
};

export const getEmployeeEmails = async (id) => {
  return axios
    .get(`/api/supplier-company/get-employee-emails?supplierID=${id}`)
    .then((res) => res.data.emails);
};

export const getSupplierCompanyEmployees = async (supplierID) => {
  return axios
    .get(`/api/supplier-company/get-employees?supplierID=${supplierID}`)
    .then((res) => res.data.employees);
};

export const getSupplierCompanyLogoIDByContact = async (id) => {
  return axios
    .get(`/api/supplier-company/get-logo-by-contact?id=${id}`)
    .then((res) => res.data.fileID);
};

export const getSupplierNotificationCount = async (id) => {
  if (!id) return;
  return axios
    .get(`/api/supplier-company/get-notification-count?id=${id}`)
    .then((res) => res?.data?.notificationCount);
};

export const getSupplierCompanyAvailableServiceDates = async (id) => {
  return axios
    .get(`/api/supplier-company/get-available-service-dates?id=${id}`)
    .then((res) => res.data.availableServiceDates);
};

export const getSupplierManufacturers = async (supplierCompanyID) => {
  return axios
    .get(
      `/api/supplier-company/get-supplier-manufacturers?id=${supplierCompanyID}`
    )
    .then((res) => res?.data?.supplierManufacturers ?? []);
};

export const addSupplierCompany = async ({ contactID, isWarehouse }) => {
  return axios
    .post("/api/supplier-company/add", {
      contactID,
      isWarehouse,
    })
    .then((res) => res.data.supplierCompany);
};

export const addSupplierCompanyLogo = async ({ formData }) => {
  return axios
    .patch(`/api/supplier-company/add-logo`, formData, {
      bypass: true,
    })
    .then((res) => res.data.supplierCompany);
};

export const addFacilityToSupplierCompany = async ({
  facilityID,
  approvedSuppliersIDs,
}) => {
  return axios.patch("/api/supplier-company/add-facility", {
    facilityID,
    approvedSuppliersIDs,
  });
};

export const addWarehouseToSupplierCompany = async ({
  supplierCompanyID,
  warehouseContactID,
}) => {
  return axios.patch("/api/supplier-company/add-warehouse", {
    supplierCompanyID,
    warehouseContactID,
  });
};
/*
@params
  administrators: [{_id: contact._id, isSupplier: contact.isSupplier}],
  facilityAssignments: [{representative: {_id: representative._id }}],
  managers: [{_id: contact._id, isSupplier: contact.isSupplier}],
  representatives: [{_id: contact._id, isSupplier: contact.isSupplier}],
  serviceRepresentatives: [{_id: contact._id, isSupplier: contact.isSupplier}],
  supplierCompanyID: supplierCompany._id,
*/
export const updateSupplierCompanyEmployees = async ({
  administrators,
  facilityAssignments,
  managers,
  representatives,
  serviceRepresentatives,
  supplierCompanyID,
}) => {
  return axios.patch("/api/supplier-company/update-employees", {
    administrators,
    facilityAssignments,
    managers,
    representatives,
    serviceRepresentatives,
    supplierCompanyID,
  });
};

/*
@params
contactID: contact._id,
supplierCompanyID: supplierCompany._id,
userRole: owners || managers || administrators || leadOperators || operators || regulators
*/
export const updateSupplierCompanyUserRole = async ({
  contactID,
  supplierCompanyID,
  userRole,
}) => {
  return axios.patch("/api/supplier-company/update-user-role", {
    contactID,
    supplierCompanyID,
    userRole,
  });
};

export const removeFacilityFromSupplierCompany = async ({
  facilityID,
  supplierContactID,
}) => {
  return axios.patch("/api/supplier-company/remove-facility", {
    facilityID,
    supplierContactID,
  });
};
/*
@params
 contact: {
  _id: contact._id,
  isSupplier: contact.isSupplier,
  isWarehouse: contact.isWarehouse,
},
supplierCompany: {
  _id: supplierCompany._id,
  contact: { _id: supplierCompany.contact._id },
},
*/
export const removeContactFromSupplierCompany = async ({
  contact,
  parentCompanyContactID,
}) => {
  axios.patch("/api/supplier-company/remove-contact", {
    contact,
    parentCompanyContactID,
  });
};

export const setSupplierCompanyWarehouses = async ({
  supplierCompanyID,
  warehouseIDs,
}) => {
  return axios.patch("/api/supplier-company/set-warehouses", {
    supplierCompanyID,
    warehouseIDs,
  });
};

export const assignSupplierCompanyFacilities = async ({
  assignedFacilityIDs,
  employeeID,
  supplierCompanyID,
}) => {
  return axios.patch("/api/supplier-company/assign-facilities", {
    assignedFacilityIDs,
    employeeID,
    supplierCompanyID,
  });
};

export const updateSupplierCompanyAvailableServiceDates = async ({
  supplierCompanyID,
  availableServiceDates,
}) => {
  return axios.patch("/api/supplier-company/update-available-service-dates", {
    supplierCompanyID,
    availableServiceDates,
  });
};

export const addToSupplierCompanyUnavailableServiceDates = async ({
  supplierCompanyID,
  unavailableServiceDate,
}) => {
  return axios.patch("/api/supplier-company/add-to-unavailable-service-dates", {
    supplierCompanyID,
    unavailableServiceDate,
  });
};

export const removeSupplierCompanyUnavailableServiceDates = async ({
  supplierCompanyID,
  unavailableServiceDate,
}) => {
  return axios.patch(
    "/api/supplier-company/remove-from-unavailable-service-dates",
    {
      supplierCompanyID,
      unavailableServiceDate,
    }
  );
};

export const updateSupplierCompanyAddSupplierManufacturer = async ({
  supplierCompanyID,
  manufacturerID,
}) => {
  return axios.patch("/api/supplier-company/add-supplier-manufacturer", {
    supplierCompanyID,
    manufacturerID,
  });
};

export const updateSupplierCompanyRemoveSupplierManufacturer = async ({
  supplierCompanyID,
  manufacturerID,
}) => {
  return axios.patch("/api/supplier-company/remove-supplier-manufacturer", {
    supplierCompanyID,
    manufacturerID,
  });
};

export const updateSupplierCompanyValidTill = async ({
  supplierCompanyID,
  validTillDate,
}) => {
  return axios.patch("/api/supplier-company/update-valid-till", {
    supplierCompanyID,
    validTillDate,
  });
};
