import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import {
  getProductOptionLabel,
  getServiceDescriptionLabel,
} from "../../../sharedFunctions/labels";
import customDollarRound from "../../../sharedFunctions/purchasingFunctions/customDollarRound";

const OrderDisplayProducts = (props) => {
  const { items, orderType, orderView } = props;

  const renderProductOrderProducts = () => {
    if (orderView === "shipper" || items?.length <= 0) return;
    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {items.map((productDetail, i) => {
          if (
            productDetail.product.type === "Container" &&
            productDetail?.isReturn
          )
            return null;
          let priceDetails = `Price: ${
            orderView === "supplier"
              ? productDetail.cost.toFixed(2)
              : productDetail.price.toFixed(2)
          }`;
          if (productDetail.containerDeposit)
            priceDetails += `, Deposit: ${productDetail.containerDeposit.toFixed(
              2
            )}`;
          priceDetails += `, Quantity: ${productDetail.quantity}, Total: $${
            orderView === "supplier"
              ? productDetail.supplierTotal.toFixed(2)
              : productDetail.total.toFixed(2)
          }`;

          return (
            <Grid item xs={12} key={productDetail.product._id + i}>
              <Grid item xs={12}>
                <Typography>
                  {getProductOptionLabel(productDetail.product)}
                </Typography>
                {productDetail?.manufacturerProductDetail?.partNumber && (
                  <Typography>
                    Part #: {productDetail.manufacturerProductDetail.partNumber}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>{priceDetails}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  };

  const renderServiceOrderServices = () => {
    if (items?.services?.length <= 0) return;
    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {items?.services?.map((serviceDetail, i) => (
          <Grid item xs={12} key={serviceDetail.service._id + i}>
            <Grid item xs={12}>
              <Typography>
                {getServiceDescriptionLabel(serviceDetail)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                Price:{" "}
                {orderView === "supplier"
                  ? serviceDetail.cost?.toFixed(2)
                  : serviceDetail.price?.toFixed(2)}
                , Quantity: {serviceDetail.quantity}, Total:{" "}
                {orderView === "supplier"
                  ? (serviceDetail.cost * serviceDetail.quantity).toFixed(2)
                  : serviceDetail.total?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {items?.materialsUsed?.map((material, i) => (
          <Grid item xs={12} key={material._id + i}>
            <Grid item xs={12}>
              <Typography>{material.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Price:{" "}
                {orderView === "supplier"
                  ? material.cost.toFixed(2)
                  : material.price.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const renderServiceContractOrderServices = () => {
    if (items?.services?.length <= 0) return;
    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {items.services.map((service, i) => (
          <Grid container item spacing={2} xs={12} key={"service contract" + i}>
            <Grid item xs={12}>
              <Typography>Service Year {i + 1}</Typography>
            </Grid>
            {service.map((serviceDetail, y) => {
              const priceDetails =
                orderView === "supplier"
                  ? `Price: ${serviceDetail.cost.toFixed(2)}, Quantity: ${
                      serviceDetail.quantity
                    }, Total: ${customDollarRound(
                      serviceDetail.cost * serviceDetail.quantity
                    ).toFixed(2)}`
                  : `Price: ${serviceDetail.price.toFixed(2)}, Quantity: ${
                      serviceDetail.quantity
                    }, Total: ${serviceDetail.total.toFixed(2)}`;
              return (
                <Grid item xs={12} key={serviceDetail._id + i + y}>
                  <Grid item xs={12}>
                    <Typography>{serviceDetail.service.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{priceDetails}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </>
    );
  };

  const renderServiceContractCloseoutOrder = () => {
    return items.services.map((serviceDetail, i) => {
      const priceDetails =
        orderView === "supplier"
          ? `Price: ${serviceDetail.cost.toFixed(2)}, Quantity: ${
              serviceDetail.quantity
            }, Total: ${customDollarRound(
              serviceDetail.cost * serviceDetail.quantity
            ).toFixed(2)}`
          : `Price: ${serviceDetail.price.toFixed(2)}, Quantity: ${
              serviceDetail.quantity
            }, Total: ${serviceDetail.total.toFixed(2)}`;
      return (
        <Grid item xs={12} key={"service" + i}>
          <Grid item xs={12}>
            <Typography>{serviceDetail.service.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{priceDetails}</Typography>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid container item spacing={2} xs={12}>
      {orderType === "ProductOrder" && renderProductOrderProducts()}
      {orderType === "ServiceOrder" && renderServiceOrderServices()}
      {orderType === "ServiceContractOrder" &&
        renderServiceContractOrderServices()}
      {orderType === "ServiceContractCloseoutOrder" &&
        renderServiceContractCloseoutOrder()}
    </Grid>
  );
};

export default OrderDisplayProducts;
