import React, { useEffect } from "react";
import { Divider, Grid, Typography, Fab, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch } from "react-redux";
import { getProductOptionLabel } from "../../sharedFunctions/labels";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import { FormSwitch } from "../sharedComponents";
import calculateProductPrice from "../../sharedFunctions/purchasingFunctions/calculateProductPrice";
import { findTierPricingCost } from "../../sharedFunctions/purchasingFunctions/findTierPricingCost";
import {
  addToProductsInCartByWarehouse,
  removeProductFromCartByWarehouse,
  reduceProductsInCartByWarehouse,
  setProductsInCartByWarehouse,
} from "../../redux/reducers/productsSlice";
import useShippingRate from "../../customHooks/shippingRate/useShippingRate";

const ShippingSavings = ({ exchangeRates, productInCart }) => {
  const exchangeRate = exchangeRates.find(
    (exchangeRate) =>
      exchangeRate.currency ===
      productInCart.supplierProductDetail.supplier.currency
  )?.rate;

  const tierPricing = productInCart.supplierProductDetail.tierPricing;

  if (!tierPricing?.length) return;

  const nextTierCostIndex = tierPricing.findIndex(
    (tier) => tier.quantity > productInCart.quantity
  );
  const nextTierCost = tierPricing[nextTierCostIndex];

  if (!nextTierCost || nextTierCost.quantity <= productInCart.quantity) return;

  return (
    <Grid item xs={12}>
      <Typography color={"limegreen"}>
        Add {nextTierCost.quantity - productInCart.quantity} More to save $
        {(
          calculateProductPrice(
            tierPricing[nextTierCostIndex - 1].cost,
            exchangeRate,
            productInCart.supplierProductDetail.supplier._id
          ) -
          calculateProductPrice(
            nextTierCost.cost,
            exchangeRate,
            productInCart.supplierProductDetail.supplier._id
          )
        ).toFixed(2)}{" "}
        per item
      </Typography>
    </Grid>
  );
};

const RenderProductsInCartByWarehouse = ({
  allProductHavePickUp,
  exchangeRates,
  productsInCartByWarehouse,
  warehouseInCart,
  facility,
  viewOnly,
}) => {
  const dispatch = useDispatch();
  const { data: shippingRate } = useShippingRate({
    facilityID: facility?._id,
    shipperID: warehouseInCart.shipperCompany?.contact?._id,
    warehouseID: warehouseInCart.warehouseID,
  });

  const productInCartByWarehouseIndex = productsInCartByWarehouse.findIndex(
    (warehouse) => warehouse.warehouseID === warehouseInCart.warehouseID
  );

  //set the correct shipping rate for the warehouse in cart
  useEffect(() => {
    if (
      !shippingRate ||
      !productsInCartByWarehouse?.length ||
      productsInCartByWarehouse[productInCartByWarehouseIndex].shippingRate
    )
      return;

    const tempProductsInCartByWarehouse = [...productsInCartByWarehouse];
    const tempProductInCartByWarehouse = {
      ...tempProductsInCartByWarehouse[productInCartByWarehouseIndex],
    };
    tempProductInCartByWarehouse.shippingRate = shippingRate;
    tempProductsInCartByWarehouse[productInCartByWarehouseIndex] =
      tempProductInCartByWarehouse;

    dispatch(setProductsInCartByWarehouse(tempProductsInCartByWarehouse));
  }, [
    productsInCartByWarehouse,
    productInCartByWarehouseIndex,
    dispatch,
    shippingRate,
  ]);

  return (
    <Grid container spacing={2} item xs={12} key={warehouseInCart.warehouseID}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        <Typography>Supplier: {warehouseInCart.supplier?.name}</Typography>
        <Typography>
          Warehouse: {warehouseInCart.closestWarehouse?.contact?.name}
        </Typography>
      </Grid>
      {warehouseInCart.products
        .filter(
          (productInCart) =>
            productInCart.supplierProductDetail.product.type !== "Container"
        )
        .map((productInCart) => {
          const exchangeRate = exchangeRates.find(
            (exchangeRate) =>
              exchangeRate.currency ===
              productInCart.supplierProductDetail.supplier.currency
          )?.rate;

          const total = calculateProductPrice(
            findTierPricingCost(productInCart),
            exchangeRate,
            productInCart.supplierProductDetail.supplier._id
          );

          return (
            <Grid
              item
              container
              spacing={2}
              xs={12}
              key={productInCart.supplierProductDetail.product?._id}
            >
              <Grid item xs={12}>
                <Typography>
                  {getProductOptionLabel(
                    productInCart.supplierProductDetail.product
                  )}
                </Typography>
                <Typography>${total.toFixed(2)}/ea.</Typography>
              </Grid>

              <Grid item xs={12} className="vertical-centered-container">
                {!viewOnly && (
                  <Fab
                    aria-label="Remove"
                    size="small"
                    onClick={() => {
                      if (productInCart.quantity <= 0) return;
                      if (productInCart.quantity === 1) {
                        dispatch(
                          removeProductFromCartByWarehouse(productInCart)
                        );
                      }
                      dispatch(reduceProductsInCartByWarehouse(productInCart));
                    }}
                    sx={{ width: 100, mr: 3 }}
                  >
                    <RemoveIcon />
                  </Fab>
                )}
                <DecimalTextField
                  editMode={!viewOnly}
                  value={productInCart.quantity}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    const newQuantity = parseInt(e.target.value);
                    if (newQuantity <= 0)
                      return dispatch(
                        removeProductFromCartByWarehouse(productInCart)
                      );

                    // Clone the current productsInCartByWarehouse to avoid direct state mutations
                    const newProductsInCartByWarehouse = structuredClone(
                      productsInCartByWarehouse
                    );

                    // Find the warehouse index
                    const warehouseIndex =
                      newProductsInCartByWarehouse.findIndex(
                        (warehouse) =>
                          warehouse.warehouseID === warehouseInCart.warehouseID
                      );

                    if (warehouseIndex !== -1) {
                      // Find the product index within the warehouse
                      const productIndex = newProductsInCartByWarehouse[
                        warehouseIndex
                      ].products.findIndex(
                        (product) =>
                          product.supplierProductDetail.product._id ===
                          productInCart.supplierProductDetail.product._id
                      );

                      if (productIndex !== -1) {
                        // Update the product quantity
                        newProductsInCartByWarehouse[warehouseIndex].products[
                          productIndex
                        ].quantity = newQuantity;

                        // If the quantity is 0, remove the product from the cart
                      }
                    }
                    dispatch(
                      setProductsInCartByWarehouse(newProductsInCartByWarehouse)
                    );
                  }}
                  numberProps={{ min: 0 }}
                  sx={{ mr: 3 }}
                  allowMinus={false}
                  allowDecimal={false}
                />

                {!viewOnly && (
                  <Fab
                    aria-label="Add"
                    size="small"
                    onClick={() => {
                      const newProductInCart = {
                        ...productInCart,
                        quantity: productInCart.quantity + 1,
                      };

                      dispatch(
                        addToProductsInCartByWarehouse(newProductInCart)
                      );
                    }}
                    sx={{ width: 100, mr: 3 }}
                  >
                    <AddIcon />
                  </Fab>
                )}
                <Typography
                  sx={{ mr: 3 }}
                  color={
                    productInCart.supplierProductDetail.product.type ===
                      "Container" && "limegreen"
                  }
                >
                  {productInCart.supplierProductDetail.product.type ===
                  "Container"
                    ? "Credits: $"
                    : "$"}
                  {(
                    calculateProductPrice(
                      findTierPricingCost(productInCart),
                      exchangeRate,
                      productInCart.supplierProductDetail.supplier._id
                    ) * productInCart.quantity
                  ).toFixed(2)}
                </Typography>
                {!viewOnly && (
                  <IconButton
                    aria-label="Remove Product"
                    size="small"
                    onClick={() => {
                      dispatch(removeProductFromCartByWarehouse(productInCart));
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Grid>
              {!viewOnly && (
                <ShippingSavings
                  exchangeRates={exchangeRates}
                  productInCart={productInCart}
                />
              )}
            </Grid>
          );
        })}

      {allProductHavePickUp && !viewOnly && (
        <FormSwitch
          label={"Pick Up"}
          checked={warehouseInCart.pickUp}
          onChange={(e) => {
            const tempProductsInCartByWarehouse = structuredClone(
              productsInCartByWarehouse
            );
            const productInCartByWarehouseIndex =
              tempProductsInCartByWarehouse.findIndex(
                (warehouse) =>
                  warehouse.warehouseID === warehouseInCart.warehouseID
              );

            tempProductsInCartByWarehouse[
              productInCartByWarehouseIndex
            ].pickUp = e.target.checked;

            dispatch(
              setProductsInCartByWarehouse(tempProductsInCartByWarehouse)
            );
          }}
          xs={12}
        />
      )}
    </Grid>
  );
};

const ShoppingCartProducts = ({
  exchangeRates,
  productsInCartByWarehouse,
  facility,
  viewOnly,
}) => {
  const allProducts = [];
  if (!productsInCartByWarehouse || productsInCartByWarehouse.length === 0)
    return;

  for (const warehouseInCart of productsInCartByWarehouse) {
    if (
      !warehouseInCart.products.find(
        (product) => product.supplierProductDetail.product.type !== "Container"
      )
    )
      continue;
    const allProductHavePickUp =
      warehouseInCart.products.filter(
        (product) =>
          product.supplierProductDetail.hasPickUp &&
          product.supplierProductDetail.product.type !== "Container"
      ).length ===
      warehouseInCart.products.filter(
        (product) => product.supplierProductDetail.product.type !== "Container"
      ).length
        ? true
        : false;

    allProducts.push(
      <RenderProductsInCartByWarehouse
        allProductHavePickUp={allProductHavePickUp}
        exchangeRates={exchangeRates}
        productsInCartByWarehouse={productsInCartByWarehouse}
        warehouseInCart={warehouseInCart}
        facility={facility}
        key={warehouseInCart.warehouseID}
        viewOnly={viewOnly}
      />
    );
  }

  return allProducts;
};

export default ShoppingCartProducts;
