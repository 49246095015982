import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addMessage } from "../../api/conversationAPI";

const useAddMessage = ({ parentType, parentID, supplierID }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addMessage,
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        "conversation",
        parentType,
        parentID,
        supplierID,
      ]);
    },
  });
};

export default useAddMessage;
