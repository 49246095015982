import axios from "axios";

export const getMaintenanceProcedure = async (maintenanceProcedureID) => {
  return axios
    .get(`/api/maintenance-procedure/get-by-id?id=${maintenanceProcedureID}`)
    .then((res) => res.data.maintenanceProcedure);
};

export const getAllMaintenanceProcedures = async () => {
  return axios
    .get(`/api/maintenance-procedure/get-all`)
    .then((res) => res.data.maintenanceProcedures);
};

export const getMaintenanceProceduresByFacility = async (facilityID) => {
  return axios
    .get(`/api/maintenance-procedure/get-by-facility?facilityID=${facilityID}`)
    .then((res) => res.data.maintenanceProcedures);
};

export const getMaintenanceCalendarEventsBySupplier = async (
  supplierID,
  month,
  year
) => {
  return axios
    .get(
      `/api/maintenance-procedure/get-calendar-events-by-Supplier?supplierID=${supplierID}&month=${month}&year=${year}`
    )
    .then((res) => res.data.maintenanceEvents);
};

export const getMaintenanceProceduresBySystem = async ({ systemID }) => {
  return axios
    .get(`/api/maintenance-procedure/get-by-system?systemID=${systemID}`)
    .then((res) => res.data.maintenanceProcedures);
};

export const getMaintenanceProceduresBySystems = async (body) => {
  return axios
    .post(`/api/maintenance-procedure/get-by-systems`, body)
    .then((res) => res.data.maintenanceProcedures);
};

export const getMaintenanceProceduresByProduct = async (productID) => {
  return axios
    .get(`/api/maintenance-procedure/get-by-product?productID=${productID}`)
    .then((res) => res.data.maintenanceProcedures);
};

export const getMaintenanceCalendarEventsByFacility = async (
  facilityID,
  month,
  year
) => {
  return axios
    .get(
      `/api/maintenance-procedure/get-calendar-events-by-facility?facilityID=${facilityID}&month=${month}&year=${year}`
    )
    .then((res) => res.data.maintenanceEvents);
};

export const getMaintenanceProcedureNamesByFacility = async (facilityID) => {
  return axios
    .get(
      `/api/maintenance-procedure/get-names-by-facility?facilityID=${facilityID}`
    )
    .then((res) => res.data.maintenanceProcedures);
};

export const getProductMaintenanceProcedureNames = async () => {
  return axios
    .get(`/api/maintenance-procedure/get-product-procedure-names`)
    .then((res) => res.data.maintenanceProcedures);
};

export const getStandardMaintenanceProcedureBySystemAndType = async (
  systemID,
  standardType
) => {
  return axios
    .get(
      `/api/maintenance-procedure/get-standard-procedure-by-system-and-type?systemID=${systemID}&standardType=${standardType}`
    )
    .then((res) => res.data.maintenanceProcedure);
};

export const addMaintenanceProcedure = async (facilityID, formData) => {
  return axios.post("/api/maintenance-procedure/add", formData, {
    headers: {
      "X-Facility-ID": facilityID,
    },
  });
};

export const updateMaintenanceProcedure = async (facilityID, formData) => {
  return axios.put("/api/maintenance-procedure/update", formData, {
    headers: {
      "X-Facility-ID": facilityID,
    },
  });
};

export const addProductMaintenanceProcedure = async (formData) => {
  return axios.post(
    "/api/maintenance-procedure/add-product-procedure",
    formData
  );
};

export const updateProductMaintenanceProcedure = async (formData) => {
  return axios.put(
    "/api/maintenance-procedure/update-product-procedure",
    formData
  );
};

export const removeMaintenanceProcedure = async (body) => {
  return axios.post("/api/maintenance-procedure/remove", body);
};

export const removeProductMaintenanceProcedure = async (body) => {
  return axios.post(
    "/api/maintenance-procedure/remove-product-procedure",
    body
  );
};
