import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";

import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import { DatePicker } from "@mui/x-date-pickers";
import FormItems from "./maintenanceProcedureForm/FormItems";
import useProductNamesForMaintenanceProcedures from "../../customHooks/products/useProductNamesForMaintenanceProcedures";
import useUpdateProductMaintenanceProcedure from "../../customHooks/maintenanceProcedure/useUpdateProductMaintenanceProcedure";
import useAddProductMaintenanceProcedure from "../../customHooks/maintenanceProcedure/useAddProductMaintenanceProcedure";
import { CustomModal } from "../sharedComponents";
import useRemoveProductMaintenanceProcedure from "../../customHooks/maintenanceProcedure/useRemoveProductMaintenanceProcedure";
import { getProductOptionLabel } from "../../sharedFunctions/labels";

const StandardMaintenanceProcedureForm = ({
  close,
  editMode,
  maintenanceProcedure,
  product: productProp,
}) => {
  const { data: productNames, isLoading: isLoadingProductNames } =
    useProductNamesForMaintenanceProcedures();

  const [calibrationParameters, setCalibrationParameters] = useState(
    maintenanceProcedure?.calibrationParameters ?? []
  );
  const [date] = useState(moment(maintenanceProcedure?.date));
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [name, setName] = useState(maintenanceProcedure?.name ?? "");
  const [product] = useState(
    maintenanceProcedure?.product ?? productProp ?? ""
  );
  const [error, setError] = useState("");
  const [frequency, setFrequency] = useState(
    maintenanceProcedure?.frequencyUnit === "Yearly"
      ? new Date(maintenanceProcedure?.frequency)
      : maintenanceProcedure?.frequency ?? ""
  );
  const [frequencyUnit, setFrequencyUnit] = useState(
    maintenanceProcedure?.frequencyUnit ?? "Days"
  );
  const [formItems, setFormItems] = useState(
    maintenanceProcedure?.formItems ?? []
  );
  const [linkedSystems, setLinkedSystems] = useState(
    maintenanceProcedure?.linkedSystems ?? []
  );
  const [notificationTime, setNotificationTime] = useState(
    maintenanceProcedure?.notificationTime ?? "7"
  );
  const [saving, setSaving] = useState(false);
  const [valid, setValid] = useState(false);

  const addProductMaintenanceProcedure = useAddProductMaintenanceProcedure();
  const updateProductMaintenanceProcedure =
    useUpdateProductMaintenanceProcedure();
  const deleteProductMaintenanceProcedure =
    useRemoveProductMaintenanceProcedure();

  useEffect(() => {
    if (!name || frequency === "" || !frequencyUnit) return setValid(false);
    if (
      frequency &&
      frequencyUnit === "Days" &&
      parseInt(frequency) < parseInt(notificationTime)
    ) {
      setError("Notification time must be less or equal to the frequency");
      return setValid(false);
    }
    if (
      formItems?.length &&
      formItems.find(
        (formItem) =>
          formItem.inputType === "Image" && !formItem.img && !formItem.imageID
      )
    ) {
      setError("Please choose an image");
      return setValid(false);
    }
    if (
      formItems?.length &&
      formItems.find(
        (formItem) =>
          formItem.inputType === "Image" &&
          formItem.img &&
          formItem.img.size > 50000000
      )
    ) {
      setError("File may not exceed 50MB");
      return setValid(false);
    }
    setError("");
    setValid(true);
  }, [name, frequency, frequencyUnit, formItems, notificationTime]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const onSubmitDelete = async () => {
    await deleteProductMaintenanceProcedure.mutateAsync({
      maintenanceProcedureID: maintenanceProcedure._id,
    });
    setDeleteModalOpen(false);
    close();
  };

  const onSubmit = async () => {
    try {
      setSaving(true);
      const formData = new FormData();

      // Images
      const images = formItems?.filter((item) => item.inputType === "Image");
      const fileIndexes = [];
      if (images?.length) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].img) {
            fileIndexes.push(i);
            formData.append("files[]", images[i].img, images[i].img.name);
          }
        }
      }
      formData.append("fileIndexes", JSON.stringify(fileIndexes));

      // Shallow copy so formItems.img will be an empty object
      let tempFormItems = [...formItems];

      let maintenanceProcedureBody = {
        _id: maintenanceProcedure?._id,
        calibrationParameters,
        date: date.toISOString(),
        name,
        frequency:
          frequencyUnit === "Yearly"
            ? new Date(frequency).getTime()
            : frequency,
        frequencyUnit,
        formItems: tempFormItems,
        linkedSystems,
        notificationTime: notificationTime ?? 0,
        product: product?._id,
      };

      formData.append(
        "maintenanceProcedure",
        JSON.stringify(maintenanceProcedureBody)
      );

      if (maintenanceProcedure) {
        let linkedSystemsRemoved = maintenanceProcedure?.linkedSystems?.filter(
          (procedure) => !linkedSystems.includes(procedure)
        );
        linkedSystemsRemoved = linkedSystemsRemoved?.map(
          (procedure) => procedure._id
        );

        if (linkedSystemsRemoved?.length) {
          formData.append(
            "linkedSystemsRemoved",
            JSON.stringify(linkedSystemsRemoved)
          );
        }

        await updateProductMaintenanceProcedure.mutateAsync(formData);
      } else {
        await addProductMaintenanceProcedure.mutateAsync(formData);
      }

      setSaving(false);
      close();
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <CustomModal
        open={deleteModalOpen}
        close={closeDeleteModal}
        title={"Please Confirm That You Want To Delete This Procedure"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => onSubmitDelete()}
              color="error"
              fullWidth
            >
              Delete Procedure
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            required
            label="Name"
            value={name}
            variant="outlined"
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
        {product && (
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              required
              label="Product"
              value={getProductOptionLabel(product)}
              variant="outlined"
              disabled={true}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="frequencyUnitLabel" required>
              Frequency
            </InputLabel>
            <Select
              required
              value={frequencyUnit}
              labelId="frequencyUnitLabel"
              label="Frequency"
              onChange={(e) => {
                setFrequencyUnit(e.target.value);

                if (e.target.value === "Yearly") setFrequency(moment());
                else setFrequency("");
              }}
              disabled={!editMode}
            >
              <MenuItem value={"Days"}>Days</MenuItem>
              <MenuItem value={"Weekly"}>Weekly</MenuItem>
              <MenuItem value={"Monthly"}>Monthly</MenuItem>
              <MenuItem value={"Yearly"}>Yearly</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {frequencyUnit === "Days" && (
          <Grid item xs={12} md={6}>
            <DecimalTextField
              editMode={editMode}
              value={frequency}
              onChange={(e) => {
                setFrequency(e.target.value);
              }}
              label={"Frequency (Days)"}
              allowMinus={false}
              allowDecimal={false}
            />
          </Grid>
        )}
        {frequencyUnit === "Weekly" && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="frequencyLabel" required>
                Frequency (Weekly)
              </InputLabel>
              <Select
                required
                value={frequency}
                labelId="frequencyLabel"
                label="Frequency (Weekly)"
                onChange={(e) => {
                  setFrequency(e.target.value);
                }}
                disabled={!editMode}
              >
                <MenuItem value="1">Monday</MenuItem>
                <MenuItem value="2">Tuesday</MenuItem>
                <MenuItem value="3">Wednesday</MenuItem>
                <MenuItem value="4">Thursday</MenuItem>
                <MenuItem value="5">Friday</MenuItem>
                <MenuItem value="6">Saturday</MenuItem>
                <MenuItem value="0">Sunday</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {frequencyUnit === "Monthly" && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="frequencyLabel" required>
                Frequency (Monthly)
              </InputLabel>
              <Select
                required
                value={frequency}
                labelId="frequencyLabel"
                label="Frequency (Monthly)"
                onChange={(e) => {
                  setFrequency(e.target.value);
                }}
                disabled={!editMode}
              >
                {Array.from({ length: 31 }, (_, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {frequencyUnit === "Yearly" && (
          <Grid item xs={12} md={6}>
            <DatePicker
              disabled={!editMode}
              label={"Frequency (Yearly)"}
              onChange={(value) => {
                setFrequency(value);
              }}
              value={moment(frequency)}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <DecimalTextField
            editMode={editMode}
            value={notificationTime}
            onChange={(e) => {
              setNotificationTime(e.target.value);
            }}
            label={"Action warning time (days)"}
            required={false}
            allowMinus={false}
            allowDecimal={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            loading={isLoadingProductNames}
            options={
              productNames?.map((product) => ({
                product: { _id: product._id, name: product.name },
              })) ?? []
            }
            getOptionLabel={(o) => {
              return o?.product?.name ?? "";
            }}
            value={linkedSystems}
            isOptionEqualToValue={(option, value) => {
              if (!value) return [];
              return option?.product?._id === value?.product?._id;
            }}
            onChange={(e, value) => {
              setLinkedSystems(value);
            }}
            disabled={!editMode}
            disableCloseOnSelect
            style={{ display: "flex" }}
            renderOption={(props, option, { selected }) => (
              <ListItem
                {...props}
                style={{ opacity: selected ? 0.5 : 1 }}
                disabled={selected}
              >
                <ListItemText primary={option.product.name} />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                {...params}
                label={`Linked Products`}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="calibration-parameters-label">
              Calibration Parameters
            </InputLabel>
            <Select
              labelId="calibration-parameters-label"
              id="calibration-parameters-select"
              multiple
              value={calibrationParameters}
              label="Calibration Parameters"
              disabled={!editMode}
              onChange={(e) => setCalibrationParameters(e.target.value)}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Calibration Parameters"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              <MenuItem value={"pH"}>pH</MenuItem>
              <MenuItem value={"Free Chlorine"}>Free Chlorine</MenuItem>
              <MenuItem value={"Total Chlorine"}>Total Chlorine</MenuItem>
              <MenuItem value={"Turbidity"}>Turbidity</MenuItem>
              <MenuItem value={"Conductivity"}>Conductivity</MenuItem>
              <MenuItem value={"Ammonia"}>Ammonia</MenuItem>
              <MenuItem value={"Nitrogen"}>Nitrogen</MenuItem>
              <MenuItem value={"Iron"}>Iron</MenuItem>
              <MenuItem value={"Manganese"}>Manganese</MenuItem>
              <MenuItem value={"Arsenic"}>Arsenic</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {editMode && maintenanceProcedure && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => setDeleteModalOpen(true)}
              disabled={saving}
            >
              Delete Procedure
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormItems
            editMode={editMode}
            formItems={formItems}
            setFormItems={setFormItems}
            isProduct={!!product}
            product={product}
          />
        </Grid>
        {editMode && error && (
          <Grid item xs={12}>
            <Typography textAlign="center" color="error">
              {error}
            </Typography>
          </Grid>
        )}
        {editMode && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={onSubmit}
              fullWidth
              disabled={!valid || saving}
            >
              {maintenanceProcedure
                ? saving
                  ? "Updating Procedure"
                  : "Update Procedure"
                : saving
                ? "Creating Procedure"
                : "Create Procedure"}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StandardMaintenanceProcedureForm;
