import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { CustomModal } from "../sharedComponents";
import { DatePicker } from "@mui/x-date-pickers";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import { useSelector } from "react-redux";
import RequestServiceDateChangeForm from "./RequestServiceDateChangeForm";

const ServiceDatesSection = ({
  close,
  serviceDatesPassedIn,
  serviceContractID,
  serviceRequestID,
  startOpen,
  submitFunction,
  getNextAutomatedServiceDate,
  serviceRepresentatives,
}) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const [errorMessage, setErrorMessage] = useState("");
  const [requestServiceDateChangeOpen, setRequestServiceDateChangeOpen] =
    useState(false);
  const [serviceDates, setServiceDates] = useState(
    serviceDatesPassedIn.map((serviceDate) => ({
      ...serviceDate,
      serviceDate: serviceDate.serviceDate
        ? moment(serviceDate.serviceDate)
        : null,
      serviceStartDate: serviceDate.serviceStartDate
        ? moment(serviceDate.serviceStartDate)
        : null,
      serviceEndDate: serviceDate.serviceEndDate
        ? moment(serviceDate.serviceEndDate)
        : null,
    })) ?? []
  );

  const [serviceDateChanged, setServiceDateChanged] = useState(null);
  const [open, setOpen] = useState(startOpen ?? false);
  const [valid, setValid] = useState(false);

  const closeRequestServiceDateChange = (e, reason, confirmed) => {
    if (reason === "backdropClick") return;
    setRequestServiceDateChangeOpen(false);
    setServiceDateChanged(null);
    if (confirmed) close();
  };

  useEffect(() => {
    if (!serviceDates?.length) {
      setValid(false);
      return;
    }
    for (let i = 0; i < serviceDates.length; i++) {
      if (serviceDates[i].isRange) {
        if (
          !serviceDates[i].serviceStartDate ||
          !serviceDates[i].serviceEndDate
        ) {
          setValid(false);
          return;
        }
        if (serviceDates[i].serviceStartDate > serviceDates[i].serviceEndDate) {
          setValid(false);
          setErrorMessage("End date must be after start date");
          return;
        }
      } else {
        if (!serviceDates[i].serviceDate) {
          setValid(false);
          return;
        }
      }
    }
    setErrorMessage("");
    setValid(true);
  }, [serviceDates]);

  //complex validation
  // useEffect(() => {
  //   if (!serviceDates?.length) return;
  //   for (let j = 1; j < serviceDates.length; j++) {
  //     const currentServiceDate = moment(serviceDates[j].serviceDate);
  //     const previousServiceDate = moment(serviceDates[j - 1].serviceDate);

  //     if (currentServiceDate.isBefore(previousServiceDate)) {
  //       setError(true);
  //       setErrorIndex(j);
  //       setMessage("Service dates are not in order.");
  //       return;
  //     }
  //   }

  //   const uniqueDatesSet = new Set(
  //     serviceDates.map((sd) => moment(sd.serviceDate).format("YYYY-MM-DD"))
  //   );

  //   if (uniqueDatesSet.size < serviceDates.length) {
  //     setError(true);
  //     setErrorIndex(serviceDates?.length - 1);
  //     setMessage("Duplicate service date found.");
  //     return;
  //   }

  //   let serviceDaysRemaining = 0;
  //   let lastYearIndex = -1;

  //   for (let i = 0; i < serviceDates.length; i++) {
  //     //if this service date exists in the previous service dates skip
  //     if (
  //       serviceContractServiceDates.some(
  //         (sd) =>
  //           moment(sd.serviceDate).format("YYYY-MM-DD") ===
  //           moment(serviceDates[i].serviceDate).format("YYYY-MM-DD")
  //       )
  //     ) {
  //       continue;
  //     }

  //     const serviceDateMoment = moment(serviceDates[i].serviceDate);
  //     const yearIndex = serviceDateMoment.diff(moment(serviceStart), "year");

  //     if (
  //       unavailableServiceDates.includes(serviceDateMoment.format("YYYY-MM-DD"))
  //     ) {
  //       setError(true);
  //       setErrorIndex(i);
  //       setMessage("Service date is already booked.");
  //       return;
  //     }

  //     if (
  //       serviceDateMoment.isBefore(serviceStart, "day") ||
  //       yearIndex >= services.length
  //     ) {
  //       setError(true);
  //       setErrorIndex(i);
  //       setMessage("Service date is out of contract period.");
  //       return;
  //     }

  //     const onSiteService = services[yearIndex]?.find(
  //       (service) => service.service?.serviceMode === "On Site"
  //     );

  //     if (lastYearIndex !== yearIndex) {
  //       serviceDaysRemaining += onSiteService?.quantity ?? 0;
  //       lastYearIndex = yearIndex;
  //     }

  //     if (!onSiteService || serviceDaysRemaining <= 0) {
  //       const futureOnSiteService = services
  //         .slice(yearIndex + 1)
  //         .find((yearServices) =>
  //           yearServices.some(
  //             (service) => service.service?.serviceMode === "On Site"
  //           )
  //         );

  //       if (futureOnSiteService) {
  //         serviceDaysRemaining--;
  //         continue; // Future availability found, skip without error
  //       } else {
  //         setError(true);
  //         setErrorIndex(i);
  //         setMessage(
  //           "No on-site service available for this year and no future availability."
  //         );
  //         return;
  //       }
  //     }

  //     if (serviceDaysRemaining <= 0) {
  //       setError(true);
  //       setErrorIndex(i);
  //       setMessage("All on-site services for this year have been used.");
  //       return;
  //     }

  //     serviceDaysRemaining--;
  //   }

  //   setError(false);
  //   setMessage("");
  // }, [
  //   serviceDates,
  //   serviceContractServiceDates,
  //   unavailableServiceDates,
  //   serviceStart,
  //   services,
  // ]);

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setOpen(false);
    if (startOpen) close();
  };

  const onSubmit = async () => {
    submitFunction(serviceDates);
    closeModal();
  };

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Button
          fullWidth
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
        >
          Manage Service Dates
        </Button>
      </Grid>
      {serviceDates.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Service Dates:</Typography>
          </Grid>
          {serviceDates.map((serviceDate, i) => (
            <Grid
              item
              container
              spacing={2}
              xs={12}
              key={"serviceDate" + i}
              className="vertical-centered-container"
            >
              {serviceDate.isRange ? (
                <>
                  <Grid item xs={4}>
                    <Typography>
                      {`${moment(serviceDate.serviceStartDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(serviceDate.serviceEndDate).format(
                        "MM/DD/YYYY"
                      )}`}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid item xs={4}>
                  <Typography>
                    {`${moment(serviceDate.serviceDate).format("MM/DD/YYYY")}`}
                  </Typography>
                </Grid>
              )}
              {serviceDate.serviceRepresentatives?.length > 0 && (
                <Grid item xs={6}>
                  {serviceDate.serviceRepresentatives.map((sr) => (
                    <Typography key={sr._id}>{sr.name}</Typography>
                  ))}
                </Grid>
              )}
              <Grid item xs={2} display={"flex"}>
                {(appUserLevel.includes("Facility") ||
                  appUserLevel.includes("Super User")) &&
                  (serviceDate.isRange
                    ? moment(serviceDate.serviceEndDate).isAfter(new Date())
                    : moment(serviceDate.serviceDate).isAfter(new Date())) && (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        setServiceDateChanged(serviceDate);
                        setRequestServiceDateChangeOpen(true);
                      }}
                    >
                      Request Date Change
                    </Button>
                  )}
              </Grid>
            </Grid>
          ))}
        </>
      )}
      <CustomModal
        open={requestServiceDateChangeOpen}
        close={closeRequestServiceDateChange}
        title={"Request Service Date Change"}
      >
        <RequestServiceDateChangeForm
          close={closeRequestServiceDateChange}
          serviceRequestID={serviceRequestID}
          serviceContractID={serviceContractID}
          previousServiceDate={serviceDateChanged}
        />
      </CustomModal>
      <CustomModal open={open} close={closeModal} title="Service Dates">
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                setServiceDates([
                  ...serviceDates,
                  {
                    notes: "",
                    serviceDate: getNextAutomatedServiceDate
                      ? getNextAutomatedServiceDate(
                          serviceDates[serviceDates.length - 1]?.serviceDate
                        )
                      : null,
                    status: "Pending",
                    serviceRepresentatives: [],
                  },
                ]);
              }}
              variant="contained"
            >
              Add Service Date
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                setServiceDates([
                  ...serviceDates,
                  {
                    isRange: true,
                    notes: "",
                    serviceEndDate: null,
                    serviceStartDate: null,
                    status: "Pending",
                    serviceRepresentatives: [],
                  },
                ]);
              }}
              variant="contained"
            >
              Add Service Date Range
            </Button>
          </Grid>

          {serviceDates.map((serviceDate, index) => (
            <Grid
              container
              spacing={2}
              item
              xs={12}
              className="vertical-centered-container"
              key={"Service Date" + index}
            >
              {serviceDate.isRange ? (
                <>
                  <Grid item xs={6} md={3}>
                    <DatePicker
                      label="Start Date *"
                      value={serviceDate.serviceStartDate}
                      onChange={(value) => {
                        const newServiceDates = [...serviceDates];
                        newServiceDates[index].serviceStartDate = value;
                        setServiceDates(newServiceDates);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <DatePicker
                      label="End Date *"
                      value={serviceDate.serviceEndDate}
                      onChange={(value) => {
                        const newServiceDates = [...serviceDates];
                        newServiceDates[index].serviceEndDate = value;
                        setServiceDates(newServiceDates);
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={4}>
                  <DatePicker
                    label="Service Date *"
                    value={serviceDate.serviceDate}
                    onChange={(value) => {
                      const newServiceDates = [...serviceDates];
                      newServiceDates[index].serviceDate = value;
                      setServiceDates(newServiceDates);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={serviceDate.isRange ? 6 : 8}>
                <Autocomplete
                  multiple
                  options={serviceRepresentatives}
                  getOptionLabel={(option) => option.name}
                  value={serviceDate.serviceRepresentatives}
                  onChange={(e, value) => {
                    const newServiceDates = [...serviceDates];
                    newServiceDates[index].serviceRepresentatives = value;
                    setServiceDates(newServiceDates);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service Representatives"
                      placeholder="Service Representatives"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={10}>
                <TextField
                  label="Notes"
                  value={serviceDate.notes}
                  onChange={(event) => {
                    const newServiceDates = [...serviceDates];
                    newServiceDates[index].notes = event.target.value;
                    setServiceDates(newServiceDates);
                  }}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    const newServiceDates = [...serviceDates];
                    newServiceDates.splice(index, 1);
                    setServiceDates(newServiceDates);
                  }}
                  color="error"
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
          {errorMessage && (
            <Grid item xs={12} className="centered-container">
              <Typography color={"error"}>{errorMessage}</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              fullWidth
              onClick={onSubmit}
              variant="contained"
              disabled={!valid}
            >
              Update Service Dates
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export default ServiceDatesSection;
