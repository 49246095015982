import { useQuery } from "@tanstack/react-query";
import { getConversation } from "../../api/conversationAPI";

const useConversation = ({ parentType, parentID, supplierID }) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["conversation", parentType, parentID, supplierID],
    queryFn: () => getConversation({ parentType, parentID, supplierID }),
    enabled: !!parentID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useConversation;
