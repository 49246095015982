import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addSupplierCompany } from "../../api/supplierCompaniesAPI";

const useAddSupplierCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addSupplierCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["supplierCompanies"] });
    },
  });
};

export default useAddSupplierCompany;
