import { useQuery } from "@tanstack/react-query";
import { contactGetProfilePicture } from "../../api/contactAPI";

const useContactProfilePicture = (contactID) => {
  const {
    data: profilePicture,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["contactProfilePicture", contactID],
    queryFn: () => contactGetProfilePicture(contactID),
    enabled: !!contactID,
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  });

  return {
    profilePicture,
    isLoading,
    isError,
  };
};

export default useContactProfilePicture;
