import React, { useState } from "react";
import useSupplierPallets from "../../customHooks/supplierProductDetails/useSupplierPallets";
import useFacilityRestrictedShippers from "../../customHooks/facility/useFacilityRestrictedShippers";
import useShipperCompanies from "../../customHooks/shipperCompany/useShipperCompanies";
import { getProductsInCartByWarehouse } from "./helperFunctions/getProductsInCartByWarehouse";
import useContact from "../../customHooks/contacts/useContact";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCart from "./ShoppingCart";
import { useSelector } from "react-redux";
import {
  selectCartSubmissions,
  selectOrderFacility,
  selectProductsInCartByWarehouse,
} from "../../redux/reducers/productsSlice";
import { Badge, Box, IconButton, Tooltip } from "@mui/material";

const ShoppingCartButton = () => {
  const productsInCartByWarehouse = useSelector(
    selectProductsInCartByWarehouse
  );
  const productsInCart = getProductsInCartByWarehouse(
    productsInCartByWarehouse
  );
  const { contact } = useContact();
  const cartSubmissions = useSelector(selectCartSubmissions);
  const orderFacility = useSelector(selectOrderFacility);

  useFacilityRestrictedShippers(orderFacility);
  useShipperCompanies();
  useSupplierPallets({
    enabled: !!(
      productsInCartByWarehouse?.length &&
      productsInCartByWarehouse.some(
        (productInCartByWarehouse) => productInCartByWarehouse.freightShipment
      )
    ),
  });

  const [shoppingCartOpen, setShoppingCartOpen] = useState(false);

  const handleToggleShoppingCart = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShoppingCartOpen(!shoppingCartOpen);
  };

  return (
    <>
      <Box>
        <Tooltip title="Open Shopping Cart">
          <Badge
            badgeContent={
              productsInCart.filter((product) =>
                product.supplierProductDetail.product.containerType ===
                  "Pallet" && !product.isReturn
                  ? false
                  : true
              ).length
            }
            color="error"
            sx={{ top: 8, right: 5 }}
          >
            <IconButton
              onClick={handleToggleShoppingCart}
              aria-label="delete"
              sx={{ color: "white", top: -8 }}
              size="large"
            >
              <ShoppingCartIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
      {shoppingCartOpen && (
        <ShoppingCart
          cartSubmissions={cartSubmissions}
          contact={contact}
          handleToggleShoppingCart={handleToggleShoppingCart}
          productsInCartByWarehouse={productsInCartByWarehouse}
          shoppingCartOpen={shoppingCartOpen}
        />
      )}
    </>
  );
};

export default ShoppingCartButton;
