import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DecimalTextField from "../../sharedComponents/TextFields/DecimalTextField";
import useCreditMemoApplyCredit from "../../../customHooks/creditMemo/useCreditMemoApplyCredit";
import useVendorCreditApplyCredit from "../../../customHooks/vendorCredit/useVendorCreditApplyCredit";
import useOrderNumbers from "../../../customHooks/order/useOrderNumbers";

const ApplyCreditsForm = ({ close, document }) => {
  const [amount, setAmount] = useState(document?.balance ?? 0);
  const [documentToApply, setDocumentToApply] = useState(null);
  const [valid, setValid] = useState(false);
  const [saving, setSaving] = useState(false);

  const { data: orders, isLoading: isLoadingOrders } = useOrderNumbers({
    outstandingOrders: true,
    facilityID: document?.customer?._id,
    supplierID: document?.vendor?._id,
  });

  const creditMemoApplyCredit = useCreditMemoApplyCredit();
  const vendorCreditApplyCredit = useVendorCreditApplyCredit();

  useEffect(() => {
    if (!documentToApply || amount <= 0 || amount > document?.balance)
      return setValid(false);
    setValid(true);
  }, [documentToApply, amount, document]);

  const onSubmit = async () => {
    setSaving(true);
    if (document?.type === "CreditMemo") {
      const body = {
        creditMemoID: document._id,
        order: documentToApply,
        amount: amount,
      };
      await creditMemoApplyCredit.mutateAsync(body);
    } else if (document?.type === "VendorCredit") {
      const body = {
        vendorCreditID: document._id,
        order: documentToApply,
        amount: amount,
      };
      await vendorCreditApplyCredit.mutateAsync(body);
    }
    setSaving(false);
    close();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>Balance ${document.balance?.toFixed(2)}</Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <Autocomplete
          loading={isLoadingOrders}
          getOptionLabel={(option) => option?.orderNumber.toString() || ""}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          options={orders || []}
          value={documentToApply}
          onChange={(e, value) => setDocumentToApply(value)}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} required={true} label={"Order Number"} />
          )}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <DecimalTextField
          editMode={true}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          label="Amount"
          required={true}
          allowMinus={false}
          allowDecimal={true}
          inputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={!valid || saving}
          onClick={onSubmit}
          variant="contained"
          fullWidth={true}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default ApplyCreditsForm;
