import { useQuery } from "@tanstack/react-query";
import { getOrderNumbers } from "../../api/orderAPI";

const useOrderNumbers = ({ facilityID, outstandingOrders, supplierID }) => {
  const { data, error, isError, isLoading, isRefetching } = useQuery({
    queryKey: [
      "orders",
      "orderNumbers",
      outstandingOrders,
      facilityID,
      supplierID,
    ],
    queryFn: () =>
      getOrderNumbers({
        facilityID,
        outstandingOrders,
        supplierID,
      }),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  return {
    error,
    data,
    isError,
    isLoading,
    isRefetching,
  };
};

export default useOrderNumbers;
