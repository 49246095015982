import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
  Box,
} from "@mui/material";
import { selectUser } from "../../redux/reducers/userSlice";
import useConversation from "../../customHooks/conversations.js/useConversation";
import useAddMessage from "../../customHooks/conversations.js/useAddMessage";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FileList from "../sharedComponents/FileList";

const Conversation = ({
  parentType,
  parentID,
  participants,
  supplierID,
  title,
}) => {
  const user = useSelector(selectUser);
  const { data: conversation } = useConversation({
    parentType,
    parentID,
    supplierID,
  });
  const [attachments, setAttachments] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [message, setMessage] = useState("");

  const addMessage = useAddMessage({ parentType, parentID, supplierID });

  const handleSendMessage = async () => {
    if (!message) return;

    const formData = new FormData();
    formData.append("sender", user._id);
    formData.append("message", message);
    formData.append("title", title);
    if (supplierID) formData.append("supplierID", supplierID);

    if (participants?.length)
      participants.forEach((participant) => {
        formData.append("participants[]", participant._id);
      });
    if (attachments.length)
      attachments.forEach((attachment) => {
        formData.append("files[]", attachment);
      });

    await addMessage.mutateAsync({
      parentType,
      parentID,
      formData,
    });

    setMessage("");
    setAttachments([]);
  };

  const displayedMessages = expanded
    ? conversation?.messages || []
    : conversation?.messages?.slice(-3) || [];

  return (
    <Grid item xs={12} container spacing={2}>
      {expanded ? (
        <Grid item xs={12}>
          <Button onClick={() => setExpanded(false)}>Show less</Button>
        </Grid>
      ) : (
        conversation?.messages?.length > 3 && (
          <Grid item xs={12}>
            <Button
              onClick={() => {
                setExpanded(true);
              }}
            >
              Show More
            </Button>
          </Grid>
        )
      )}

      {displayedMessages.map((msg) => (
        <Grid container item xs={12} key={msg._id}>
          <Grid item>
            <Avatar
              alt={msg.sender.name}
              src={
                conversation.participants.find((p) => p._id === msg.sender._id)
                  ?.profilePicture
              }
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{ ml: "10px", mt: "5px", wordBreak: "break-word" }}
            >
              <b>{msg.sender.name}</b> -{" "}
              <span style={{ fontSize: "12px" }}>
                {moment(msg.dateCreated).fromNow()}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ ml: "50px", wordBreak: "break-word" }}
            >
              {msg.message}
            </Typography>
          </Grid>
          {msg.attachments?.length > 0 && (
            <Grid item xs={12} container spacing={2}>
              <FileList files={msg.attachments} />
            </Grid>
          )}
        </Grid>
      ))}
      <Grid
        container
        item
        xs={12}
        spacing={1}
        style={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
        }}
      >
        <Grid item>
          <Avatar
            alt={user.firstName}
            src={user.profilePicture}
            sx={{ mt: "10px" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            label="Message"
            value={message}
            variant="outlined"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item className="vertical-centered-container">
          <Button
            fullWidth
            sx={{ mt: "4px", display: "flex" }}
            component="label"
            startIcon={<AttachFileIcon fontSize="large" />}
            size="20px"
          >
            <input
              type="file"
              accept=".jpeg, .png, .jpg, .gif, .svg , .pdf"
              hidden
              multiple
              onChange={(e) => {
                setAttachments([...attachments, ...e.target.files]);
              }}
            />
          </Button>
          <Button
            fullWidth
            sx={{ mt: "4px", display: "flex" }}
            onClick={handleSendMessage}
            size="20px"
            disabled={!message}
          >
            <SendIcon fontSize="large" />
          </Button>
        </Grid>
      </Grid>
      {attachments.length > 0 && (
        <Grid item xs={12} display={"flex"} flexWrap="wrap">
          {attachments.map((file, i) => (
            <Box
              key={"attachment" + i}
              sx={{ ml: 1 }}
              className="vertical-centered-container"
            >
              <Typography key={i}>{file.name}</Typography>
              <IconButton
                onClick={() => {
                  setAttachments(attachments.filter((a) => a !== file));
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default Conversation;
