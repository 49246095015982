import { useQuery } from "@tanstack/react-query";
import { contactGetCustomerVendorNames } from "../../api/contactAPI";

const useContactCustomerVendorNames = () => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["contactCustomerVendorNames"],
    queryFn: contactGetCustomerVendorNames,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useContactCustomerVendorNames;
