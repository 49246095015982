import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import CustomDatePicker from "../sharedComponents/datePicker/CustomDatePicker";
import useRequestServiceContractServiceDateChange from "../../customHooks/serviceContractOrder/useRequestServiceContractServiceDateChange";
import useRequestServiceOrderServiceDateChange from "../../customHooks/serviceOrder/useRequestServiceOrderServiceDateChange";
import moment from "moment";

const RequestServiceDateChangeForm = ({
  close,
  previousServiceDate,
  serviceContractID,
  serviceRequestID,
}) => {
  const [newServiceDate, setNewServiceDate] = useState(
    moment(previousServiceDate.serviceDate)
  );
  const [newServiceEndDate, setNewServiceEndDate] = useState(
    moment(previousServiceDate.serviceEndDate)
  );
  const [newServiceStartDate, setNewServiceStartDate] = useState(
    moment(previousServiceDate.serviceStartDate)
  );
  const [reason, setReason] = useState("");
  const [saving, setSaving] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const requestServiceOrderServiceDateChange =
    useRequestServiceOrderServiceDateChange();
  const requestServiceContractServiceDateChange =
    useRequestServiceContractServiceDateChange();

  useEffect(() => {
    if (
      previousServiceDate?.isRange &&
      newServiceStartDate > newServiceEndDate
    ) {
      setErrorMessage(
        "New service start date must be before new service end date."
      );
    } else {
      setErrorMessage("");
    }
  }, [newServiceStartDate, newServiceEndDate, previousServiceDate]);

  const onSubmit = async () => {
    setSaving(true);

    if (serviceRequestID) {
      await requestServiceOrderServiceDateChange.mutateAsync({
        serviceOrderID: serviceRequestID,
        newServiceDate,
        newServiceEndDate,
        newServiceStartDate,
        previousServiceDate,
        reason,
      });
    } else if (serviceContractID) {
      await requestServiceContractServiceDateChange.mutateAsync({
        serviceContractID,
        newServiceDate,
        newServiceEndDate,
        newServiceStartDate,
        previousServiceDate,
        reason,
      });
    }

    setSaving(false);
    close(null, null, true);
  };

  return (
    <Grid container spacing={2}>
      {saving ? (
        <Grid item xs={12}>
          <Typography>
            Your service provider has received the request to change this
            service date.
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography>
              To reschedule your service, please fill in the details below:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Reason for Rescheduling"
              variant="outlined"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>
          {previousServiceDate?.isRange ? (
            <>
              <Grid item xs={6} md={4}>
                <CustomDatePicker
                  label="New Service Start Date"
                  value={newServiceStartDate}
                  onChange={(date) => setNewServiceStartDate(date)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomDatePicker
                  label="New Service End Date"
                  value={newServiceEndDate}
                  onChange={(date) => setNewServiceEndDate(date)}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <CustomDatePicker
                label="New Service Date"
                value={newServiceDate}
                onChange={(date) => setNewServiceDate(date)}
              />
            </Grid>
          )}
          {errorMessage && (
            <Grid item xs={12}>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              fullWidth
              disabled={
                !reason ||
                saving ||
                (previousServiceDate?.isRange &&
                  (!newServiceStartDate || !newServiceEndDate)) ||
                (!previousServiceDate?.isRange && !newServiceDate) ||
                errorMessage
              }
            >
              Request Service Date Change
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RequestServiceDateChangeForm;
