import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getFacilitiesNames } from "../../api/facilityAPI";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";

const useFacilitiesNames = ({ disable }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const {
    data: facilities = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["facilitiesNames"],
    queryFn: () => getFacilitiesNames(appUserLevel),
    enabled: !disable,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  return {
    error,
    facilities,
    isError,
    isLoading,
  };
};

export default useFacilitiesNames;
