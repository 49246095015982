import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import AddDocumentForm from "./AddDocumentForm";
import { CustomModal } from "../../sharedComponents";

const AccountingDocuments = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleOpenModal = (type) => {
    setModalType(type);
    setOpenModal(true);
  };

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setOpenModal(false);
    setModalType("");
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: "5px" }}>
        <Grid container item spacing={2} xs={12} className="centered-container">
          <Grid item xs={12} md={2}>
            <Button
              onClick={() => handleOpenModal("Create Document")}
              variant="contained"
            >
              Create Document
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <CustomModal close={handleCloseModal} open={openModal} title={modalType}>
        <AddDocumentForm />
      </CustomModal>
    </>
  );
};

export default AccountingDocuments;
