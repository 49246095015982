import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
import { updateProductsInCartWithFreightDetails } from "../../sharedFunctions/palletBuildingFunctions/palletBuildingFunctions";

const preloadedState = loadState("products");

export const products = createSlice({
  name: "products",
  initialState: {
    cartSubmission: preloadedState?.cartSubmission ?? null,
    cartSubmissions: preloadedState?.cartSubmissions ?? [],
    productsInCartByWarehouse: preloadedState?.productsInCartByWarehouse ?? [],
    orderFacility: preloadedState?.orderFacility ?? null,
    orderFacilityAddress: preloadedState?.orderFacilityAddress ?? null,
    supplierPallets: [],
    shipperCompanies: [],
    restrictedShippers: [],
    loading: false,
  },
  reducers: {
    resetProducts: (state) => {
      state.cartSubmission = null;
      state.cartSubmissions = [];
      state.productsInCartByWarehouse = [];
      state.orderFacility = null;
      state.orderFacilityAddress = null;
      state.supplierPallets = [];
      state.shipperCompanies = [];
      state.restrictedShippers = [];
    },
    setProductsInCartByWarehouse: (state, action) => {
      const productsInCartByWarehouse = action.payload;

      state.productsInCartByWarehouse = updateProductsInCartWithFreightDetails(
        productsInCartByWarehouse,
        state.supplierPallets,
        state.shipperCompanies,
        state.restrictedShippers,
        state.orderFacilityAddress
      );
    },
    addToProductsInCartByWarehouse: (state, action) => {
      if (!state.productsInCartByWarehouse) {
        state.productsInCartByWarehouse = [];
      }

      const productInCart = action.payload;
      const { closestWarehouse } = productInCart;
      const warehouseID = closestWarehouse._id;

      let warehouseIndex = state.productsInCartByWarehouse.findIndex(
        (entry) => entry.warehouseID === warehouseID
      );

      if (warehouseIndex === -1) {
        warehouseIndex = state.productsInCartByWarehouse.length;
        state.productsInCartByWarehouse.push({
          warehouseID: warehouseID,
          closestWarehouse: closestWarehouse,
          products: [],
          pickUp: false,
          shippingIncluded: false, //not used yet
          supplier: productInCart.supplierProductDetail.supplier,
        });
      }

      const productIndex = state.productsInCartByWarehouse[
        warehouseIndex
      ].products.findIndex((product) => {
        if (product.supplierProductDetail.product?.type === "Container")
          return (
            product.supplierProductDetail.product._id ===
              productInCart.supplierProductDetail.product._id &&
            product.supplierProductDetail.supplier._id ===
              productInCart.supplierProductDetail.supplier._id &&
            product.chemical?._id === productInCart.chemical?._id
          );
        else
          return (
            product.supplierProductDetail.product._id ===
              productInCart.supplierProductDetail.product._id &&
            product.supplierProductDetail.supplier._id ===
              productInCart.supplierProductDetail.supplier._id
          );
      });

      if (productIndex === -1) {
        state.productsInCartByWarehouse[warehouseIndex].products.push(
          productInCart
        );
      } else {
        state.productsInCartByWarehouse[warehouseIndex].products[
          productIndex
        ].quantity = productInCart.quantity;
      }

      state.productsInCartByWarehouse = updateProductsInCartWithFreightDetails(
        state.productsInCartByWarehouse,
        state.supplierPallets,
        state.shipperCompanies,
        state.restrictedShippers,
        state.orderFacilityAddress
      );
    },
    reduceProductsInCartByWarehouse: (state, action) => {
      const productInCart = action.payload;

      const warehouseIndex = state.productsInCartByWarehouse.findIndex(
        (entry) => entry.warehouseID === productInCart.closestWarehouse._id
      );

      if (warehouseIndex !== -1) {
        const productIndex = state.productsInCartByWarehouse[
          warehouseIndex
        ].products.findIndex(
          (product) =>
            product.supplierProductDetail.product._id ===
            productInCart.supplierProductDetail.product._id
        );

        if (productIndex !== -1) {
          state.productsInCartByWarehouse[warehouseIndex].products[
            productIndex
          ].quantity -= 1;
        }
      }

      state.productsInCartByWarehouse = updateProductsInCartWithFreightDetails(
        state.productsInCartByWarehouse,
        state.supplierPallets,
        state.shipperCompanies,
        state.restrictedShippers,
        state.orderFacilityAddress
      );
    },
    removeProductFromCartByWarehouse: (state, action) => {
      const productInCart = action.payload;

      if (!state.productsInCartByWarehouse) {
        state.productsInCartByWarehouse = [];
      }

      const warehouseIndex = state.productsInCartByWarehouse.findIndex(
        (entry) => entry.warehouseID === productInCart.closestWarehouse._id
      );

      if (warehouseIndex !== -1) {
        const filteredProducts = state.productsInCartByWarehouse[
          warehouseIndex
        ].products.filter(
          (product) =>
            product.supplierProductDetail.product._id !==
            productInCart.supplierProductDetail.product._id
        );

        if (filteredProducts.length === 0) {
          state.productsInCartByWarehouse.splice(warehouseIndex, 1);
        } else {
          state.productsInCartByWarehouse[warehouseIndex].products =
            filteredProducts;
        }
      }

      if (
        state.productsInCartByWarehouse.length === 0 ||
        !state.productsInCartByWarehouse.some((warehouse) =>
          warehouse.products.some(
            (product) =>
              product.supplierProductDetail.product.type !== "Container"
          )
        )
      ) {
        state.orderFacility = null;
        state.productsInCartByWarehouse = [];
        state.cartSubmission = null;
        state.cartSubmissions = [];
      }

      state.productsInCartByWarehouse = updateProductsInCartWithFreightDetails(
        state.productsInCartByWarehouse,
        state.supplierPallets,
        state.shipperCompanies,
        state.restrictedShippers,
        state.orderFacilityAddress
      );
    },
    checkToAddToCartSubmissions: (state, action) => {
      //check if cartSubmission already exists
      const cartSubmission = action.payload;
      const cartSubmissionIndex = state.cartSubmissions.findIndex(
        (entry) => entry._id === cartSubmission._id
      );

      if (cartSubmissionIndex === -1) {
        state.cartSubmissions.push(cartSubmission);
      }
    },
    setCartSubmission: (state, action) => {
      state.cartSubmission = action.payload;
    },
    setCartSubmissions: (state, action) => {
      state.cartSubmissions = [...action.payload];
    },
    setOrderFacility: (state, action) => {
      state.orderFacility = action.payload;
    },
    setOrderFacilityAddress: (state, action) => {
      state.orderFacilityAddress = action.payload;
    },
    setSupplierPallets: (state, action) => {
      state.supplierPallets = action.payload;
    },
    setShipperCompanies: (state, action) => {
      state.shipperCompanies = action.payload;
    },
    setRestrictedShippers: (state, action) => {
      state.restrictedShippers = action.payload;
    },
  },
});

export const {
  setProductsInCartByWarehouse,
  addToProductsInCartByWarehouse,
  setCartSubmission,
  setCartSubmissions,
  resetProducts,
  removeProductFromCartByWarehouse,
  reduceProductsInCartByWarehouse,
  setOrderFacility,
  setOrderFacilityAddress,
  setSupplierPallets,
  setShipperCompanies,
  setRestrictedShippers,
  checkToAddToCartSubmissions,
} = products.actions;

export default products.reducer;

export const selectCartSubmission = (state) => state.products.cartSubmission;
export const selectCartSubmissions = (state) => state.products.cartSubmissions;
export const selectProductsInCartByWarehouse = (state) =>
  state.products.productsInCartByWarehouse;
export const selectOrderFacility = (state) => state.products.orderFacility;
export const selectLoading = (state) => state.products.loading;
export const selectSupplierPallets = (state) => state.products.supplierPallets;
