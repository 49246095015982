import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import formatDate from "../../../sharedFunctions/formatDate";
import getTaxNameFromTaxCode from "../../../sharedFunctions/getTaxNameFromTaxCode";
import { CustomModal } from "../../sharedComponents";
import ApplyCreditsForm from "./ApplyCreditsForm";

const DocumentDisplay = ({ document }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleOpenModal = (type) => {
    setModalType(type);
    setOpenModal(true);
  };

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setOpenModal(false);
    setModalType("");
  };

  const renderDocInfo = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography>{formatDate(document.date)}</Typography>
        </Grid>
        {document?.dueDate && (
          <Grid item xs={12}>
            <Typography>Due: {formatDate(document.dueDate)}</Typography>
          </Grid>
        )}
        {(document?.customer?.name || document?.vendor?.name) && (
          <Grid item xs={12}>
            <Typography>
              {document?.customer?.name ?? document?.vendor?.name}
            </Typography>
          </Grid>
        )}
        {document?.docNumber && (
          <Grid item xs={12}>
            <Typography>Document #{document.docNumber}</Typography>
          </Grid>
        )}
        {document?.order?.orderNumber && (
          <Grid item xs={12}>
            <Typography>
              Created From Order #{document.order.orderNumber}
            </Typography>
          </Grid>
        )}
      </>
    );
  };

  const renderItems = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h5">Items</Typography>
        </Grid>
        {document?.lineItems?.map((lineItem) => (
          <Grid container item xs={12} key={lineItem._id}>
            <Grid item xs={12}>
              <Typography>{lineItem.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                QTY: {lineItem.quantity}, Price: $
                {lineItem.unitPrice?.toFixed(2)}, Total: $
                {lineItem.amount?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  const renderTotals = () => {
    return (
      <Grid item xs={12}>
        {document?.subTotal && (
          <Typography style={{ textAlign: "right" }}>
            Subtotal ${document.subTotal?.toFixed(2)}
          </Typography>
        )}
        {document.taxDetail?.line?.map((tax) => (
          <Typography style={{ textAlign: "right" }} key={tax._id}>
            {getTaxNameFromTaxCode(tax.taxRateRef.value)} $
            {tax.amount?.toFixed(2)}
          </Typography>
        ))}
        <Typography style={{ textAlign: "right" }}>
          Total ${document.amount?.toFixed(2)}
        </Typography>
      </Grid>
    );
  };

  const renderTransactions = () => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h5">Transactions</Typography>
        </Grid>
        {document?.linkedTxn?.map((linkedTxn) => (
          <Grid container item xs={12} key={linkedTxn._id}>
            <Grid item xs={12}>
              <Typography>
                {formatDate(linkedTxn.date)} - {linkedTxn.txnType}{" "}
                {linkedTxn?.docNumber ?? linkedTxn?.txn?.docNumber ?? ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Amount: ${linkedTxn?.amount?.toFixed(2)}</Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          {document?.balance >= 0 && (
            <Typography style={{ textAlign: "right" }}>
              Balance ${document.balance?.toFixed(2)}
            </Typography>
          )}
        </Grid>
      </>
    );
  };

  const renderButtons = () => {
    return (
      (document.type === "CreditMemo" || document.type === "VendorCredit") &&
      document?.balance > 0 && (
        <Grid item xs={12} md={2}>
          <Button
            onClick={() => handleOpenModal("Apply Credit")}
            variant="contained"
          >
            Apply Credit
          </Button>
        </Grid>
      )
    );
  };

  return (
    <>
      <CustomModal close={handleCloseModal} open={openModal} title={modalType}>
        <ApplyCreditsForm close={handleCloseModal} document={document} />
      </CustomModal>

      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={12} className="centered-container">
          {renderButtons()}
        </Grid>
        {renderDocInfo()}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {renderItems()}
        {renderTotals()}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {renderTransactions()}
      </Grid>
    </>
  );
};

export default DocumentDisplay;
