import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import useAddContactProfilePicture from "../../../customHooks/contacts/useAddContactProfilePicture";
import { FormSwitch } from "../../sharedComponents";

const ProfilePictureDisplay = ({ profilePicture }) => {
  return (
    <Grid item xs={12} className="centered-container">
      <Avatar src={profilePicture} alt={"Profile Picture"} />
    </Grid>
  );
};

const ProfilePicture = ({
  contactID,
  editMode,
  profilePicture,
  user,
  useSocialLogInProfilePicture,
  setUseSocialLogInProfilePicture,
}) => {
  const [image, setImage] = useState(null);
  const [uploadNewImage, setUploadNewImage] = useState(
    profilePicture ? false : true
  );
  const addProfilePicture = useAddContactProfilePicture();

  const onSubmit = async () => {
    if (!image) return;
    const formData = new FormData();

    formData.append("file", image, image.name);
    formData.append("contactID", contactID);
    formData.append("useSocialLogInProfilePicture", false);

    await addProfilePicture.mutateAsync(formData);
    setUploadNewImage(false);
  };

  const onSubmitSocialLogInProfilePicture = async (pictureURL) => {
    const formData = new FormData();
    formData.append("pictureURL", pictureURL);
    formData.append("contactID", contactID);
    formData.append("useSocialLogInProfilePicture", true);

    await addProfilePicture.mutateAsync(formData);
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Profile Picture" />
        <CardContent>
          <Grid container spacing={2}>
            {user?.picture && (
              <FormSwitch
                label="Use Social Log in Profile Picture"
                checked={useSocialLogInProfilePicture}
                onChange={() =>
                  setUseSocialLogInProfilePicture(!useSocialLogInProfilePicture)
                }
                disabled={!editMode}
                xs={12}
                sx={{ justifyContent: "center" }}
              />
            )}
            {useSocialLogInProfilePicture && user.picture ? (
              <>
                <ProfilePictureDisplay profilePicture={user.picture} />
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      onSubmitSocialLogInProfilePicture(user.picture)
                    }
                    fullWidth
                  >
                    Save Profile Picture
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid container spacing={2} item xs={12}>
                {!uploadNewImage && profilePicture ? (
                  <ProfilePictureDisplay profilePicture={profilePicture} />
                ) : (
                  <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    className="centered-container"
                  >
                    <input
                      type="file"
                      accept=".jpeg, .png, .jpg, .gif, .svg"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          setImage(e.target.files[0]);
                        }
                      }}
                    />
                    <Grid item xs={12} className="centered-container">
                      {image && (
                        <Avatar
                          src={URL.createObjectURL(image)}
                          alt={"Profile Picture"}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={onSubmit}
                        disabled={!image}
                        fullWidth
                      >
                        Upload Profile Img
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {!uploadNewImage && editMode && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => setUploadNewImage(true)}
                      fullWidth
                    >
                      Upload New Image
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProfilePicture;
