import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ServiceQuoteActionButtons from "./ServiceQuoteActionButtons";
import useServiceOrderQuotes from "../../../customHooks/serviceOrder/useServiceOrderQuotes";
import Conversation from "../../conversations/Conversation";
import { CustomModal } from "../../sharedComponents";

const ServiceQuotes = ({
  close,
  serviceOrderID,
  orderNumber,
  orderID,
  setServiceRequestOpen,
  facilityRecipient,
}) => {
  const { data } = useServiceOrderQuotes(serviceOrderID);
  const [conversationOpen, setConversationOpen] = useState(false);
  const [conversationSupplier, setConversationSupplier] = useState(null);

  if (!data) return null;
  const requestedSuppliers = data?.requestedSuppliers;
  const serviceQuotes = data?.serviceQuotes;

  const pendingServiceQuotes = requestedSuppliers?.filter(
    (supplier) =>
      !serviceQuotes.find((quote) => quote.supplier._id === supplier._id)
  );

  if (!serviceQuotes?.length && !pendingServiceQuotes?.length) return null;

  return (
    <>
      {serviceQuotes?.length > 0 &&
        serviceQuotes.map((serviceQuote, i) => {
          const serviceQuoteNumber = `${orderNumber}Q${i + 1}${
            serviceQuote.revisionNumber
              ? serviceQuote.revisionNumber < 10
                ? `-R0${serviceQuote.revisionNumber}`
                : `-R${serviceQuote.revisionNumber}`
              : ""
          }`;

          return (
            <Grid container spacing={2} item xs={12} key={"service Quote" + i}>
              {serviceQuote.status === "Denied" ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {serviceQuoteNumber} {serviceQuote.supplier.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="red">
                      Status: {serviceQuote.status}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setServiceRequestOpen(serviceQuote.supplier);
                      }}
                    >
                      {serviceQuoteNumber} {serviceQuote.supplier.name}
                    </Button>
                  </Grid>
                  {serviceQuote.status && (
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Status: {serviceQuote.status}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Service Description:{" "}
                      {serviceQuote.serviceRequestDescription}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Total ${serviceQuote.total.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setConversationSupplier(serviceQuote.supplier);
                        setConversationOpen(true);
                      }}
                    >
                      Open Conversation
                    </Button>
                  </Grid>
                  <ServiceQuoteActionButtons
                    close={close}
                    serviceOrderID={serviceOrderID}
                    serviceQuoteID={serviceQuote._id}
                    serviceQuoteNumber={serviceQuoteNumber}
                  />
                </>
              )}
            </Grid>
          );
        })}
      {pendingServiceQuotes?.length > 0 &&
        pendingServiceQuotes.map((supplier, i) => {
          return (
            <Grid container spacing={2} item xs={12} key={"pending Quote" + i}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setServiceRequestOpen(supplier);
                  }}
                >
                  {supplier.name}
                </Button>
                <Typography variant="body1">Status: Awaiting Quote</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setConversationSupplier(supplier);
                    setConversationOpen(true);
                  }}
                >
                  Open Conversation
                </Button>
              </Grid>
            </Grid>
          );
        })}

      <CustomModal
        open={conversationOpen}
        close={() => setConversationOpen(false)}
        title={"Conversation"}
      >
        <Conversation
          parentType={"ServiceOrder"}
          parentID={orderID}
          participants={[conversationSupplier, facilityRecipient]}
          supplierID={conversationSupplier?._id}
          title={`Service Order ${orderNumber}`}
        />
      </CustomModal>
    </>
  );
};

export default ServiceQuotes;
