import { useQuery } from "@tanstack/react-query";
import { getBills } from "../../api/billAPI";

const useBills = (enabled) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["bills"],
    queryFn: () => getBills(),
    enabled: enabled,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, error };
};

export default useBills;
