import React, { useState } from "react";
import { CustomModal } from "../../sharedComponents";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";
// import useServiceOrderRequestNewServiceProvider from "../../../customHooks/serviceOrder/useServiceOrderRequestNewServiceProvider";
import useServiceOrderCancelRequest from "../../../customHooks/serviceOrder/useServiceOrderCancelRequest";
import { selectUser } from "../../../redux/reducers/userSlice";

const ServiceOrderActionButtons = ({ close, order }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const user = useSelector(selectUser);
  // const [adjustModalOpen, setAdjustModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [notes, setNotes] = useState("");

  // const requestNewServiceProvider = useServiceOrderRequestNewServiceProvider();
  const cancelRequest = useServiceOrderCancelRequest();

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setNotes("");
    // setAdjustModalOpen(false);
    setCancelModalOpen(false);
  };

  // const requestNewProvider = async () => {
  //   await requestNewServiceProvider.mutateAsync({
  //     serviceOrderID: order._id,
  //     reason: notes,
  //   });

  //   closeModal();
  //   close();
  // };

  const cancelServiceRequest = async () => {
    await cancelRequest.mutateAsync({
      denyUser: user._id,
      serviceOrderID: order._id,
      reason: notes,
    });

    closeModal();
    close();
  };

  return (
    <Grid container spacing={2} item xs={12}>
      {(appUserLevel.includes("Facility") ||
        appUserLevel.includes("Super User")) &&
        (order?.status === "Pending" ||
          order?.status === "Adjustment Requested" ||
          order?.status === "Awaiting Facility Approval" ||
          order?.status === "Awaiting Service") && (
          <Grid
            item
            xs={
              !order.supplier
                ? 12
                : order?.status === "Awaiting Facility Approval"
                ? 4
                : 6
            }
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => setCancelModalOpen(true)}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
        )}

      {/* <CustomModal
        open={adjustModalOpen}
        close={closeModal}
        title="Service Adjustment"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">choose new quote here?</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Notes"
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                requestNewProvider();
              }}
              fullWidth
              disabled={!notes}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </CustomModal> */}

      <CustomModal
        open={cancelModalOpen}
        close={closeModal}
        title="Service Cancellation"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please enter a reason for canceling the service request.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Reason for Cancellation"
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={cancelServiceRequest}
              fullWidth
              disabled={!notes}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export default ServiceOrderActionButtons;
