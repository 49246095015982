import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import useAddMultipleProductOrders from "../../customHooks/productOrder/useAddMultipleProductOrders";
import useDeleteOrderAddition from "../../customHooks/orderAddition/useDeleteOrderAddition";
import useCompleteNotificationByItemID from "../../customHooks/notifications/useCompleteNotificationByItemID";
import useAddToOrder from "../../customHooks/productOrder/useAddToOrder";

const AddToOrder = ({ close, pendingOrder, orderAddition }) => {
  const [confirmOrder, setConfirmOrder] = useState(false);
  const [newOrderOpen, setNewOrderOpen] = useState(false);

  const addMultipleOrders = useAddMultipleProductOrders();
  const addToOrder = useAddToOrder();
  const deleteOrderAddition = useDeleteOrderAddition();
  const completeNotification = useCompleteNotificationByItemID();

  const closeConfirmModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setConfirmOrder(false);
  };
  const closeNewOrderModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setNewOrderOpen(false);
  };

  const addToPendingOrder = async () => {
    await addToOrder.mutateAsync({
      orderID: pendingOrder._id,
      orderAdditionID: orderAddition._id,
    });

    closeConfirmModal();
    close(null, null, true);
  };

  const createNewOrder = async () => {
    const body = {
      ...orderAddition,
      facilityName: orderAddition?.facility?.name,
      supplierName: orderAddition?.supplier?.name,
    };
    await addMultipleOrders.mutateAsync({
      orders: [body],
      facilityID: body.facility._id,
      sendOrderEmails: true,
    });
    deleteOrderAddition.mutate({ orderAdditionID: orderAddition._id });
    completeNotification.mutate({ itemID: orderAddition._id });
    closeNewOrderModal();
    close(null, null, true);
  };

  const renderConfirmOrder = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Typography>
            Confirm you would like to add the products to the pending order.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={addToPendingOrder}>
            Confirm Add To Order
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderNewOrder = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Typography>
            Confirm you would like to create a new order with the products to
            add.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={createNewOrder}>
            Create New Order
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setConfirmOrder(true)}
          >
            Open Add To Order
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setNewOrderOpen(true)}
          >
            Open Create New Order
          </Button>
        </Grid>
      </Grid>

      <CustomModal
        open={confirmOrder}
        close={closeConfirmModal}
        title={"Confirm Add To Order"}
      >
        {renderConfirmOrder()}
      </CustomModal>
      <CustomModal
        open={newOrderOpen}
        close={closeNewOrderModal}
        title={"New Order"}
      >
        {renderNewOrder()}
      </CustomModal>
    </>
  );
};

export default AddToOrder;
