import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import CustomToolbar from "../../sharedComponents/tables/CustomToolbar";
import moment from "moment";
import { CustomModal } from "../../sharedComponents";
import DocumentDisplay from "./DocumentDisplay";
import useCreditMemos from "../../../customHooks/creditMemo/useCreditMemos";
import useInvoices from "../../../customHooks/invoice/useInvoices";
import useBills from "../../../customHooks/bill/useBills";
import useVendorCredits from "../../../customHooks/vendorCredit/useVendorCredits";
import usePayments from "../../../customHooks/payment/usePayments";

const AccountingDocumentTable = () => {
  const [document, setDocument] = useState(null);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [documentType, setDocumentType] = useState("Invoice");

  const { data: creditMemos, isLoading: isLoadingCreditMemos } = useCreditMemos(
    documentType === "CreditMemo"
  );
  const { data: invoices, isLoading: isLoadingInvoices } = useInvoices(
    documentType === "Invoice"
  );
  const { data: bills, isLoading: isLoadingBills } = useBills(
    documentType === "Bill"
  );
  const { data: vendorCredits, isLoading: isLoadingVendorCredits } =
    useVendorCredits(documentType === "VendorCredit");
  const { data: payments, isLoading: isLoadingPayments } = usePayments(
    documentType === "Payment"
  );

  const openDocument = (document) => {
    setDocument(document);
    setDocumentOpen(true);
  };

  const closeDocument = (event, reason) => {
    if (reason === "backdropClick") return;
    setDocument(null);
    setDocumentOpen(false);
  };

  const renderButton = (params) => {
    return (
      <Button variant="contained" onClick={() => openDocument(params.row)}>
        {params.value}
      </Button>
    );
  };

  const TableButtons = () => {
    return (
      <Grid item xs={12} container display="flex">
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id="document-label"></InputLabel>
            <Select
              labelId="document-label"
              id="document-select"
              value={documentType}
              label=""
              onChange={(e) => setDocumentType(e.target.value)}
            >
              <MenuItem value={"Bill"}>Bill</MenuItem>
              <MenuItem value={"CreditMemo"}>Credit Memo</MenuItem>
              <MenuItem value={"Invoice"}>Invoice</MenuItem>
              <MenuItem value={"VendorCredit"}>Vendor Credit</MenuItem>
              <MenuItem value={"Payment"}>Payment</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  let columns = [
    {
      field: "docNumber",
      headerName: "",
      renderCell: renderButton,
      width: 125,
      valueGetter: (value, row) => row?.docNumber ?? row?.Id ?? "",
    },
    {
      headerName:
        documentType === "Bill" || documentType === "VendorCredit"
          ? "Vendor"
          : "Customer",
      width: 250,
      valueGetter: (value, row) =>
        row?.customer?.name ?? row?.vendor?.name ?? "",
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueGetter: (value, row) =>
        value ? moment(value).format("MMM. DD, YYYY") : null,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 150,
      valueGetter: (value, row) =>
        value ? moment(value).format("MMM. DD, YYYY") : null,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      valueGetter: (value, row) => {
        if (row.balance === 0) {
          return "Paid";
        } else if (
          row.balance !== 0 &&
          moment(row.dueDate).isAfter(moment(), "day")
        ) {
          return "Outstanding";
        } else if (moment(row.dueDate).isBefore(moment(), "day")) {
          return "Overdue";
        } else {
          return "";
        }
      },
    },
  ];

  const getRows = () => {
    switch (documentType) {
      case "CreditMemo":
        return creditMemos;
      case "Invoice":
        return invoices;
      case "Bill":
        return bills;
      case "VendorCredit":
        return vendorCredits;
      case "Payment":
        return payments;
      default:
        return [];
    }
  };

  const isLoading = () => {
    switch (documentType) {
      case "CreditMemo":
        return isLoadingCreditMemos;
      case "Invoice":
        return isLoadingInvoices;
      case "Bill":
        return isLoadingBills;
      case "VendorCredit":
        return isLoadingVendorCredits;
      case "Payment":
        return isLoadingPayments;
      default:
        return false;
    }
  };

  return (
    <>
      <CustomModal
        open={documentOpen}
        close={closeDocument}
        title={document?.docNumber ?? ""}
      >
        <DocumentDisplay document={document} />
      </CustomModal>

      <Box
        sx={{
          mt: "10px",
          height: window.innerHeight - 130,
          width: "100%",
          "& .order-overdue-true": {
            background: "rgba(255, 0, 0)",
          },
          "& .order-overdue-true:hover": {
            background: "rgba(255, 0, 0, 0.7) !important",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={getRows()}
          columns={columns}
          rowsPerPageOptions={[12, 28, 30, 31, 100]}
          disableRowSelectionOnClick
          localeText={{
            noRowsLabel: "No Documents",
          }}
          slots={{
            toolbar: () => CustomToolbar({ Buttons: TableButtons }),
          }}
          getRowClassName={(params) => {
            if (
              moment(params.row.dueDate).isBefore(moment(), "day") &&
              params.row.balance !== 0
            ) {
              return "order-overdue-true";
            }
          }}
          loading={isLoading()}
        />
      </Box>
    </>
  );
};

export default AccountingDocumentTable;
