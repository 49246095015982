import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { getServiceOptionLabel } from "../../../sharedFunctions/labels";
import DecimalTextField from "../../sharedComponents/TextFields/DecimalTextField";
import { GST } from "../../../globalConstants";
import getPSTRate from "../../../sharedFunctions/purchasingFunctions/getPSTRate";
import customDollarRound from "../../../sharedFunctions/purchasingFunctions/customDollarRound";
import useServiceContractRefund from "../../../customHooks/serviceContractOrder/useServiceContractRefund";

const ServiceRefundForm = ({ close, order }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [reason, setReason] = useState("");
  const [saving, setSaving] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [valid, setValid] = useState(false);

  const refund = useServiceContractRefund();

  useEffect(() => {
    const allValid = selectedProducts.every(
      (product) =>
        product.refundQuantity > 0 && product.refundQuantity <= product.quantity
    );

    if (!allValid) {
      setErrorMessage("Please enter a valid refund quantity for each product.");
    } else {
      setErrorMessage("");
    }

    setValid(allValid);
  }, [selectedProducts]);

  const subtotalRefund = useMemo(() => {
    const calculateSubtotalRefund = () => {
      return selectedProducts.reduce((total, product) => {
        let amount = customDollarRound(product.refundQuantity * product.price);
        if (product?.containerDeposit > 0) {
          amount += customDollarRound(
            product.refundQuantity * product.containerDeposit
          );
        }
        return total + amount;
      }, 0);
    };

    return calculateSubtotalRefund(selectedProducts);
  }, [selectedProducts]);

  const GSTRefund = useMemo(() => {
    if (!order?.facility?.hasGST) return 0;
    const calculateGSTRefund = () => {
      return selectedProducts.reduce((total, product) => {
        let amount = customDollarRound(
          product.refundQuantity * product.price * GST
        );
        return total + amount;
      }, 0);
    };

    return calculateGSTRefund(selectedProducts);
  }, [selectedProducts, order]);

  const PSTRefund = useMemo(() => {
    const calculatePSTRefund = () => {
      return selectedProducts.reduce((total, product) => {
        let amount = customDollarRound(
          product.refundQuantity *
            product.price *
            getPSTRate(order?.facility?.addressObject?.province)
        );
        return total + amount;
      }, 0);
    };

    return calculatePSTRefund(selectedProducts);
  }, [selectedProducts, order]);

  const totalRefund = useMemo(() => {
    const calculateTotalRefund = () => {
      return customDollarRound(subtotalRefund + GSTRefund + PSTRefund);
    };

    return calculateTotalRefund(selectedProducts);
  }, [selectedProducts, subtotalRefund, GSTRefund, PSTRefund]);

  const handleProductSelection = (product) => {
    const isProductSelected = selectedProducts.some(
      (p) => p._id === product._id
    );

    if (isProductSelected) {
      setSelectedProducts(
        selectedProducts.filter((p) => p._id !== product._id)
      );
    } else {
      setSelectedProducts([
        ...selectedProducts,
        { ...product, refundQuantity: product.quantity },
      ]);
    }
  };

  const handleRefundQuantityChange = (product, quantity) => {
    const updatedSelectedProducts = selectedProducts.map((p) =>
      p._id === product._id ? { ...p, refundQuantity: quantity } : p
    );
    setSelectedProducts(updatedSelectedProducts);
  };

  const onRefundOrder = async () => {
    setSaving(true);

    const body = {
      orderID: order._id,
      refundProducts: selectedProducts.map((product) => ({
        _id: product._id,
        refundQuantity: product.refundQuantity,
      })),
      reason,
    };

    await refund.mutateAsync(body);

    setSaving(false);
    close();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Please select the items you would like to refund.
        </Typography>
      </Grid>
      {order?.services?.map((service, i) => (
        <Grid container item key={i}>
          <Grid item xs={12}>
            <Typography>Service Year {i + 1}</Typography>
          </Grid>
          {service.map((product) => (
            <React.Fragment key={product._id}>
              <Grid item xs={10}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => handleProductSelection(product)}
                      />
                    }
                    label={
                      <>
                        <Typography>
                          {getServiceOptionLabel(product.service)}
                        </Typography>
                        <Typography>
                          Quantity: {product.quantity}, Price: $
                          {product.price.toFixed(2)}, Total: $
                          {product.total.toFixed(2)}
                        </Typography>
                      </>
                    }
                  />
                </FormGroup>
              </Grid>
              {product.quantity > 1 &&
                selectedProducts.find((p) => p._id === product._id) && (
                  <Grid item xs={2}>
                    <DecimalTextField
                      label={"Refund Quantity"}
                      value={
                        selectedProducts.find((p) => p._id === product._id)
                          ?.refundQuantity
                      }
                      editMode={true}
                      onChange={(e) =>
                        handleRefundQuantityChange(product, e.target.value)
                      }
                      numberProps={{ min: 0, max: product.quantity }}
                      allowMinus={false}
                      allowDecimal={false}
                    />
                  </Grid>
                )}
            </React.Fragment>
          ))}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          Subtotal: ${subtotalRefund.toFixed(2)}
        </Typography>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          PST: ${PSTRefund.toFixed(2)}
        </Typography>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          GST: ${GSTRefund.toFixed(2)}
        </Typography>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          Total: ${totalRefund.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          label="Reason for Refund"
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </Grid>
      {errorMessage && (
        <Grid item xs={12}>
          <Typography color="red">{errorMessage}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={onRefundOrder}
          fullWidth
          disabled={!selectedProducts.length || !reason || saving || !valid}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default ServiceRefundForm;
