import { useQuery } from "@tanstack/react-query";
import { getCreditMemos } from "../../api/creditMemoAPI";

const useCreditMemos = (enabled) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["creditMemos"],
    queryFn: () => getCreditMemos(),
    enabled: enabled,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, error };
};

export default useCreditMemos;
