import axios from "axios";

export const getSupplierProductDetail = (id) => {
  return axios
    .get("/api/supplier-product-detail/get-by-id", {
      params: {
        id,
      },
    })
    .then((res) => res?.data?.supplierProductDetail);
};

export const getSupplierProductDetailsBySupplier = (supplierID) => {
  return axios
    .get("/api/supplier-product-detail/get-by-supplier", {
      params: {
        supplierID,
      },
    })
    .then((res) => res?.data?.supplierProductDetails);
};

export const getSupplierProductDetailsByProduct = (productID) => {
  return axios
    .get("/api/supplier-product-detail/get-by-product", {
      params: {
        productID,
      },
    })
    .then((res) => res?.data?.supplierProductDetails);
};

export const getEquivalentSupplierProductDetailsByProduct = (productID) => {
  return axios
    .get("/api/supplier-product-detail/get-equivalents-by-product", {
      params: {
        productID,
      },
    })
    .then((res) => res?.data?.supplierProductDetails);
};

export const getSupplierProductDetailsByWarehouse = (warehouseID) => {
  return axios
    .get("/api/supplier-product-detail/get-by-warehouse", {
      params: {
        warehouseID,
      },
    })
    .then((res) => res?.data?.supplierProductDetails);
};

export const getSupplierContainersByFacility = (facilityID) => {
  return axios
    .get("/api/supplier-product-detail/get-supplier-containers-by-facility", {
      params: {
        facilityID,
      },
    })
    .then((res) => res?.data?.supplierProductDetail || null);
};

export const getSupplierContainerProductsByFacility = (facilityID) => {
  return axios
    .get(
      "/api/supplier-product-detail/get-supplier-container-products-by-facility",
      {
        params: {
          facilityID,
        },
      }
    )
    .then((res) => res?.data?.supplierContainerProducts || null);
};

export const getSupplierPallets = () => {
  return axios
    .get("/api/supplier-product-detail/get-supplier-pallets")
    .then((res) => res?.data?.supplierPallets);
};

/*
@params
{
    productsSelected: [ { id: product.id+ manufacturer.id, partNumber: product.partNumber}]
    supplierID: supplierCompany.contact._id
    supplierCompanyID: supplierCompany._id
    warehouseIDs: [warehouse._id]
}
*/
export const addMultipleSupplierProductDetails = (body) => {
  return axios.post("/api/supplier-product-detail/add-multiple", body);
};

export const updateSupplierProductDetail = (body) => {
  return axios.put("/api/supplier-product-detail/update", body).then((res) => {
    return res?.data?.supplierProductDetail;
  });
};

export const updateMultipleSupplierProductDetails = (body) => {
  return axios.put("/api/supplier-product-detail/update-multiple", body);
};

/*
@params
{
    productsSelected: [ { id: product.id + manufacturer.id}]
    supplierID: supplierCompany.contact._id
    warehouseIDs: [warehouse._id]
}
*/
export const setMultipleWarehousesForSupplierProductDetail = (body) => {
  return axios.patch(
    "/api/supplier-product-detail/set-multiple-warehouses",
    body
  );
};
export const setContainerDepositsForSupplierProductDetails = ({
  containerType,
  containerDeposit,
  supplierID,
  supplierCompanyID,
}) => {
  return axios.patch("/api/supplier-product-detail/set-container-deposits", {
    containerType,
    containerDeposit,
    supplierID,
    supplierCompanyID,
  });
};

/*
@params
{
    productsSelected: [ { id: product.id + manufacturer.id}]
    supplierID: supplierCompany.contact._id
    warehouseIDs: [warehouse._id]
}
*/
export const removeWarehouseFromSupplierProductDetail = (body) => {
  return axios.patch(
    "/api/supplier-product-detail/remove-warehouse-from-products",
    body
  );
};

export const removeWarehousesFromAllSupplierProductDetails = ({
  supplierCompanyID,
  warehouseIDs,
}) => {
  return axios.patch(
    "/api/supplier-product-detail/remove-warehouses-from-all-products",
    {
      supplierCompanyID,
      warehouseIDs,
    }
  );
};

/*
@params
{
    productsRemoved: [ { id: product.id + manufacturer.id, partNumber: product.partNumber}]
    supplierID: supplierCompany.contact._id
    supplierCompanyID: supplierCompany._id
}
*/
export const removeMultipleSupplierProductDetails = (body) => {
  return axios.patch("/api/supplier-product-detail/remove-multiple", body);
};

export const removeContainerDepositsByProductID = (productID) => {
  return axios.patch(
    "/api/supplier-product-detail/remove-container-deposits-by-productID",
    {
      productID,
    }
  );
};
