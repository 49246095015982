import axios from "axios";

export const getShippingRate = async (shipper, shipTo, packageInfo) => {
  const { data } = await axios.post("/api/ups/get-shipping-rate", {
    shipper,
    shipTo,
    package: packageInfo,
  });
  return data;
};

export const getTrackingInfo = async (trackingNumber) => {
  const { data } = await axios.post("/api/ups/tracking", { trackingNumber });
  return data;
};

export const bookShipment = async (orderID) => {
  const { data } = await axios.post("/api/ups/book-shipment", {
    orderID,
  });
  return data;
};

export const createPickup = async (pickupDetails) => {
  const { data } = await axios.post("/api/ups/create-pickup", pickupDetails);
  return data;
};
