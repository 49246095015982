import { useQuery } from "@tanstack/react-query";
import { getAllProducts } from "../../api/productAPI";

const useProducts = () => {
  const {
    data: products = [],
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["products"],
    queryFn: getAllProducts,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  return { products, isLoading, isRefetching };
};

export default useProducts;
