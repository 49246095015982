import { useMutation, useQueryClient } from "@tanstack/react-query";
import { bookShipment } from "../../api/upsAPI";

const useBookUPSShipment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: bookShipment,
    onSuccess: async () => {
      await queryClient.invalidateQueries(["ups", "shipment"]);
    },
  });
};

export default useBookUPSShipment;
