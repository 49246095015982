import { useQuery } from "@tanstack/react-query";
import { getVendorCredits } from "../../api/vendorCreditAPI";

const useVendorCredits = (enabled) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["vendorCredits"],
    queryFn: () => getVendorCredits(),
    enabled: enabled,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, error };
};

export default useVendorCredits;
