import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import formatDate from "../../../sharedFunctions/formatDate";
import { FEDEX_CONTACT_ID, UPS_CONTACT_ID } from "../../../globalConstants";
import ContactButton from "../../contacts/ContactButton";
import formatTextWithLineBreaks from "../../../sharedFunctions/formatTextWithLineBreaks";

const OrderDisplayDetails = ({ appUserLevel, order }) => {
  const renderOrderDetails = () => {
    return (
      <>
        <Grid item xs={6}>
          <Typography>Order #: {order.orderNumber}</Typography>
        </Grid>
        {order.customerPONumber && (
          <Grid item xs={6}>
            <Typography>Customer PO #: {order.customerPONumber}</Typography>
          </Grid>
        )}
        {order.creditMemoNumber && (
          <Grid item xs={6}>
            <Typography>Credit Memo #: {order.creditMemoNumber}</Typography>
          </Grid>
        )}
        {order.supplierOrderNumber && (
          <Grid item xs={6}>
            <Typography>
              Supplier Order #: {order.supplierOrderNumber}
            </Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography>Facility: {order.facility.name}</Typography>
        </Grid>
        {order.supplier && (
          <Grid item xs={6}>
            <Typography>
              {order.orderType === "ProductOrder"
                ? "Supplier:"
                : "Service Provider:"}{" "}
              {order.supplier.name}
            </Typography>
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography>Status: {order.status}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Date: {formatDate(order.date)}
            {order?.endDate && ` - ${formatDate(order.endDate)}`}
          </Typography>
        </Grid>
        {order.payDueDate && (
          <Grid item xs={6}>
            <Typography>Payment Due: {formatDate(order.payDueDate)}</Typography>
          </Grid>
        )}
        {order.pickUp && (
          <Grid item xs={6}>
            <Typography>Pick Up: {order.pickUp ? "Yes" : "No"}</Typography>
          </Grid>
        )}
      </>
    );
  };

  const renderProductOrderDetails = () => {
    return (
      <>
        {order.pickUpAvailableDate && (
          <Grid item xs={6}>
            <Typography>
              Pick up available: {formatDate(order.pickUpAvailableDate)}{" "}
              {order.pickUpHours}
            </Typography>
          </Grid>
        )}
        {order.shipDate && (
          <Grid item xs={6}>
            <Typography>Date shipped: {formatDate(order.shipDate)}</Typography>
          </Grid>
        )}
        {(order.arriveDate || order.estimatedArriveDate) && (
          <Grid item xs={6}>
            <Typography>
              Est. Delivery date:{" "}
              {order.arriveDate
                ? formatDate(order.arriveDate)
                : formatDate(order.estimatedArriveDate)}
            </Typography>
          </Grid>
        )}
        {order.requestedUser && (
          <Grid item xs={6}>
            <Typography>Requested User: {order.requestedUser.name}</Typography>
          </Grid>
        )}
        {order.requestingUser?.name !== order.requestedUser?.name && (
          <Grid item xs={6}>
            <Typography>
              Requesting User: {order.requestingUser.name}
            </Typography>
          </Grid>
        )}
        {order.recipient && (
          <Grid item xs={6}>
            <Typography>Order Recipient: {order.recipient.name}</Typography>
          </Grid>
        )}
        {order.shipper && (
          <Grid item xs={6}>
            <Typography>Shipper: {order.shipper.name}</Typography>
          </Grid>
        )}
        {order.thirdPartyShipper && (
          <Grid item xs={6}>
            <Typography>
              Broker Shipper: {order.thirdPartyShipper.name}
            </Typography>
          </Grid>
        )}
        {order.trackingNumber && (
          <Grid item xs={6}>
            <Typography>
              Tracking #:{" "}
              {order.trackingNumber.split(",").map((trackingNumber, index) => (
                <span key={index}>
                  {order.shipper._id === UPS_CONTACT_ID ? (
                    <Link
                      href={`https://www.ups.com/track?loc=en_CA&tracknum=${trackingNumber.trim()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {trackingNumber.trim()}
                    </Link>
                  ) : order.shipper._id === FEDEX_CONTACT_ID ? (
                    <Link
                      href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber.trim()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {trackingNumber.trim()}
                    </Link>
                  ) : (
                    trackingNumber.trim()
                  )}
                  {index < order.trackingNumber.split(",").length - 1 && ", "}
                </span>
              ))}
            </Typography>
          </Grid>
        )}
        {order.notes && (
          <Grid item xs={6}>
            <Typography>Notes: {order.notes}</Typography>
          </Grid>
        )}
      </>
    );
  };

  const renderServiceDetails = () => {
    return (
      <>
        {order.serviceDates?.length > 0 && (
          <Grid item xs={12} container spacing={1}>
            {order.serviceDates.map((serviceDate, i) => (
              <Grid item xs={6} key={"service Date" + i}>
                <Typography>Service Date: {formatDate(serviceDate)}</Typography>
              </Grid>
            ))}
          </Grid>
        )}
        {order?.requestingUser?.name && (
          <Grid item xs={6}>
            <Typography>
              Requesting User: {order.requestingUser.name}
            </Typography>
          </Grid>
        )}
        {order.approvalUser && (
          <Grid item xs={6}>
            <Typography>Approval User: {order.approvalUser.name}</Typography>
          </Grid>
        )}
        {order.denyUser && (
          <Grid item xs={6}>
            <Typography>Deny User: {order.denyUser.name}</Typography>
          </Grid>
        )}
        {order?.recipient && (
          <ContactButton
            xs={6}
            contact={order.recipient}
            label="Facility Recipient: "
          />
        )}
        {order?.serviceRepresentatives?.length > 0 &&
          order?.serviceRepresentatives.map((serviceRepresentative) => (
            <ContactButton
              xs={6}
              contact={serviceRepresentative}
              label="Service Representative: "
              key={serviceRepresentative._id}
            />
          ))}

        <Grid container item spacing={2} xs={12}>
          {order?.facilityServiceDescription && (
            <Grid container item spacing={1} xs={6}>
              <Grid item xs={12}>
                <Typography>Facility Service Description:</Typography>
              </Grid>
              <Grid item xs={12}>
                {formatTextWithLineBreaks(order.facilityServiceDescription)}
              </Grid>
            </Grid>
          )}
          {order?.serviceRequestDescription && (
            <Grid container item spacing={1} xs={6}>
              <Grid item xs={12}>
                <Typography>Service Request Description:</Typography>
              </Grid>
              <Grid item xs={12}>
                {formatTextWithLineBreaks(order.serviceRequestDescription)}
              </Grid>
            </Grid>
          )}
          {order?.serviceNotes && (
            <Grid container item spacing={1} xs={6}>
              <Grid item xs={12}>
                <Typography>Service Notes:</Typography>
              </Grid>
              <Grid item xs={12}>
                {formatTextWithLineBreaks(order.serviceNotes)}
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const renderServiceContractDetails = () => {
    return (
      <>
        {order.approvalUser && (
          <Grid item xs={6}>
            <Typography>Approval User: {order.approvalUser.name}</Typography>
          </Grid>
        )}
        {order.denyUser && (
          <Grid item xs={6}>
            <Typography>Deny User: {order.denyUser.name}</Typography>
          </Grid>
        )}
        {order?.recipient && (
          <ContactButton
            xs={6}
            contact={order.recipient}
            label="Facility Recipient: "
          />
        )}
        {order?.serviceRepresentatives?.length > 0 &&
          order?.serviceRepresentatives.map((serviceRepresentative) => (
            <ContactButton
              xs={6}
              contact={serviceRepresentative}
              label="Service Representative: "
              key={serviceRepresentative._id}
            />
          ))}
        <Grid item xs={6}>
          <Typography>Service Contract: {order.name}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <Grid container item xs={12} spacing={1}>
      {renderOrderDetails()}
      {order.orderType === "ProductOrder" && renderProductOrderDetails()}
      {order.orderType === "ServiceOrder" && renderServiceDetails()}
      {order.orderType === "ServiceContractOrder" &&
        renderServiceContractDetails()}
    </Grid>
  );
};

export default OrderDisplayDetails;
