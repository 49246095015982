import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import CustomModal from "../../sharedComponents/modals/CustomModal";
import useExchangeRates from "../../../customHooks/exchangeRates/useExchangeRates";
import CancelCartSubmission from "../CancelCartSubmission";
import ConfirmOrder from "../ConfirmOrder";
import useFacilityContacts from "../../../customHooks/facility/useFacilityContacts";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderFacility,
  setOrderFacilityAddress,
  setProductsInCartByWarehouse,
  checkToAddToCartSubmissions,
  setCartSubmission,
} from "../../../redux/reducers/productsSlice";
import useCartSubmissionByID from "../../../customHooks/cartSubmissions/useCartSubmissionByID";
import ShoppingCartProducts from "../../shoppingCart/ShoppingCartProducts";
import ShoppingCartTotal from "../../shoppingCart/ShoppingCartTotal";
import { Loader } from "../../sharedComponents";
import { selectFacilityUserLevel } from "../../../redux/reducers/userLevelsSlice";
import { checkFacilityUserLevelOrder } from "../../../sharedFunctions/userLevels";
import { RenderReturnableContainerProducts } from "../../shoppingCart/ReturnableContainers";

const CartSubmissionDisplay = ({
  cartSubmissionID,
  close,
  facilityID,
  facilityHasGST,
}) => {
  const dispatch = useDispatch();

  const { exchangeRates } = useExchangeRates();
  const { facilityContacts } = useFacilityContacts(facilityID);
  const { cartSubmission, isLoading } = useCartSubmissionByID(cartSubmissionID);

  const [approveOpen, setApproveOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const facilityUserLevel = useSelector(selectFacilityUserLevel);

  const setCartSubmissionInCart = () => {
    dispatch(checkToAddToCartSubmissions(cartSubmission));
    dispatch(setCartSubmission(cartSubmission));
    dispatch(setOrderFacility(cartSubmission.facility._id));
    dispatch(setOrderFacilityAddress(cartSubmission.facility.addressObject));
    dispatch(
      setProductsInCartByWarehouse(cartSubmission.productsInCartByWarehouse)
    );
    close();
  };

  if (isLoading) return <Loader />;
  if (!cartSubmission) return <div>Error Loading, please refresh.</div>;

  return (
    <>
      <Grid container spacing={2}>
        {cartSubmission.customerPONumber && (
          <Grid item xs={12}>
            <Typography>
              Customer PO Number: {cartSubmission.customerPONumber}
            </Typography>
          </Grid>
        )}
        {cartSubmission.notes && (
          <Grid item xs={12}>
            <Typography>Notes: {cartSubmission.notes}</Typography>
          </Grid>
        )}
        <ShoppingCartProducts
          productsInCartByWarehouse={cartSubmission.productsInCartByWarehouse}
          exchangeRates={exchangeRates}
          facility={cartSubmission.facility}
          viewOnly={true}
        />
        <ShoppingCartTotal
          exchangeRates={exchangeRates}
          productsInCartByWarehouse={cartSubmission.productsInCartByWarehouse}
          facilityHasGST={cartSubmission.facility?.hasGST}
          facilityHasPST={cartSubmission.facility?.hasPST}
          facilityCredit={cartSubmission.facility?.credit}
          facilityProvince={cartSubmission.facility?.addressObject?.province}
          hasOnlyShippingIncluded={cartSubmission.productsInCartByWarehouse?.every(
            (productInCart) =>
              productInCart.shippingIncluded || productInCart.pickUp
          )}
        />
        <RenderReturnableContainerProducts
          exchangeRates={exchangeRates}
          facilityHasGST={facilityHasGST}
          productsInCartByWarehouse={cartSubmission.productsInCartByWarehouse}
          viewOnly={true}
        />

        {checkFacilityUserLevelOrder(facilityUserLevel) && (
          <>
            <Grid item xs={4}>
              <Button
                variant="contained"
                onClick={() => setApproveOpen(true)}
                fullWidth
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                onClick={setCartSubmissionInCart}
                fullWidth
              >
                Manage In Cart
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                onClick={() => setCancelOpen(true)}
                fullWidth
                color="error"
              >
                Cancel
              </Button>
            </Grid>{" "}
          </>
        )}
      </Grid>

      <CustomModal
        open={cancelOpen}
        close={() => {
          setCancelOpen(false);
        }}
        title="Cancel Cart Submission"
      >
        <CancelCartSubmission
          cartSubmissionID={cartSubmission._id}
          close={() => {
            setCancelOpen(false);
            close();
          }}
        />
      </CustomModal>
      <CustomModal
        open={approveOpen}
        close={() => {
          setApproveOpen(false);
        }}
        title="Approve Cart Submission"
      >
        <ConfirmOrder
          cartSubmission={cartSubmission}
          facilityContacts={facilityContacts}
          productsInCartByWarehouse={cartSubmission?.productsInCartByWarehouse}
          close={() => {
            setApproveOpen(false);
            close();
          }}
          facilityID={facilityID}
        />
      </CustomModal>
    </>
  );
};

export default CartSubmissionDisplay;
