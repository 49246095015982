import axios from "axios";

export const getCreditMemos = async () => {
  return axios.get(`/api/credit-memo/get-all`).then((res) => {
    return res?.data?.creditMemo;
  });
};

export const getCreditMemo = async (creditMemoID) => {
  return axios
    .get(`/api/credit-memo/get-by-id?id=${creditMemoID}`)
    .then((res) => {
      return res?.data?.creditMemo;
    });
};

export const getCreditMemoByCustomer = async (customerID, outStanding) => {
  return axios
    .get(
      `/api/credit-memo/get-by-customer?customerID=${customerID}&outStanding=${outStanding}`
    )
    .then((res) => {
      return res?.data?.creditMemo;
    });
};

export const creditMemoApplyCredit = async (body) => {
  return axios.put(`/api/credit-memo/apply-credit`, body);
};
