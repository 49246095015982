import { useQuery } from "@tanstack/react-query";
import { getInvoices } from "../../api/invoiceAPI";

const useInvoices = (enabled) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["invoices"],
    queryFn: () => getInvoices(),
    enabled: enabled,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, error };
};

export default useInvoices;
