const getShippingFlatRate = ({ shippingRate, hazardous, applicableRange }) => {
  if (!shippingRate || !applicableRange) return 0;

  const tempFlatRate = { ...shippingRate.flatRates };
  const { displacement } = applicableRange ?? 0;
  if (!tempFlatRate) return 0;

  let flatRate = 0;
  if (tempFlatRate) {
    if (hazardous && tempFlatRate.dangerousGoods) {
      flatRate += tempFlatRate.dangerousGoods;
      delete tempFlatRate.dangerousGoods;
    }

    //add each flat rate in the flatRates Object
    for (const [, value] of Object.entries(tempFlatRate)) {
      flatRate += value;
    }
  }

  return flatRate + displacement;
};

export default getShippingFlatRate;
